import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import ReactModal from 'react-modal';
import { Form, Field, option } from 'react-final-form';
import { useParams } from 'react-router-dom';
import {addHashtag} from '../../campaign/actions';

const ModalTitle = styled.h2`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  
  `
const SubmodalTitle = styled.h4`
font-family: Roboto;
font-size: 14px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2d3748;
padding-bottom:10px;
border-bottom: 3px solid  #aeaeae;

`
const BtnAplicar = styled.button`
  width: 125px;
  height: 27px;
  border-radius: 50px;
  background-color: #9ae6b4;
  float: right;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}`

const ModalHashtag = ({isOpen, setIsOpen}) => {

  /* Esto está pendiente porque tengo que ver cómo se estructuraban las cuentas */
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const { idCamp} = useParams();
    const listadoCuentas = useSelector((state) => state.client.decode.cuentas_user);
    const listadoCuentasCampaing = useSelector((state) => state.campaign.campaign.cuentas);
    const [cuentasActivas, setCuentasActivas] = useState({});

    
   

    const onSubmit = (values) =>{
        dispatch(addHashtag(values.hashtag, `/api/campaigns/${idCamp}`, client));
      };
      const validate = () =>{
  
      };
    return (
        <ReactModal

        isOpen={isOpen}
        
        >
            <div>
          <div className="rowModal" style={{textAlign:'right'}}><img src="/assets/images/close-icon.png" onClick={(e)=>{e.preventDefault();setIsOpen(false);}}/></div>
          <ModalTitle>Añadir hashtag o mención</ModalTitle>
          <SubmodalTitle>Añade un nuevo hashtag o mención y pulsa en 'Aplicar' para añadirlo a la campaña.</SubmodalTitle>
        <div className="contenidoModal">
            <div className="rowModal">
            <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="form-datos-cuenta">
                    
                    <Field
                    name="hashtag"
                    type="text"
                    id="hashtag"
                    className="input_text_1 w100"
                    component="input"
                    placeholder="#/@mención"
                  />
                   <BtnAplicar>Aplicar</BtnAplicar>
             </form>
        
        )}
      />
            </div>
            
        </div>

          </div>
        </ReactModal>
      );
}
 
export default ModalHashtag;