import { SIGNUP_REQUESTING } from './constants'

const signupRequest = function signupRequest ({ email, password , tipo}) {
  // console.log('Tipo: ' + tipo);
  return {
    type: SIGNUP_REQUESTING,
    email,
    password,
    tipo
  }
}

export default signupRequest
