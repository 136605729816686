import React from "react";
import { Form, Field, option } from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import NuevaCampanaForm from './formularios/nuevaCampanaForm';

const DivPopUpCrearCamp = styled.div`
  width: 50%;
  //height: 100vh;
  padding: 35px 15px 150px 39px;
  border-radius: 11px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: absolute;
  right: 0px;
  z-index: 1;

    @media (max-width:500px) {
        width: 75%;
    }
`;

const NuevaCampPopup = ({mostrarCrear,setMostrarCrear}) => {
    const Titulo =styled.h1`
        width: 100%;
        height: 27px;
        margin: 0 17px 15px 0;
        font-family: Roboto;
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
        `;
    const Close = styled.div`
        width: 20px;
        height: 20px;
        display: inline-block;
        float: right;
        
    `;

    if (mostrarCrear){
    return (  
        <DivPopUpCrearCamp>
            <Titulo>Nueva campaña <Close onClick={(e)=>{e.preventDefault();setMostrarCrear(!mostrarCrear)}}><img src="/assets/images/close-icon.svg"/></Close></Titulo>
            <NuevaCampanaForm />
        
        </DivPopUpCrearCamp>

            );
    }else{
        return (
            null
        );
    }
}
 
export default NuevaCampPopup;