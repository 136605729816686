import {
  UPDATEACCOUNTDETAILS_REQUESTING,
  UPDATEACCOUNTDETAILS_SUCCESS,
  UPDATEACCOUNTDETAILS_ERROR
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
}

const reducer = function doblefactorReducer (state = initialState, action) {
  switch (action.type) {
    case UPDATEACCOUNTDETAILS_REQUESTING:
      return {
        requesting: true,
        successful: false,
        messages: [{ body: 'Verificando...', time: new Date() }],
        errors: [],
      }

    case UPDATEACCOUNTDETAILS_SUCCESS:
      return {
        errors: [],
        messages: [{
          body: `Verificación correcta`,
          time: new Date(),
        }],
        requesting: false,
        successful: true,
      }

    case UPDATEACCOUNTDETAILS_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
    
    default:
      return state
  }
}

export default reducer
