import React, { useEffect, useState, Fragment } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import ReactModal from 'react-modal';
import { useParams } from 'react-router-dom';
import BasicInfluencer from "./planesCards/basicInfluencer";

const ModalTitle = styled.h2`
  flex-grow: 0;
  margin: 0 461px 38px 0;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  @media (max-width:500px){
    margin: 0px 0px 10px 100px;
    }  
  
  `

const DivResponisve = styled.div`
width:100%;

@media (max-width:500px){
  display:flex;
  flex-direction: column;
  position:absolute;
  }   

`
const PlanCol1= styled.div`
  width: 60%;
  display:inline-block ;
  vertical-align: top;
  @media (max-width:500px){
    position: flex;
    }  
   
`
const PlanCol2= styled.div`
  width: 40%;
  display:inline-block;
  vertical-align: top;
  position: relative;
  @media (max-width:500px){
    margin: 0px 0px 5px -10px;
    }  
`
const Text1=styled.h2`
flex-grow: 0;
  margin: 0px 0 6px 30.3px;
  font-family: Roboto;
  font-size: 34px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2c5282;
`
const Text2=styled.p`
flex-grow: 0;
  margin: 6px 60px 5.5px 30.3px;
  font-family: Roboto;
  font-size: 26px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
`
const ImgPlan=styled.img`
  @media (max-width:500px){
    display: none;
    }  
`
const Text3=styled.p`
width: 85%;
flex-grow: 0;
  margin: 32.5px 72px 114px 49px;
  font-family: Roboto;
  font-size: 21px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #6a6a6a;
  font-weight:normal;
    @media (max-width:500px){
      margin: 10px 0px 10px 10px;
      width: 150%;
    }  
`
const Card1=styled.div`
  width: 326px;
  height: 562px;
  flex-grow: 0;
  margin: 0px 110px 0 0;
  padding: 20px 5px 24px 25px;
  border-radius: 26px;
  box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
  background-color: #3a6eb0;
  position: relative;
  z-index:2;
  & li{
        margin: 0 0 3px 11.2px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & ul {
        list-style: none;
        padding-left: 0px;
    }

    & ul li img {
        vertical-align: middle;
    }
    & ul li{
        padding-bottom: 10px;
    }
   
`
const DivRecomendado = styled.div`
  margin: 0 147px 26px 0;
  padding: 7px 16px 6px 15px;
  border-radius: 13.5px;
  background-color: #2c5282;
  text-align: center;
  & span{
    font-family: Avenir;
  font-size: 13px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: #c7d8ed;
  text-transform: uppercase;
  }
`
const Text4=styled.p`
  flex-grow: 0;
  margin: 26px 193px 10px 5px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const Text5=styled.p`
  margin: 0 10px 0 0;
  font-family: Roboto;
  font-size: 36px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  & span{
    margin: 19px 0 7px 10px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
`
const Text6=styled.p`
  margin: 2px 0 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const BotonContratar=styled.button`
  margin: 28px 14px 15px 22px;
  padding: 13px 46px 14px;
  border-radius: 24px;
  border: solid 0 #9ae6b4;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c5282;
`
const VerTodosPlanes=styled.a`
  flex-grow: 0;
  margin: 15px 59px 0 66px;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration:none;
`
const PlanCol1_1=styled.div`
  width: 30%;
  display: inline-block;
  vertical-align: top;
`
const PlanCol1_2=styled.div`
  width: 70%;
  display: inline-block;
  vertical-align: top;
    @media (max-width:500px){
        margin: 10px 0px 0px -20px;
        width: 180%;
      }  
`
const TipoPlan=styled.span`
  flex-grow: 0;
  transform: rotate(-270deg);
  font-family: Avenir;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: #c7d8ed;
  text-transform: uppercase ;
  display: inline-block ;
  position: absolute;
  right:0px;
  top:40px;
`

const Card2 = styled.div`
  width: 326px;
  height: 562px;
  flex-grow: 0;
  margin: 0px 110px 0 0;
  padding: 20px 5px 24px 25px;
  border-radius: 26px;
  box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
  background-color: #2c5282;
  top: 0px;
    left: 40px;
  position: absolute;
  z-index:1;
  & li{
        margin: 0 0 3px 11.2px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & ul {
        list-style: none;
        padding-left: 0px;
    }

    & ul li img {
        vertical-align: middle;
    }
    & ul li{
        padding-bottom: 10px;
    }
 
  `

const ModalPlanes = ({isOpen, setIsOpen}) => {
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);

    const [modoActivo, setModoActivo] = useState('mensual');

    const texto1 = () =>{
      switch(client.decode.plan){
        case 'FREE':
          return `<Text3>Con el plan <i>“Basic”</i> podrás administrar <strong>2 campañas</strong> usuando hasta <strong>5 Hashtags</strong> por cada una y gestionar hasta <strong>10 influencers. Todo eso y más, además con un precio increible si contratas el año. ¿Te animas?</strong></Text3>`
        case 'FREE INFLUENCER':
            return `<Text3>Con el plan <i>“Basic”</i> podrás administrar todos tus post sin límites. Todo eso y más, además con un precio increible si contratas el año. ¿Te animas?</strong></Text3>`


      }

    }

    const texto2 = () =>{
      switch(client.decode.plan){
        case 'FREE':
          return `<Text4>Basic</Text4>`
        case 'FREE INFLUENCER':
            return `<Text4>Basic</Text4>`


      }

    }
    return (
        <ReactModal

        isOpen={isOpen}
        
        >
            <div>
          <div className="rowModal" style={{textAlign:'right'}}><img src="/assets/images/close-icon.png" onClick={(e)=>{e.preventDefault();setIsOpen(false);}}/></div>
          <ModalTitle>Gestión de planes</ModalTitle>
        <div className="contenidoModal">
            <DivResponisve>

              <PlanCol1>
                <PlanCol1_1>
                <ImgPlan src="/assets/images/modal_plan.png" />
                </PlanCol1_1>
                <PlanCol1_2>
                     <Text1>{client.gestion_plan.error}</Text1>
                      <Text2>No pongas límite a tu trabajo y consigue ahora un plan superior sin compromiso.</Text2>
              </PlanCol1_2>
              
              <div dangerouslySetInnerHTML={{__html:texto1()}}/>

              </PlanCol1>
              <PlanCol2>
                <BasicInfluencer 
                setModoActivo={setModoActivo}
                modoActivo={modoActivo}
                />
              </PlanCol2>

            </DivResponisve>
            
        </div>

          </div>
        </ReactModal>
      );
}
 
export default ModalPlanes;