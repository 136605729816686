import { call, put, takeLatest, takeEvery, select} from 'redux-saga/effects'
import { handleApiErrors } from '../lib/api-errors'
import {
  SINGLE_CAMPAIGN_REQUESTING,
  SINGLE_CAMPAIGN_SUCCESS,
  SINGLE_CAMPAIGN_ERROR,
  SINGLE_CAMPAIGN_MENTIONED_SUCCESS,
  SINGLE_CAMPAIGN_MENTIONED_REQUESTING,
  SINGLE_CAMPAIGN_MENTIONED_ERROR,
  SINGLE_CAMPAIGN_USERNAMES_SUCCESS,
  SINGLE_CAMPAIGN_USERNAMES_REQUESTING,
  SINGLE_CAMPAIGN_USERNAMES_ERROR,
  DELETE_MENTIONED_REQUESTING,
  DELETE_MENTIONED_SUCCESS,
  DELETE_MENTIONED_ERROR,
  SINGLE_CAMPAIGN_STORIES_REQUESTING,
  SINGLE_CAMPAIGN_STORIES_SUCCESS,
  SINGLE_CAMPAIGN_STORIES_ERROR,
  DATOS_GRAFICA_SPLINE_REQUESTING,
  DATOS_GRAFICA_SPLINE_SUCCESS,
  DATOS_GRAFICA_SPLINE_ERROR,
  FILTROS_APPLY,
  FILTROS_APPLY_RESPONSE,
  DATOS_GRAFICA_DONUT_REQUESTING,
  DATOS_GRAFICA_DONUT_SUCCESS,
  DATOS_GRAFICA_DONUT_ERROR,
  FILTROS_RESULT_UPDATE_VALUE,
  SINGLE_CAMPAIGN_ADDINFLUENCER,
  SINGLE_CAMPAIGN_ADDINFLUENCER_END,
  SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR,
  SINGLE_CAMPAIGN_DELETE_INFLUENCER,
  SINGLE_CAMPAIGN_DELETE_INFLUENCER_END,
  SINGLE_CAMPAIGN_ADD_HASHTAG,
  SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST,
  SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE,
  SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_REQUEST,
  SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_SUCCESS,
  CAMPAING_DATOS_SUCCESS,
  CAMPAING_DATOS_ERROR,
  CAMPAING_DATOS_REQUESTING
} from './constants'
import {CLIENT_SUPERADO_PLAN} from '../client/constants';

/* ******************* VARIABLES ******************* */
// The url derived from our .env file
const singlecampURL = `${process.env.REACT_APP_API_URL}/api/campaigns/`;
const dataGraficaSplineURL = `${process.env.REACT_APP_API_URL}/api/grafica-detalle-spline/`;
const filtrosURL = `${process.env.REACT_APP_API_URL}/api/ejecutarfiltro/`;
const dataGraficaDonutURL = `${process.env.REACT_APP_API_URL}/api/grafica-detalle-donut/`;
const addHashtagTocampURL = `${process.env.REACT_APP_API_URL}/api/hashtags`
const datosCampaing = `${process.env.REACT_APP_API_URL}/api/datos-basicos-campaing/`

/* ******************* END VARIABLES ******************* */


/* ******************* FUNCTIONS ******************* */
function obtenerAddInfluencer (client, campaing, influencer) {
  return fetch(`${process.env.REACT_APP_API_URL}/api/addInfluencer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify({influencer, campaing})
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}
function obtenerDeleteInfluencer (client, idCampaign, idInfluencer) {
  return fetch(`${process.env.REACT_APP_API_URL}/api/eliminarInfluencer`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify({idInfluencer, idCampaign})
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}
function actualizarFiltroApi (client, idCamp, values) {
  return fetch(filtrosURL + idCamp, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(values)
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}




function obtenerSingleCampApi (client, idCamp, filtro) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log(client.token.token);

  return fetch(singlecampURL + idCamp, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
      'X-Filtro-Custom-Header': (filtro !== null && Object.keys(filtro).length > 0 ? JSON.stringify(filtro) : undefined),
      //'Origin': 'http://localhost:3000'
    },
    //body: JSON.stringify({})
    mode: 'cors'
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}

function addToCampApi (client,url, datos, method="POST") {

  return fetch(url , {
    method: method,
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(datos)
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}

function obtenerMentionedCampsApi (client, page=null, idCamp, filtro, username=null) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log(client.token.token);
  //return fetch((page !== null ? `${process.env.REACT_APP_API_URL}${page}` : `${process.env.REACT_APP_API_URL}/api/campaigns/${idCamp}/mentioned_media`), {
    //timestamp%5Bafter%5D=
    let filtrosAplicar={};
    if (filtro.hasOwnProperty("fechas") && filtro.fechas.hasOwnProperty("hasta") && filtro.fechas.hasta !== null ){
      filtrosAplicar['timestamp[before]']=filtro.fechas.hasta;
    }
    if (filtro.hasOwnProperty("fechas") && filtro.fechas.hasOwnProperty("desde") && filtro.fechas.desde !== null ){
      filtrosAplicar['timestamp[after]']=filtro.fechas.desde;
    }
    var stringFiltrosAplicar = Object.keys(filtrosAplicar).map(key =>{
        return `${key}=${filtrosAplicar[key]}`;
    }).join('&');

    if (filtro.hasOwnProperty("redes")){
      Object.keys(filtro.redes).map(red =>{
        if (filtro.redes[red]===true){
          stringFiltrosAplicar = `${stringFiltrosAplicar}&tipo[]=${red}`;
        }
      });
    }
    if (filtro.hasOwnProperty("hashtags")){
      Object.keys(filtro.hashtags).map(hashtag =>{
        if (filtro.hashtags[hashtag]===false){
          stringFiltrosAplicar = `${stringFiltrosAplicar}&negative_hashtag[]=${hashtag}`;
        }
      });
    }

    if (filtro.hasOwnProperty("minInteracciones") && filtro.minInteracciones!== null){
          stringFiltrosAplicar = `${stringFiltrosAplicar}&limite_interacciones[min]=${filtro.minInteracciones}`;
      
    }
    if (filtro.hasOwnProperty("maxInteracciones") && filtro.maxInteracciones!== null){
      stringFiltrosAplicar = `${stringFiltrosAplicar}&limite_interacciones[max]=${filtro.maxInteracciones}`;
  
    }
    if (filtro.hasOwnProperty("minFollowers") && filtro.minFollowers!== null){
        stringFiltrosAplicar = `${stringFiltrosAplicar}&limite_followers[min]=${filtro.minFollowers}`;
    
    }
    if (filtro.hasOwnProperty("maxFollowers") && filtro.maxFollowers!== null){
      stringFiltrosAplicar = `${stringFiltrosAplicar}&limite_followers[max]=${filtro.maxFollowers}`;

    }
    if (username!== null){
      stringFiltrosAplicar = `${stringFiltrosAplicar}&username=${username}`;
    }

  return fetch((page !== null ? `${process.env.REACT_APP_API_URL}${page}` : `${process.env.REACT_APP_API_URL}/api/mentioned_media?page=1&campaign=/api/campaigns/${idCamp}&=${stringFiltrosAplicar}`), {
      method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
      'X-Filtro-Custom-Header': (filtro !== null ? JSON.stringify(filtro) : undefined)
    },
    //body: JSON.stringify({})
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
      return res;
    })
    .catch((error) => { 
      throw error })
}

function obtenerUsernamesCampsApi (client, page=null, idCamp, filtro) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log(client.token.token);
  //return fetch((page !== null ? `${process.env.REACT_APP_API_URL}${page}` : `${process.env.REACT_APP_API_URL}/api/campaigns/${idCamp}/usernames`), {
  let stringFiltrosAplicar='';
    if (filtro.hasOwnProperty("minFollowers") && filtro.minFollowers!== null){
      stringFiltrosAplicar = `${stringFiltrosAplicar}&limite_followers[min]=${filtro.minFollowers}`;
  
  }
  if (filtro.hasOwnProperty("maxFollowers") && filtro.maxFollowers!== null){
    stringFiltrosAplicar = `${stringFiltrosAplicar}&limite_followers[max]=${filtro.maxFollowers}`;

  }
  return fetch((page !== null ? `${process.env.REACT_APP_API_URL}${page}` : `${process.env.REACT_APP_API_URL}/api/usernames?campaigns=${idCamp}${stringFiltrosAplicar}`), {
      method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
      'X-Filtro-Custom-Header': (filtro !== null ? JSON.stringify(filtro) : undefined)
    },
    //body: JSON.stringify({})
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
      return res;
    })
    .catch((error) => { 
      throw error })
}

function deleteMentionedCampsApi (client, idPost) {
  let item = {eliminado: true};
  return fetch(`${process.env.REACT_APP_API_URL}/api/mentioned_media/${idPost}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(item)
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
      return res;
    })
    .catch((error) => { 
      throw error })
}

function obtenerStoriesCampsApi (client, page=null, idCamp, filtro) {
  return fetch((page !== null ? `${process.env.REACT_APP_API_URL}${page}` : `${process.env.REACT_APP_API_URL}/api/campaigns/${idCamp}/stories`), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
      'X-Filtro-Custom-Header': (filtro !== null ? JSON.stringify(filtro) : undefined)
    },
    //body: JSON.stringify({})
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
      return res;
    })
    .catch((error) => { 
      throw error })
}

function obtenerDatosGraficaSpline(client, idCamp, filtro){
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log('Sagas -> obtenerDatosGraficaSpline -> idCamp: ' + idCamp);

  return fetch(dataGraficaSplineURL + idCamp, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
      'X-Filtro-Custom-Header': (filtro !== null ? JSON.stringify(filtro) : undefined)
    },
    //body: JSON.stringify({})
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}

function obtenerDatosGraficaDonut(client, idCamp){
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log('Sagas -> obtenerDatosGraficaSpline -> idCamp: ' + idCamp);

  return fetch(dataGraficaDonutURL + idCamp, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    //body: JSON.stringify({})
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}


function obtenerDatosApi(client, filtro,idCamp){
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log('Sagas -> obtenerDatosGraficaSpline -> idCamp: ' + idCamp);

  return fetch(datosCampaing + idCamp , {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    //body: JSON.stringify({})
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}
/* ******************* END FUNCTIONS ******************* */

/* ******************* FLOW - FUNCTIONS ******************* */
// This will be run when the SINGLE_CAMP_REQUESTING
// Action is found by the watcher
function* singleCampFlow (action) {
  try {
    const {client, idCamp, page, filtro} = action.payload;

    //tengo que ver si la campaña que hay almacenada es distinta a la actual para eliminar el filtro. Pendiente

    const response = yield call(obtenerSingleCampApi, client, idCamp, filtro);
    yield put({ type: SINGLE_CAMPAIGN_SUCCESS, response });
    yield put({ type: SINGLE_CAMPAIGN_USERNAMES_REQUESTING, payload : {client: client, idCamp: idCamp, page: page, filtro}});
    yield put({ type: SINGLE_CAMPAIGN_MENTIONED_REQUESTING, payload : {client: client, idCamp: idCamp, page: page, filtro}});
    yield put({ type: SINGLE_CAMPAIGN_STORIES_REQUESTING, payload : {client: client, idCamp: idCamp, page: page, filtro}});
    yield put({ type: DATOS_GRAFICA_SPLINE_REQUESTING, payload : {client: client, idCamp: idCamp, filtro}});
    yield put({ type: SINGLE_CAMPAIGN_USERNAMES_REQUESTING, payload : {client: client, idCamp: idCamp, page: page}});
    yield put({ type: SINGLE_CAMPAIGN_MENTIONED_REQUESTING, payload : {client: client, idCamp: idCamp, page: page}});
    yield put({ type: SINGLE_CAMPAIGN_STORIES_REQUESTING, payload : {client: client, idCamp: idCamp, page: page}});
    yield put({ type: DATOS_GRAFICA_SPLINE_REQUESTING, payload : {client: client, idCamp: idCamp}});
    yield put({ type: DATOS_GRAFICA_DONUT_REQUESTING, payload : {client: client, idCamp: idCamp}});
    yield put({type: CAMPAING_DATOS_REQUESTING, payload : {client: client, idCamp: idCamp, filtro}});
   
    // El borrado del post tendría que hacerse de otra forma, ya que esto se ejecuta cuando se carga una singleCamp.
    //yield put({ type: DELETE_MENTIONED_REQUESTING, payload : {client: client, idCamp: idCamp, page: page, idPost: idPost}});

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    console.log(error);
    yield put({ type: SINGLE_CAMPAIGN_ERROR, error })
  }
}

function* filtrosFlow (action) {
  try {
    const {client, idCamp, values} = action.payload;
    const response = yield call(actualizarFiltroApi, client, idCamp, values);
    if (response.hasOwnProperty('filtros_modificados') && response.filtros_modificados=== 'success'){
      yield put({ type: FILTROS_APPLY_RESPONSE, filtro: response.filtro })
      yield put({ type: SINGLE_CAMPAIGN_REQUESTING, payload: {client, idCamp, page:null, filtro: response.filtro } })
    }
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    console.log(error);
  }
}

function* singleMentionedMediaCampFlow (action) {
  try {
    const {client, idCamp, page, filtro} = action.payload;
    const responseMentioned = yield call(obtenerMentionedCampsApi, client, page, idCamp, filtro);
    if (responseMentioned!== undefined && responseMentioned.hasOwnProperty('hydra:totalItems')){
      yield put({ type: FILTROS_RESULT_UPDATE_VALUE, payload: {campo: 'posts', valor: responseMentioned['hydra:totalItems']} })
    }
    yield put({ type: SINGLE_CAMPAIGN_MENTIONED_SUCCESS, responseMentioned })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: SINGLE_CAMPAIGN_MENTIONED_ERROR, error })
  }
}


function* mencionesUsuarioFlow (action) {
  try {
    const {client, idCamp, page, filtro, username} = action.payload;
    const responseMentioned = yield call(obtenerMentionedCampsApi, client, page, idCamp, filtro, username);
    
    yield put({ type: SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_SUCCESS, payload: {username: username, respuesta: responseMentioned }})

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    //yield put({ type: SINGLE_CAMPAIGN_MENTIONED_ERROR, error })
  }
}

function* singleUsernamesCampFlow (action) {
  try {
    const {client, idCamp, page, filtro} = action.payload;
    const responseUsernames = yield call(obtenerUsernamesCampsApi, client, page, idCamp, filtro);
    if (page===null){
        if (responseUsernames!== undefined && responseUsernames.hasOwnProperty('hydra:totalItems')){
          yield put({ type: FILTROS_RESULT_UPDATE_VALUE, payload: {campo: 'allContributors', valor: responseUsernames['hydra:totalItems']} })
        }
    }
    yield put({ type: SINGLE_CAMPAIGN_USERNAMES_SUCCESS, responseUsernames })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: SINGLE_CAMPAIGN_USERNAMES_ERROR, error })
  }
}

function* deleteMentionedMediaFlow (action) {
  try {
    const {client, idCamp, idPost} = action.payload;
    const responseDelete = yield call(deleteMentionedCampsApi, client, idPost);
    //El put es como un dispatch, pero en vez del nombre de la función se llama al type definido, en este caso, DELETE_MENTIONED_SUCCESS
    yield put({ type: DELETE_MENTIONED_SUCCESS, payload : {client: client, idPost: idPost} })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: DELETE_MENTIONED_ERROR, error })
  }
}

function* singleStoriesCampFlow (action) {
  try {
    const {client, idCamp, page, filtro} = action.payload;
    const responseStories = yield call(obtenerStoriesCampsApi, client, page, idCamp, filtro);
    // console.log('responseStories');
    // console.log(responseStories);
    yield put({ type: SINGLE_CAMPAIGN_STORIES_SUCCESS, responseStories })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: SINGLE_CAMPAIGN_STORIES_ERROR, error })
  }
}

function* dataGraficaSplineFlow (action) {
  try {
    const {client, idCamp, filtro} = action.payload;
    const responseDataGraficaSpline = yield call(obtenerDatosGraficaSpline, client, idCamp, filtro);
    // console.log('responseStories');
    // console.log(responseStories);
    yield put({ type: DATOS_GRAFICA_SPLINE_SUCCESS, responseDataGraficaSpline })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: DATOS_GRAFICA_SPLINE_ERROR, error })
  }
}

function* dataGraficaDonutFlow (action) {
  try {
    const {client, idCamp} = action.payload;
    const responseDataGraficaDonut = yield call(obtenerDatosGraficaDonut, client, idCamp);
    // console.log('responseStories');
    // console.log(responseStories);
    yield put({ type: DATOS_GRAFICA_DONUT_SUCCESS, responseDataGraficaDonut })

  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    yield put({ type: DATOS_GRAFICA_DONUT_ERROR, error })
  }
}

function* addInfluencerFlow (action) {
  try {
    const {client, influencer, campaing} = action.payload;
    const responseAddInfluencer = yield call(obtenerAddInfluencer, client, campaing, influencer);
    if (responseAddInfluencer!== undefined && responseAddInfluencer.hasOwnProperty('success') && responseAddInfluencer.success===true){
      yield put({type: SINGLE_CAMPAIGN_DELETE_INFLUENCER_END});
      yield put({ type: FILTROS_RESULT_UPDATE_VALUE, payload: {campo: 'influencers', valor: responseAddInfluencer['resultInfluencers']} })
      yield put({ type: FILTROS_RESULT_UPDATE_VALUE, payload: {campo: 'influencersCamp', valor: responseAddInfluencer['resultInfluencers'].length} })

      
    }

  } catch (error) {
    if (error.type==="Error plan"){
      yield put({type: CLIENT_SUPERADO_PLAN, payload: {mensaje: "Has alcanzado el limite de influencers"}});
    }
    yield put({type: SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR});
  }
}


function* deleteInfluencerFlow (action) {
  try {
    const {client, influencer, campaing} = action.payload;
    const responseDeleteInfluencer = yield call(obtenerDeleteInfluencer, client, campaing, influencer);
    if (responseDeleteInfluencer!== undefined && responseDeleteInfluencer.hasOwnProperty('success') && responseDeleteInfluencer.success===true){
      yield put({type: SINGLE_CAMPAIGN_DELETE_INFLUENCER_END});
      yield put({ type: FILTROS_RESULT_UPDATE_VALUE, payload: {campo: 'influencers', valor: responseDeleteInfluencer['resultInfluencers']} })
      yield put({ type: FILTROS_RESULT_UPDATE_VALUE, payload: {campo: 'influencersCamp', valor: responseDeleteInfluencer['resultInfluencers'].length} })

      
    }

  } catch (error) {
    yield put({type: SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR});
  }
}

function* addHashtagFlow(action) {
  try {
    const {client, hashtag, campaing} = action.payload;
    yield put({type: SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE, payload: {
        requesting: true,
        error: false,
        ended: false,
    } })
    let newHashtag = hashtag.replace("#",'').replace("@",'');
    const respuestasHashtag = yield call(addToCampApi,client,addHashtagTocampURL,{"hashtag": newHashtag,"campaign": campaing});
    if(respuestasHashtag.hasOwnProperty('@id')){
      //hay que añadirlo a los hashtags
      respuestasHashtag['hashtag']= newHashtag;
      yield put({type: SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST, respuestasHashtag });
      yield put({type: SINGLE_CAMPAIGN_UPDATE_HASHTAG_LIST_STATE, payload: {
        requesting: false,
        error: false,
        ended: true,
    } })
    }


  } catch (error) {
    //yield put({type: SINGLE_CAMPAIGN_ADDINFLUENCER_ERROR});
    if (error.type==="Error plan"){
      yield put({type: CLIENT_SUPERADO_PLAN, payload: {mensaje: "Has superado el número de Hashtags"}});
    }
  }
}

function* datosFlow (action) {
  try {
    const {client, filtro, idCamp} = action.payload;

    //tengo que ver si la campaña que hay almacenada es distinta a la actual para eliminar el filtro. Pendiente

    const response = yield call(obtenerDatosApi, client, filtro, idCamp);
    yield put({ type: CAMPAING_DATOS_SUCCESS, response });
    
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.

    yield put({ type: CAMPAING_DATOS_ERROR, error })
  }
}
/* ******************* END FLOW - FUNCTIONS ******************* */


/* ******************* WATCHER ******************* */
function* singleCampsWatcher () {
  yield takeEvery(
    SINGLE_CAMPAIGN_REQUESTING, singleCampFlow
  )
  yield takeEvery(
    SINGLE_CAMPAIGN_MENTIONED_REQUESTING, singleMentionedMediaCampFlow
  )
  yield takeEvery(
    SINGLE_CAMPAIGN_USERNAMES_REQUESTING, singleUsernamesCampFlow
  )
  yield takeEvery(
    DELETE_MENTIONED_REQUESTING, deleteMentionedMediaFlow
  )
  yield takeEvery(
    SINGLE_CAMPAIGN_STORIES_REQUESTING, singleStoriesCampFlow
  )
  yield takeEvery(
    DATOS_GRAFICA_SPLINE_REQUESTING, dataGraficaSplineFlow
  )
  yield takeEvery(
    FILTROS_APPLY, filtrosFlow)
  yield takeEvery(
    DATOS_GRAFICA_DONUT_REQUESTING, dataGraficaDonutFlow
  )
  yield takeEvery(
    SINGLE_CAMPAIGN_ADDINFLUENCER, addInfluencerFlow
  )
  yield takeEvery(
    SINGLE_CAMPAIGN_DELETE_INFLUENCER, deleteInfluencerFlow
  )
  yield takeEvery(SINGLE_CAMPAIGN_ADD_HASHTAG, addHashtagFlow)
  yield takeEvery(SINGLE_CAMPAIGN_SOLICITAR_MENCIONES_USER_REQUEST, mencionesUsuarioFlow)
  yield takeEvery(
    CAMPAING_DATOS_REQUESTING, datosFlow
  )
}
/* ******************* END WATCHER ******************* */


export default singleCampsWatcher
