import React, { Fragment, useEffect, useState } from "react";
import styled from 'styled-components';
import PostRow from "./postRow";
import PaginadorRow from "./PaginadorRow";
const RowCamp = styled.div`
margin-bottom: 10px;
display: flex;
`;

const Div12 = styled.div`
width: 100%;
display: block;
`;

const Div12_Center = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

const Div1 = styled.div`
float: left;
padding-left: 30px;
`;

const Div3 = styled.div`
width: 20%;
float: left;
padding-top: 5px;
`;
const Div3Table = styled.div`
width: 20%;
float: left;
padding-top: 5px;
display: table;
`;

const Div5 = styled.div`
width: 49%;
float: left;
padding: 5px;
`;

const Div6 = styled.div`
width: 66%;
float: left;
padding-top: 5px;
padding-bottom: 10px;
`;

const Div7 = styled.div`
width: 70%;
float: left;
padding-top: 5px;
padding-bottom: 10px;
`;

const Div10 = styled.div`
width: 80%;
float: left;

`;

const ImgUser= styled.div`
width: 44px;
height: 44px;
flex-grow: 0;
margin: 0 15px 0 0;
background-color: #c4c4c4;
border:1px solid #c4c4c4;
border-radius: 50%;
display: inline-block;
display: table-cell ;
`;
const NombreUserPost= styled.span`
flex-grow: 0;
font-family: Roboto;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.33;
letter-spacing: normal;
text-align: left;
color: black;
display: inline-block;
vertical-align: top;
display: table-cell;

`
const BloqueColPostResultRow=styled.div`
display: inline-block;
vertical-align:top;
flex-grow: 0;
margin: 0 14px 0 0;
padding: 8.2px 13px 8.6px 18px;
border-radius: 10px;
background-color: #fff;

`;

const FechaUserPost=styled.div`
flex-grow: 0;
font-family: Roboto;
font-size: 12px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.33;
letter-spacing: normal;
text-align: left;
color: darkgrey;
`;

const PostResultRowLikes=styled.span`
flex-grow: 0;
font-family: Roboto;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #2a4365;
strong{
    width:100%;
    clear:both;
    display: block;
}
`;

const PostResultRow = styled.div`
width: calc(100% - 50px);
padding: 22px 27px 22px 26px;
`;

const UserRowDiv = styled.div`
width: 98%;
/*height: 99px;*/
left: 22px;
top: 640px;

background: #FAFBFC;
box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
border-radius: 10px;

margin-top: 20px;
display: block;
`;

const IconRightMenu = styled.img`
cursor: pointer;
`;

    
const UserRow = ({
    item,
    obtenerPostsUser,
    postPorUsername,
    client,
    dispatch,
    deleteMentionedRequesting,
    clickPaginacionUsernames
}) => {



    return (
        <UserRowDiv>
            <PostResultRow className="PostResultRow">
                <Div12 className="Div12">
                    <Div3Table className="Div3">
                        <ImgUser className="ImgUser"></ImgUser>
                        <BloqueColPostResultRow className="BloqueColPostResultRow">
                            <NombreUserPost className="autologiaonline NombreUserPost">
                                <b>{item.username}</b>
                            </NombreUserPost>
                            {/* <FechaUserPost className="FechaUserPost">10/9/2022</FechaUserPost> */}
                        </BloqueColPostResultRow>
                    </Div3Table>
                    <Div6 className="Div6">
                        <BloqueColPostResultRow className="BloqueColPostResultRow">
                            <PostResultRowLikes className="PostResultRowLikes">
                                <strong>{item.ig_likes}</strong>
                                Likes
                            </PostResultRowLikes>
                        </BloqueColPostResultRow>
                        <BloqueColPostResultRow>
                            <PostResultRowLikes>
                                <strong>6</strong>
                                Interacciones totales
                            </PostResultRowLikes>
                        </BloqueColPostResultRow>
                        <BloqueColPostResultRow>
                            <PostResultRowLikes>
                                <strong>500€</strong>
                                EMV
                            </PostResultRowLikes>
                        </BloqueColPostResultRow>
                        <BloqueColPostResultRow>
                            <PostResultRowLikes>
                                <strong>{item.mentionedMedia.length}</strong>
                                Posts
                            </PostResultRowLikes>
                        </BloqueColPostResultRow>
                        <BloqueColPostResultRow>
                            <PostResultRowLikes>
                                <strong>0,26%</strong>
                                Engagement Ratio
                            </PostResultRowLikes>
                        </BloqueColPostResultRow>
                    </Div6>
                    <Div1 className="Div1">
                        <Div12_Center className="Div12_Center">
                            <Div5 className="Div5">
                                <IconRightMenu className="IconRightMenu" src="/assets/images/add_follower_icon.png"></IconRightMenu>
                            </Div5>
                            <Div5 className="Div5">
                                <IconRightMenu className="IconRightMenu" src="/assets/images/delete_follower_icon.png"></IconRightMenu>
                            </Div5>
                        </Div12_Center>
                        <Div12_Center className="Div12_Center">
                            <IconRightMenu className="IconRightMenu" src="/assets/images/ver_posts_icon.png"
                                onClick={(e)=>{e.preventDefault();obtenerPostsUser(item['@id'])}}
                            ></IconRightMenu>
                        </Div12_Center>
                    </Div1>
                </Div12>
            </PostResultRow>
            <PostResultRow>
            { postPorUsername.data.hasOwnProperty(item['@id']) && postPorUsername.data[item['@id']].hasOwnProperty('result') && postPorUsername.data[item['@id']].result.hasOwnProperty('hydra:member') &&  postPorUsername.data[item['@id']].result['hydra:member'].length >0 ?  
                postPorUsername.data[item['@id']].result['hydra:member'].map((item,i) =>(
                    <Div12_Center key={`post_user_${i}`}>
                    <PostRow 
                        item={item}
                        client={client}
                        dispatch={dispatch}
                        deleteMentionedRequesting={deleteMentionedRequesting}
                    />
                    </Div12_Center>
                ))

                
                : null                  
                }
                { postPorUsername.data.hasOwnProperty(item['@id']) && postPorUsername.data[item['@id']].hasOwnProperty('result') && postPorUsername.data[item['@id']].result.hasOwnProperty('hydra:member') &&  postPorUsername.data[item['@id']].result['hydra:member'].length >0 ?  
           

                postPorUsername.data[item['@id']].result.hasOwnProperty('hydra:view') && postPorUsername.data[item['@id']].result['hydra:view'].hasOwnProperty('@id')? 
                      <PaginadorRow 
                          paginacion={postPorUsername.data[item['@id']].result['hydra:view']}
                          callback_click={clickPaginacionUsernames}
                      />
                  : null
                    :null             
                }
            </PostResultRow>
            
        </UserRowDiv>
    );
};

export default UserRow;
