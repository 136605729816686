import React, { Fragment,useState } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {useDispatch, useSelector} from 'react-redux';
import Messages from '../notifications/Messages'
import Errors from '../notifications/Errors'
import RegistroForm from '../componentes/formularios/registroForm';
import MenuSuperior from "../componentes/menuSuperior";
import DobleFactorForm from '../componentes/formularios/dobleFactorForm';
import {resendVerifyRequest} from './actions';
import styled from '@emotion/styled';
const GraciasTitle = styled.h1`
    font-family: Roboto;
    font-size: 40px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2d3748;
    margin-top:80px;
`
const GraciasSubtitulo = styled.p`
  margin: 0 0 11px;
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d3748;
  `
const GraciasTexto= styled.p`
  margin: 11px 11px 28px 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #224957;
    @media (max-width:500px) {
      font-size: 20px;
    }
  `
  
const GraciasLink= styled.a`
    margin: 11px 11px 28px 0;
    font-family: Roboto;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #224957;`
  
const GraciasError=styled.p`
  margin: 20px 11px 0 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #c91a4f;`

const DivCandado=styled.div`
  background: #f5f5f5 url('/assets/images/candado.png') center center no-repeat;
  vertical-align: top;
  display: table-cell;
    @media (max-width:500px) {
      display: none;
    }

`

const DivForm = styled. div`
 width: 100%;
  @media (max-width:500px) {
    margin: 12%;
    width: 75%;
  }

`
const DobleFactor = () => {
  const client = useSelector(state=> state.client);
  const reenvioOK = useSelector(state=> state.verificacion.successful_reenvio);
  const dispatch = useDispatch();  

  

  const enviarCodigo = (e) =>{
      e.preventDefault();
      dispatch(resendVerifyRequest(client));
      
    
  }
    return (
      <Fragment>
        {
           
                client.decode.is_verified ? (
                  client.decode.roles.includes('ROLE_INFLUENCER') ? 
                  <Navigate to={{ pathname: '/dashboard_influencer', state: ''}} />
                  :
                <Navigate to={{ pathname: '/dashboard', state: ''}} />) : null
        }
        <MenuSuperior
          showDerecha= "false"
          marginBottom="No"
          />

<div className="col-md-12 div-table verificacionRow">
    
        <div className="col-md-6 inline-block col-table">
              <GraciasTitle>Gracias por registrarte</GraciasTitle>
              <GraciasSubtitulo>Te hemos enviado un mail</GraciasSubtitulo>
              <GraciasTexto>Por favor, intruduce el <strong>código</strong> que te hemos enviado para verificar que eres tú.</GraciasTexto>
              
              <DivForm className="signup">
                <DobleFactorForm
                />
                
              </DivForm>
              { reenvioOK === true ?
              <GraciasTexto>Email con código reenviado correctamente. Por favor, compruebe su spam.</GraciasTexto>
              :
              <GraciasTexto><GraciasLink onClick={enviarCodigo}>No he recibido el código, <strong>reenviar</strong></GraciasLink></GraciasTexto>
              }

        </div>
        <DivCandado className="col-md-6 hideMovil">

        </DivCandado>
      </div>
      </Fragment>
    )
  }

export default DobleFactor
