import React, {Fragment, useEffect, useState} from "react";
import MenuSuperior from "../componentes/menuSuperior";
import {useDispatch, useSelector} from 'react-redux';
import styled from 'styled-components';
import FiltrosMenuAside from "../componentes/filtros_menu_aside";
import DetalleCampaign from "../componentes/detalle_campaña";
import { useParams } from 'react-router-dom';
import {singleCampaign} from './actions';
import ModalInfluencers from "../componentes/modales/modalnfluencers";
import ModalCuentas from "../componentes/modales/modalcuentas";
import ModalHashtag from "../componentes/modales/modalhashtag";
import ModalPlanes from "../componentes/modales/modalPlanes";
 /* ****** Elementos styled ****** */
 const RowCamp = styled.div`
 margin-bottom: 10px;
 display: flex;
`;

const Div12 = styled.div`
 width: 100%;
 display: block;
`;

const DivResponsive = styled.div`
  @media (max-width:500px){
    display:none;
    
  }
`

//Estilo boton para el menu responsive
const Button = styled.button`
    display: none;
    margin: 0px 0px 0px 0px;

    @media (max-width:500px){
        display: flex;
        z-index: 5;
        position:fixed;
        margin-left:20;
    }

`;

/* ****** END Elementos styled ****** */

const SingleCampaign = () => {
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const estadoModalHashtags = useSelector(state=> state.campaign.addHashtagsState);
    //Obtener el listado de las campañas
    //Cambio el redux dashboard->requesting a true para que saga lo escuche

    const datosSingleCampaign = useSelector((state) => state.campaign.campaign);
    const filtro = useSelector((state) => state.campaign.filtro);
    const { idCamp} = useParams()

    /* ****** STATES ****** */
    const [isOpenModalInfluencers, setIsOpenModalInfluencers]= useState(false);
    const [isOpenModalCuentas, setIsOpenModalCuentas]= useState(false);
    const [isOpenModalHashtag, setIsOpenModalHashtag]= useState(false);
    
    const [isOpenModalPlanes, setIsOpenModalPlanes]= useState(false);

    const [responsive, setResponsive] = useState(false)
    /* ****** END STATES ****** */


    /* ****** USE-EFFECTS ****** */
    useEffect(()=>{
      if (datosSingleCampaign.length === 0){
        dispatch(singleCampaign(client, idCamp, filtro, null));
      }

    },[])

    useEffect(()=>{
      console.log(estadoModalHashtags);
      if (estadoModalHashtags.ended === true){
        setIsOpenModalHashtag(false);
      }
    },[estadoModalHashtags])

    useEffect(()=>{
      if (client.gestion_plan.superado === true){
        setIsOpenModalPlanes(true);
      }
    },[client.gestion_plan])
    
    /* ****** END USE-EFFECTS ****** */



    /* ****** FUNCTIONS ****** */
    
    /* ****** END FUNCTIONS ****** */



   

    return (
      <Fragment>
        {responsive ?
              <FiltrosMenuAside
              setIsOpenModalInfluencers={setIsOpenModalInfluencers}
              setIsOpenModalCuentas={setIsOpenModalCuentas}
              setIsOpenModalHashtag={setIsOpenModalHashtag}
              />
            :
            null
            }
        <MenuSuperior />
        <Button
            onClick={()=>setResponsive(!responsive)}
        >menu</Button>
        <div className="container">
          <RowCamp>
              <Div12>
              <DivResponsive>
                <FiltrosMenuAside
                setIsOpenModalInfluencers={setIsOpenModalInfluencers}
                setIsOpenModalCuentas={setIsOpenModalCuentas}
                setIsOpenModalHashtag={setIsOpenModalHashtag}
                />
                </DivResponsive>
                <DetalleCampaign
                    datosSingleCampaign = {datosSingleCampaign}
                   
                />
            </Div12>
          </RowCamp>
        </div>
       
      <ModalInfluencers 
      isOpen={isOpenModalInfluencers}
      setIsOpen={setIsOpenModalInfluencers}
      />
       <ModalCuentas 
      isOpen={isOpenModalCuentas}
      setIsOpen={setIsOpenModalCuentas}
      />
       <ModalHashtag
      isOpen={isOpenModalHashtag}
      setIsOpen={setIsOpenModalHashtag}
      />
       <ModalPlanes
      isOpen={isOpenModalPlanes}
      setIsOpen={setIsOpenModalPlanes}
      />

      </Fragment>
    );
}

export default SingleCampaign;