import { useState, useCallback, useEffect } from 'react';
import { Form, Field, option, useField } from 'react-final-form';
import signupRequest from '../../registro/actions';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { format, isValid, toDate } from "date-fns";
import {crearCamp} from "../../dashboard/actions";
import ReactTagInput from "@pathofdev/react-tag-input";
import "@pathofdev/react-tag-input/build/index.css";
import '../../planes/checkbox.css';
import Dropzone from '../dropzone/dropzone';

const ColumnaDatos = styled.div`
  width: calc(50% - 45px);
  display: inline-block;
  vertical-align: top;
  padding: 20px;
  padding-left:0px;
  & .input_text_1{
    margin-left:0px;
  } 

  @media (max-width: 500px) {
    width: calc(100% - 45px);
  }
`;
const LabelC = styled.label`
  margin: 0 0px 8px 19px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  margin-left:0px;

`;
const LabelCheckbox=styled.label`
  font-family: Roboto;
  font-size: 15px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  margin-left: 15px;
    position: relative;
    top: -9px;
  `
const DropAreaTag=styled.button`
    background: transparent url('/assets/images/upload_img.png') top left no-repeat;
    width: 220px;
    height: 200px;
    border:none;
    `
const Col50 = styled.div`
  width: 50%;
  display: inline-block;
`
const ReacTagInputHolder=styled.div`
  & .react-tag-input{
    flex-grow: 0;
    border-radius: 30px;
    background-color: #eaeaea;
    margin-top: 11px;
    margin-bottom: 11px;
}
`

const Button = styled.button`
    @media (max-width:500px) {
      margin: 12%;
      width: 75%;
    }
`
const NuevaCampanaForm = () => {
    const [startDate, setStartDate] = useState(new Date());
    const [campaingsTags,setCampaingsTags] = useState([]);
    const [campaingsInfluencers,setCampaingsInfluencers] = useState([]);
    const mensajesCrearCamp = useSelector((state) => state.dashboard.mensajesCrearCamp);
    const [hideMessage, setHideMessage] = useState(true);
    const client = useSelector(state=> state.client);
    const [isCheckedFecha, setIsCheckedFecha] = useState(false);
    const dispatch = useDispatch();
    const onSubmit = (values) =>{
      values['tags']=campaingsTags;
      values['influencers']=campaingsInfluencers;
      dispatch(crearCamp(client,values));
    };
    const validate = () =>{

    };

    
    const updateHideMessage = () => {
      setHideMessage(true);
    }
    
  useEffect(()=>{
    console.log(mensajesCrearCamp);
  },[mensajesCrearCamp])

  
    return ( 
      <div className="col-md-12 mauto">
        <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit, meta: submitting, values  }) => (
          <form onSubmit={handleSubmit} id="form-datos-cuenta">
            <ColumnaDatos>
                      <LabelC>Nombre de la campaña</LabelC>
                      <Field
                      name="nombre"
                      type="text"
                      id="nombre"
                      className="input_text_1 w100"
                      component="input"
                      placeholder="Nombre de la campaña"
                    />
                    <LabelC>Fecha inicio</LabelC>
                    <Field
                                name="fechaInicio"
                                component={RenderDatePicker}
                                placeholder="Fecha de inicio"
                                className="input_text_1 w100"
                                onChange ={(value)=>{
                                  // console.log("set value2");
                                  // console.log(value);
                                }}
                                setStartDate={setStartDate}
                                value={startDate}
                              />
                    <div className="checbox_container">
                        <Field
                                    name="continua"
                                    type="checkbox"
                                    id="acepto"
                                    value={isCheckedFecha}
                                    onChange={(e)=>{e.preventDefault();setIsCheckedFecha(e.target.checked);}}
                                    component={RenderCheckbox}
                                  />

                                  
                        <LabelCheckbox>Sin fecha de fin</LabelCheckbox> 
                  </div> 

        </ColumnaDatos>
        <ColumnaDatos>                  
         <LabelC>Imagen de la campaña</LabelC>
                                        
                                  <Field
                                          name="imagen"
                                          component={Dropzone}
                                          currentFile={values.imagen}
                                          accept="image/*"
                                          
                                          /> 
                                     
                 
                 </ColumnaDatos>
        

        <LabelC>Hashtags</LabelC>
            <Field
                      name="hashtags"
                      component={RenderTagInputComponent}
                      placeholder="Escribe y presiona enter para añadir"
                      className="input_text_1 w100"
                      tags={campaingsTags}
                      value={campaingsTags}
                      setUpdateTags={setCampaingsTags}
                    />
                     
                     <LabelC>Influencers</LabelC>
            <Field
                      name="influences"
                      component={RenderTagInputComponent}
                      placeholder="Escribe y presiona enter para añadir"
                      className="input_text_1 w100"
                      tags={campaingsInfluencers}
                      value={campaingsInfluencers}
                      setUpdateTags={setCampaingsInfluencers}
                    />
                     





          {mensajesCrearCamp!="" && typeof mensajesCrearCamp == 'object' && mensajesCrearCamp.hasOwnProperty('mensaje') && mensajesCrearCamp.mensaje!=="" ?
          
          <p>{mensajesCrearCamp.mensaje}</p>
          : null}
          <br/><br/><br/>
          
          <Button action="submit" className="submit_verder w100">Enviar</Button>

          </form>
        
        )}
      />
      </div>
    )
}



const RenderDatePicker = ({ name, input, input: { value, onChange } , placeholder,className,setStartDate}) => {
  return (
    <DatePicker
      {...input}
      locale="es"
      placeholderText={placeholder}
      dateFormat="P"
      selected={value && isValid(value) ? toDate(value) : null} // needs to be checked if it is valid date
      disabledKeyboardNavigation
      name={name}
      className={className}
      onChange={(date) => {
        // On Change, you should use final-form Field Input prop to change the value
        // console.log("here");
        // console.log(date);
        if (isValid(date)) {
          input.onChange(format(new Date(date), "dd-MM-yyyy"));
          setStartDate(new Date(date));
          // console.log("propangando");
        } else {
          input.onChange(null);
        }
      }}
    />
  );
};


const RenderCheckbox = ({name, input, onChange, value}) =>{
  return (
                            <label className="switch">
                                <input type="checkbox" value={value} onChange={onChange} name={name} />
                                <div className="slider"></div>
                            </label>
  );
}

const RenderTagInputComponent = ({ name, input, input: { value, onChange } , placeholder,className, tags, setUpdateTags}) => {
  return (
    <ReacTagInputHolder><ReactTagInput
              {...input}
                tags={tags}
                placeholder={placeholder}
                maxTags={10}
                editable={true}
                readOnly={false}
                removeOnBackspace={true}
                onChange ={(newTags)=>{
                  setUpdateTags(newTags)
                }}
            />
            </ReacTagInputHolder>
    
  );
};




export default NuevaCampanaForm;