import {React} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';

import logo from './logo.svg';
import './App.css';

//Redux
import {Provider } from 'react-redux';
import store from './store'; 

import {
  checkIndexAuthorization,
  checkWidgetAuthorization,
} from './lib/check-auth'
import { browserHistory } from './helpers/history';

import Login from './login'
import Registro from './registro'
import WidgetsAux from './WidgetsAux';
import ListadoCampañas from './dashboard';
import DobleFactor from './doblefactor';
import DatosCuenta from './datoscuenta';
//import 'bootstrap/dist/css/bootstrap.min.css';
import SingleCampaign from './campaign';
import Planes from './planes';
import Logout from './logout';
import Planes_Comprar from './planes_comprar';
import AccountConnect from './account_connect';
import DashboardInfluencer from './dashboard_influencer';
import Recovery from './password_recovery';

function App() {
  return (
    <Provider store={store}>
    <Router location={browserHistory.location} navigator={browserHistory}>
      <Routes>
      <Route path="/" element={<Login/>} />
      <Route path="/register" element={<Registro/>} />
      <Route path="/password-reset" element={<Recovery/>} />
      <Route exact path="/dashboard" element={<ListadoCampañas/>} onEnter={checkWidgetAuthorization(store)}></Route>
      <Route exact path="/dashboard_influencer" element={<DashboardInfluencer/>} onEnter={checkWidgetAuthorization(store)}></Route>
      <Route path="/verificacion" element={<DobleFactor/>} onEnter={checkWidgetAuthorization(store)} />
      <Route path="/account-details" element={<DatosCuenta/>} onEnter={checkWidgetAuthorization(store)} />
      <Route exact path="/campaign/:idCamp" element={<SingleCampaign/>} onEnter={checkWidgetAuthorization(store)}></Route>
      <Route exact path="/planes" element={<Planes/>} onEnter={checkWidgetAuthorization(store)}></Route>
      <Route exact path="/planes/:plan/:periodicidad" element={<Planes_Comprar/>} onEnter={checkWidgetAuthorization(store)}></Route>
      <Route exact path="/account_connect" element={<AccountConnect/>} onEnter={checkWidgetAuthorization(store)}></Route>
      <Route path="/logout" element={<Logout/>} />
      
        
      
      </Routes>
    </Router>
  </Provider>
  );
  /*
return (
  <Provider store={store}>
  
  <h1>Aqui</h1>
  <Router history={browserHistory}>
      <Routes>
          <Route path="/" element={<WidgetsAux />} />
      </Routes>
  </Router>
  </Provider>
)
*/
/*
return (


  <Router history={browserHistory}>
  
        <Routes>
          <Route exact path="/"  component={WidgetsAux}/>
        <Route exact path="/login" component={Login} />
        </Routes>
    
  
</Router></Provider>);
*/



}

export default App;
