import React from 'react';
import styled from '@emotion/styled';
import dateFormat, { masks } from "dateformat";
import imageTestCamp from '../../test/images/testCampImg.jpeg';
import iconEdit from '../../test/icons/Group 158.png';
import iconTrash from '../../test/icons/trash-2.png';

/* ****** Elementos styled ****** */
const RowCamp = styled.div`
margin-bottom: 10px;
display: flex;
`;

const Col_12 = styled.div`
width: 100%;
padding-left: 100px;
`;

const CardDiv = styled.div`
// width: 25%;
display: block;
float: left;
// justify-content: center;
// margin: 10px;

width: 320px;
height: 437px;
margin: 20px 30px 50px;
padding: 0 0 22px;
box-shadow: 0 2px 2px 0
    rgba(0, 0, 0, 0.25);
background-color: #fffcfe;

// background-color: white;
color: #272727;
// padding: 20px;
border-radius: 10px;
border: 3px solid transparent;
border-style: solid;
transition: 200ms;
`;

const Div12 = styled.div`
width: 100%;
`;

const Div12_Center = styled.div`
width: 100%;
display: flex;
justify-content: center;
`;

const Div6 = styled.div`
width: 49%;
`;

const Div6_Center = styled.div`
width: 49%;
float: left;
display: flex;
justify-content: center;
`;

const HorizontalLine = styled.div`
width: 282.4px;
height: 1px;
flex-grow: 0;
margin: 23px 16.9px 21px 20.7px;
background-color: #d4d4d4;
`;

const ModalEdit = styled.div`
width: 615px;
height: 962px;
padding: 35px 0 150px 39px;
border-radius: 11px;
box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
background-color: #fff;
`;

const Span_Nombre = styled.span`
width: 200.9px;
height: 19px;
flex-grow: 0;
margin: 0 369.1px 3px 4px;
font-family: Roboto;
font-size: 16px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2d3748;
`
const SinCamp=styled.p`
font-family: Roboto;
font-size: 23px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #2d3748;`
/* ****** END Elementos styled ****** */

const GridCard = ({index,item,getCurrentCamp,handleOpen,changeRoute}) => {

    return (  
<CardDiv className="card" 
                            key={`camp_list_${index}`} >
                                <div 
                                    className="containerImg"
                                    style={{cursor: 'pointer'}}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        //getSingleCamp(item.id);
                                        changeRoute(item.id);
                                    }}
                                    
                                >
                                    <img className="card-image" src={imageTestCamp} alt="Logo" />
                                    <div className="bottom-left">
                                        <span className="titleCard">{item.nombre}</span><br/>
                                        <span className="dateCard">{dateFormat(item.fecha_inicio, "dd, mmmm, yyyy")}</span>
                                    </div>
                                </div>
                                {/* <div className="card-items-bar">
                                    <div className="item-text">
                                        <b>{item.posts}</b> posts.
                                    </div>
                                    <div className="item-text">
                                        <b>{item.comments}</b> comments.
                                    </div>
                                    <div className="item-text">
                                        <b>{item.likes}</b> likes.
                                    </div>
                                </div> */}
                                <Div12_Center className="card-items-bar">
                                    <Div6>
                                        <div className="item-text">
                                            <b>Total Visibility</b><br/>
                                            <Div12>
                                                <span className="numberData">{item.posts}</span>
                                            </Div12>
                                        </div>
                                    </Div6>
                                    <Div6>
                                        <div className="item-text">
                                            <b>Total Interactions</b><br/>
                                            <Div12>
                                                <span className="numberData">{item.likes + item.comments}</span>
                                            </Div12>
                                        </div>
                                    </Div6>
                                </Div12_Center>
                                <Div12_Center className="card-items-bar">
                                    <Div6>
                                        <div className="item-text">
                                            <b>EMV</b><br/>
                                            <Div12>
                                                <span className="numberData">{item.posts}</span>
                                            </Div12>
                                        </div>
                                    </Div6>
                                    <Div6>
                                        <div className="item-text">
                                            <b>Engagement Ratio</b><br/>
                                            <Div12>
                                                <span className="numberData">{item.posts > 0 ? ((item.likes + item.comments)/item.posts).toFixed(2) : 0}%</span>
                                            </Div12>
                                        </div>
                                    </Div6>
                                </Div12_Center>
                                <Div12_Center className="card-items-bar">
                                    <Div6>
                                        <div className="item-text">
                                            <b>Total Contributors</b><br/>
                                            <Div12>
                                                <span className="numberData">{item.comments}</span>
                                            </Div12>
                                        </div>
                                    </Div6>
                                    <Div6>
                                        <div className="item-text">
                                            <b>Total Posts</b><br/>
                                            <Div12>
                                                <span className="numberData">{item.posts}</span>
                                            </Div12>
                                        </div>
                                    </Div6>
                                </Div12_Center>
                                <HorizontalLine/>
                                <Div12 className="card-items-bar">
                                    <div className="item-text ml-20 ">
                                    { new Date(item.fechaFin).getTime() > new Date().getTime()?
                                            <span>Activa</span>
                                            :
                                            <span>Finished</span>
                                        }
                
                                    </div>
                                    <div className="item-text">
                                        <a
                                            href="#"
                                            className="pr-20 btnCard"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                getCurrentCamp(item);
                                                handleOpen();
                                            }}
                                        >
                                            <img src={iconEdit} />
                                        </a>
                                        <a href="#" className="btnCard"><img src={iconTrash}/></a>
                                    </div>

                                </Div12>
                            </CardDiv>

        );
}
 
export default GridCard;