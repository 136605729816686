import { UPDATEACCOUNTDETAILS_REQUESTING } from './constants'

const updateAccountDetailsRequest = function updateAccountDetailsRequest (datos, client) {
  return {
    type: UPDATEACCOUNTDETAILS_REQUESTING,
    datos,
    client
  }
}

export default updateAccountDetailsRequest
