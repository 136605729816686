import {createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk';
import reducer from './reducers';
import createSagaMiddleware from 'redux-saga';
import IndexSagas from './index-sagas';

// Setup the middleware to watch between the Reducers and the Actions
const sagaMiddleware = createSagaMiddleware();
const middleWares = [sagaMiddleware, thunk];
/*eslint-disable */
const composeSetup = process.env.REACT_APP_MODE !== 'production' && typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ : compose
/*eslint-enable */

const store= createStore(
    reducer,
    composeSetup(applyMiddleware(sagaMiddleware))
);
// Begin our Index Saga
sagaMiddleware.run(IndexSagas);

export default store;