import {
  LISTADO_CAMP_REQUESTING,
  CREAR_CAMP_ERROR,
  CREAR_CAMPAÑA,
  MENSAJE_CREAR_CAMPAÑA,
  SET_PAGINACION
} from './constants'

// In order to perform an action of type LOGIN_REQUESTING
// we need an email and password
const listadoCamps = function listadoCamps (client,page=null) {
  return {
    type: LISTADO_CAMP_REQUESTING,
    payload: {client: client,"page": page}
  }
}


export function crearCamp(client, datosBasicos){
  return {
    type: CREAR_CAMPAÑA,
    payload:{
      client: client,
      datosBasicos: datosBasicos
    }
  }
}
export function mensajeCrearCamp(mensaje){
  return {
    type: MENSAJE_CREAR_CAMPAÑA,
    payload: mensaje
  }
}
export function setPaginacion(paginacion){
  return {
    type: SET_PAGINACION,
    payload: paginacion
  }
}


// Since it's the only one here
export default listadoCamps
