import {
  LISTADO_CAMP_REQUESTING,
  LISTADO_CAMP_SUCCESS,
  LISTADO_CAMP_ERROR,
  CREAR_CAMP_ERROR,
  CREAR_CAMPAÑA,
  MENSAJE_CREAR_CAMPAÑA,
  SET_PAGINACION
} from './constants'

const initialState = {
  single_camp: [],
  camps: [],
  requesting: false,
  successful: false,
  requestPage: null,
  messages: [],
  errors: [],
  mensajesCrearCamp: {},
  creacionCamp: {
    iniciado: false,
    id: null,
    datosBasicos: {}
  },
  pagination: {}
}

const reducer = function dashboardReducer (state = initialState, action) {
  switch (action.type) {
    case SET_PAGINACION:
      return {
        ...state,
        pagination: action.pagination
      }
    case MENSAJE_CREAR_CAMPAÑA:
      return {
        ...state,
        mensajesCrearCamp: action.mensaje
      }
    // Set the requesting flag and append a message to be shown
     // Append the error returned from our api
    // set the success and requesting flags to false
    case CREAR_CAMP_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
    // Set the requesting flag and append a message to be shown
    case LISTADO_CAMP_REQUESTING:
      return {
        ...state,
        requesting: true,
        successful: false,
        requestPage: action.payload.page,
        messages: [{ body: 'Logging in...', time: new Date() }],
        errors: [],
      }

    // Successful?  Reset the login state.
    case LISTADO_CAMP_SUCCESS:
      // console.log(action);
      // console.log(action.response);
      return {
        ...state,
        camps: action.response,
        errors: [],
        messages: [],
        requesting: false,
        successful: true,
        requestPage: null,
        
      }

    // Append the error returned from our api
    // set the success and requesting flags to false
    case LISTADO_CAMP_ERROR:
      return {
        ...state,
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
    
      case CREAR_CAMPAÑA:
        return {
            ...state,
            creacionCamp: {
              iniciado: true,
              id: null,
              datosBasicos: action.payload.datosBasicos
            }
      }

    default:
      return state
  }
}

export default reducer
