import React, { useState } from 'react'
import { Form, Field } from 'react-final-form';
import styled from '@emotion/styled';

import DropForm from '../dropzone/dropzoneSupscripcionFormulario';

const ImgPerfiles = styled.img`
    width: 100px;
    height: 100px;
    flex-grow: 0;
    padding: 24.3px 21.5px 24.2px;
    border-radius: 201px;

    &:hover {border: solid 1px #6cdb92;}
`

const Div = styled.div`
  float: right;
  margin: 10px 130px 0px 0px;
` 

const Button = styled.button`
  width: 180px;
  height: 38px;
  flex-grow: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 33px;
  border: solid 1px #9ae6b4;
  background-color: #6cdb92;
  float: right;
  margin: 10px 130px 0px 0px;
    
    & > span {
      height: 10px;
      align-self: stretch;
      flex-grow: 0;
      font-family: Roboto;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
`

const SelectImgForm = ({img, cargarImg, mostrarCrear, setMostrarCrear, imagenesPerfil, setImagenesPerfil}) => {


  const onSubmit = (values) =>{

 
    if (values.imagen) {

        const MyBlob = new Blob([values.imagen[0]], {type : "image/png"});
        
        
        var reader = new FileReader();
        reader.readAsDataURL(MyBlob);
        reader.onloadend = function () {
            var base64data = reader.result;
            
            cargarImg(base64data);


        }
   
    }
   
    setMostrarCrear(!mostrarCrear);

  }


  
  
  
  return (
    <Form
    onSubmit={onSubmit}
    render={({ handleSubmit, values }) => (
      <form id="form-select-photo" onSubmit={handleSubmit}>
          {
            imagenesPerfil.length === 0 ?

            <></>
      
            :

            imagenesPerfil.map( imagen => (
                
                <ImgPerfiles src={imagen} name={imagen} onClick={() => cargarImg(imagen)}></ImgPerfiles>
             
              ))
          }
          
            <Div >
                <Field
                    name="imagen"
                    component={DropForm}
                    currentFile={values.imagen}
                    accept="image/*"
                    />
            </Div>
            
            <Button action="submit"><span>Seleccionar Foto</span></Button>

        </form>
         )}
         />
         
  )
}

export default SelectImgForm;