/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../helpers/metronic/helpers'
import {getCSSVariableValue} from '../../assets/charts_css'
import {Dropdown1} from '../../assets/Dropdown1'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  likesPercent: number
}

const MixedWidget7: React.FC<Props> = ({className, chartColor, chartHeight, likesPercent}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, chartHeight, likesPercent))
    if (chart) {
      chart.render()
    }

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartRef, likesPercent])
  

  return (
    <div className={`mt-20 ${className}`}>
      {/* begin::Body */}
      <div className='card-body d-flex flex-column'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart'></div>
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

const chartOptions = (chartColor: string, chartHeight: string, likesPercent: number): ApexOptions => {
  // const baseColor = getCSSVariableValue('--bs-' + chartColor)
  // const lightColor = getCSSVariableValue('--bs-light-' + chartColor)
  // const labelColor = getCSSVariableValue('--bs-gray-700')

  const baseColor = '#E42C63'
  const lightColor = '#DBDBDA'
  const labelColor = '#717171'

  return {
    series: [(likesPercent)],
    chart: {
      fontFamily: 'inherit',
      height: chartHeight,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 0,
          size: '65%',
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: '700',
          },
          value: {
            color: labelColor,
            fontSize: '30px',
            fontWeight: '700',
            offsetY: 12,
            show: true,
            formatter: function (val) {
              return val + '%'
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: '100%',
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: 'round',
    },
    labels: ['Progress'],
  }
}

export {MixedWidget7}
