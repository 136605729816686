import {
  PLANES_REQUESTING,
  PLANES_SUCCESS,PLANES_ERROR,PLANES_PROCESAR_PAGO,
  CUPON_DESCUENTO_REQUESTING
} from './constants'

// In order to perform an action of type PLAN_REQUESTING
// we need an email and password
export const solicitandoPago = function solicitandoPago () {
  return {
    type: PLANES_REQUESTING
  }
}
export const recibidoPlanOK = function recibidoPlanOK (){
  return {
    type: PLANES_SUCCESS
  }

}

export const recibidoPlanKO = function recibidoPlanKO(error){
  return {
    type: PLANES_ERROR,
    error: error
  }
}


export const procesarPago = function procesarPago(datos,client){
  return {
    type: PLANES_PROCESAR_PAGO,
    datos: datos,
    client: client
  }
}

export const addCupon = function addCupon(cupon,client){
  return {
    type: CUPON_DESCUENTO_REQUESTING,
    cupon: cupon,
    client: client
  }
}


// Since it's the only one here
export default solicitandoPago
