import React, { useState, useEffect, Fragment } from 'react';
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { addCupon } from '../../planes_comprar/actions';
import { useDispatch, useSelector } from 'react-redux';

/* *********** STYLED COMPONENTS *********** */
const Card1=styled.div`
width: 326px;
  height: 562px;
  flex-grow: 0;
  margin: 0px 5px;
  padding: 20px 5px 24px 25px;
  border-radius: 26px;
  box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
  background-color: #3A6EB0;
  position: relative;
  z-index:2;
  display: inline-block;
  vertical-align: top;
  & li{
        margin: 0 0 3px 11.2px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & ul {
        list-style: none;
        padding-left: 0px;
    }

    & ul li img {
        vertical-align: middle;
    }
    & ul li{
        padding-bottom: 10px;
    }
      @media (max-width: 500px) {
        margin-bottom: 20px;
      }
`

const DivRecomendado = styled.div`
  margin: 0 147px 26px 0;
  padding: 7px 16px 6px 15px;
  border-radius: 13.5px;
  background-color: #2c5282;
  text-align: center;
  & span{
    font-family: Avenir;
  font-size: 13px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: #c7d8ed;
  text-transform: uppercase;
  }
`

const TipoPlan=styled.span`
  flex-grow: 0;
  transform: rotate(-270deg);
  font-family: Avenir;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: #c7d8ed;
  text-transform: uppercase ;
  display: inline-block ;
  position: absolute;
  right:0px;
  top:40px;
`

const Text4=styled.p`
  flex-grow: 0;
  margin: 26px 193px 10px 5px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const Text5=styled.p`
  margin: 0 10px 0 0;
  font-family: Roboto;
  font-size: 36px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  & span{
    margin: 19px 0 7px 10px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
`
const Text6=styled.p`
  margin: 2px 0 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const BotonContratar=styled.button`
  margin: 28px 14px 15px 22px;
  padding: 13px 46px 14px;
  border-radius: 24px;
  border: solid 0 #9ae6b4;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c5282;
  cursor: pointer;
`
const VerTodosPlanes=styled.a`
  flex-grow: 0;
  margin: 15px 59px 0 66px;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration:none;
  cursor: pointer;
`

/* Cupon */
const TextCupon = styled.p`
  font-family: Roboto;
  font-size: 18px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const InputCode=styled.input`
  width: 211.1px;
  height: 17px;
  margin: 0 4.9px 4px 0;
  opacity: 1;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border:0px;
  border-bottom: 2px solid #fff;
  background: transparent;
  margin-bottom: 20px;
  //position: absolute;
  display: inline-block ;
  &::placeholder {
    color:#fff;
  }
`
const Row=styled.div`
  width:100%;
  display:inline-block;
  clear:both;
  //position: relative;
  padding-bottom:40px;
`
const BotonComprobarCodigo= styled.button`
  //position: absolute;
  //right: 0px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  border:0px;
  background: transparent;
  display: inline-block ;

`
/* *********** END STYLED COMPONENTS *********** */

const BasicInfluencerPlan = ({showBtnPlanes,showBtnContratar, showRenderCupon}) => {

    const navigate = useNavigate();
    const [cupon, setCupon] = useState('');
    const dispatch= useDispatch();
    const client = useSelector(state=> state.client);
    const estadoCupon=  useSelector(state=> state.plan.cupon);
    /* ****** STATES ****** */

    /* ****** END STATES ****** */


    // Ir a la Comprar Plan Basic
    const changeRoute = (plan,periodicidad) => {
      navigate(`/planes/${plan}/${periodicidad}`);
    }

    return (
        <Fragment>
            <Card1 className="Card1">
                 <DivRecomendado className="DivRecomendado"><span>recomendado</span></DivRecomendado>
                <TipoPlan className="TipoPlan">Mensual</TipoPlan>
                <Text4 className="Text4">Basic</Text4>
                <Text5 className="Text5">9€ <span>/mes</span></Text5>
                <Text6 className="Text6">Gestiona todos tus posts al mejor precio.</Text6>
                <ul>
                    <li><img src="/assets/images/check-circle-1.png"/> <strong>Post ilimitados</strong></li>
                    <li><img src="/assets/images/check-circle-1.png"/> Informe de EMV completos</li>
                </ul>

            {showRenderCupon === true ? 
              <Fragment>
              <TextCupon>¿Tienes un código promocial?</TextCupon>
              <Row>
                {
                  estadoCupon.requesting === false && estadoCupon.success === false ?
                    <Fragment>
                                  <InputCode type="text" placeholder="Ejm INFLUEN70" className="input_code_promo"
                                    onChange={(e)=>{
                                      e.preventDefault();
                                      setCupon(e.target.value);
                                    }}
                                    />
                                    <BotonComprobarCodigo
                                    onClick={(e)=>{
                                      e.preventDefault();
                                      dispatch(addCupon(cupon,client))
                                    }}
                                    >Comprobar</BotonComprobarCodigo>

                    </Fragment>
                  :
                  estadoCupon.success === true ? 
                                  <p>Cupón validado con éxito</p>
                  : 
                                    <p>Validando el cupón</p>

                }
                {
                  estadoCupon.error !== null ?
                    <p>{estadoCupon.error}</p>  
                  : null
                }

              </Row>
              </Fragment>
              : null }
              {showBtnContratar === true?
                <BotonContratar 
                  className="BotonContratar"
                  onClick={(e) => {
                    let plan = "basic_influencer";
                    changeRoute(plan,"mensual");
                  }}
                >
                  Contratar Plan Basic
                </BotonContratar>
                : null }
                {showBtnPlanes === true ? 
                  <VerTodosPlanes 
                    className="VerTodosPlanes"
                    onClick={(e) => {
                      navigate("/planes");
                    }}
                  >
                    <img className="verticalAlign arrowLeftIcon" src="/assets/images/arrow_left_icon.png"></img>
                    <span className="verticalAlign">Ver todos los planes</span>
                  </VerTodosPlanes>
                :null}
            </Card1>
        </Fragment>
    );
}

export default BasicInfluencerPlan;