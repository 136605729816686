// because Fetch doesn't recognize error responses as
// actual errors since it's technically completing the response...
export function handleApiErrors (response) {
  if (response.status === 403){
      //permiso denegado
      const responseError = {
        type: 'Error plan',
        message: response.json().then(json => json) || 'Something went wrong',
        data: response.data || '',
        code: response.code || '',
      };

    let error = new Error();
    error = { ...error, ...responseError };
    throw (error);
  }else if (response.status === 401){
    const responseError = {
      type: 'Error JWT',
      message: response.json().then(json => json) || 'Something went wrong',
      data: response.data || '',
      code: response.code || '',
    };
    let error = new Error();
    error = { ...error, ...responseError };
    //Lo tengo que envisr al login.
    //verifico que no esté ya en el login
    if (window.location.pathname!=="/"){
      window.location.href="/";
    }
    throw (error);
  }else if (!response.ok){ throw Error(response.statusText)}else
  {
     return response
  }
}
export function handleApiErrorsPaypal (response) {
  if (response.status === 400){
      //permiso denegado
      console.log(response);
      const responseError = {
        type: 'tarjeta_no_valida',
        message: response.json().then(json => json) || 'Something went wrong',
        data: response.data || '',
        code: response.code || '',
      };

    let error = new Error();
    error = { ...error, ...responseError };
    throw (error);
  }else if (response.status === 401){
    const responseError = {
      type: 'Error JWT',
      message: response.json().then(json => json) || 'Something went wrong',
      data: response.data || '',
      code: response.code || '',
    };
    let error = new Error();
    error = { ...error, ...responseError };
    //Lo tengo que envisr al login.
    //verifico que no esté ya en el login
    if (window.location.pathname!=="/"){
      window.location.href="/";
    }
    throw (error);
  }else if (!response.ok){ throw Error(response.statusText)}else
  {
     return response
  }
}
