import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//TRANSLATIONS
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import global_es from "./translations/es/global.json";
import global_en from "./translations/en/global.json";
i18next.init({
  interpolation: {escapeValue: false},
  // lng: window._env_.REACT_APP_DEFAULT_LANGUAGE ? window._env_.REACT_APP_DEFAULT_LANGUAGE : "en",
  lng: `${process.env.REACT_APP_DEFAULT_LANGUAGE}` ? `${process.env.REACT_APP_DEFAULT_LANGUAGE}` : "es",
  resources: {
    es: {
      global: global_es
    },
    en: {
      global: global_en
    },
  }
});

ReactDOM.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
