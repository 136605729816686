import { take, fork, cancel, call, put, cancelled,takeLatest } from 'redux-saga/effects'

// We'll use this function to redirect to different routes based on cases
import { push } from 'react-router-redux';

// Helper for api errors
import { handleApiErrors,handleApiErrorsPaypal } from '../lib/api-errors'

// Our login constants
import {
  PLANES_PROCESAR_PAGO,PLANES_ERROR,PLANES_SUCCESS,
  CUPON_DESCUENTO_REQUESTING,
  CUPON_DESCUENTO_ERROR,
  CUPON_DESCUENTO_SUCCESS
} from './constants'

// So that we can modify our Client piece of state
import {
  setClient,
  unsetClient,
} from '../client/actions'

import {
  CLIENT_UNSET,
} from '../client/constants'


const checkoutURL = `${process.env.REACT_APP_API_URL}/api/checkout`
const addCuponURL = `${process.env.REACT_APP_API_URL}/api/couponApi`



function checkoutApi (datos, client) {
  

  return fetch(checkoutURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: datos,
  })
    .then(handleApiErrorsPaypal) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
       return res;
    })
    .catch((error) => { 
      console.log(error);
      throw error })
}

function addCuponApi (cupon, client) {
  

  return fetch(addCuponURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify({code: cupon, planId: 'elPlanId'}),
  })
    .then(handleApiErrorsPaypal) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
       return res;
    })
    .catch((error) => { 
      console.log(error);
      throw error })
}


function* checkoutFlow (action) {
  try {
    const { client, datos } = action
    const response = yield call(checkoutApi, datos,client)
    if (response.hasOwnProperty('status') && response.status==="active"){
      yield put({ type: PLANES_SUCCESS });
    }else{
      if (response.hasOwnProperty('error') && response.error.hasOwnProperty("code") && response.error.code==="card_declined"){
        yield put({ type: PLANES_ERROR, error: "El pago ha sido denegado" })
        return
      }else{
        yield put({ type: PLANES_ERROR, error: "Se he producido un error en el pago, vuelva a intentarlo" })
        return
      }
  } 
    

  } catch (error) {
    // if the api call fails, it will "put" the UPDATEACCOUNTDETAILS_ERROR
    // into the dispatch along with the error.
    console.log(error);
    if (error.hasOwnProperty('type')){
      if (error.type==="tarjeta_no_valida"){
        yield put({ type: PLANES_ERROR, error: "El pago ha sido denegado" })
        return
      }
    }
    yield put({ type: PLANES_ERROR, error: "Error al procesar el pago. Vuelva a intentarlo." })
  }
}


function* addCuponFlow (action) {
  try {
    const { client, cupon } = action
    const response = yield call(addCuponApi, cupon,client)
    if (response.hasOwnProperty('status') && response.status==="ok"){
      yield put({ type: CUPON_DESCUENTO_SUCCESS, cuponid: response.cuponid});
    }else{
      if (response.hasOwnProperty('error')){
        yield put({ type: CUPON_DESCUENTO_ERROR, error: response.error })
        return
      }else{
        yield put({ type: CUPON_DESCUENTO_ERROR, error: "Se he producido un error al comprobar el cupón, vuelva a intentarlo" })
        return
      }
  } 
    

  } catch (error) {
    // if the api call fails, it will "put" the UPDATEACCOUNTDETAILS_ERROR
    // into the dispatch along with the error.
    console.log(error);
   
    yield put({ type: CUPON_DESCUENTO_ERROR, error: "Error al procesar el cupón. Vuelva a intentarlo." })
  }
}




// Watches for the UPDATEACCOUNTDETAILS_REQUESTING action type
// When it gets it, it will call verificarFlow()
// WITH the action we dispatched
function* planesWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(PLANES_PROCESAR_PAGO, checkoutFlow)
  yield takeLatest(CUPON_DESCUENTO_REQUESTING, addCuponFlow)
 
}

export default planesWatcher
