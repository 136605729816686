import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

import styled from "@emotion/styled";

const Inputperfil = styled.button`
    width: 80px;
    height: 80px;
    flex-grow: 0;
    padding: 24.3px 21.5px 24.2px;
    border-radius: 201px;
    background-color: #eaeaea; 
      
`

const DropForm = ({ input, ...res }) => {

    const [files, setFiles] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        input.onChange(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)

        })));
    }, []);

  const removeFile = (name) => {
        // find the index of the item
         // remove the item from array
        const validFileIndex = files.findIndex(e => e.name === name);
        files.splice(validFileIndex, 1);
        // update validFiles array
        setFiles([...files]);
        input.onChange({});
        const acceptedFilesIndex = acceptedFiles.findIndex(e => e.name === name);
        acceptedFiles.splice( acceptedFilesIndex, 1)
    }

   const thumb={ }
   const thumbInner={ }
   const img={
    width: 100,
    height: 100,
    flexGrow: 0,
    borderRadius: 201,
    backgroundColor: '#EAEAEA'
   }
   const thumbsContainer={ 
    width: 75,
    height: 75,
    flexGrow: 0,
    padding: 24,
    borderRadius: 201,
    backgroundColor: '#EAEAEA'
   }

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
    <div style={thumbInner}>
      <div className="file-remove" onClick={() => removeFile(file.name)}>X</div>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, accept: 'image/*' });
  const {currentFile} = res;
  
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  
  console.log(currentFile);
  

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      { acceptedFiles.length === 0 ? 
     
                
                isDragActive ? (
                    
                  <aside style={thumbsContainer}>
                  <span>Subir foto</span>
                    </aside>
                  
                

                ) : (
                
                <aside style={thumbsContainer}>
               <span>Subir foto</span>
                 </aside>
                )
                
                :
                <aside >
                    {thumbs}
                </aside>
                }
    </div>
  );
};

export default DropForm;
