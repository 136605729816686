import { take, fork, cancel, call, put, cancelled, takeEvery } from 'redux-saga/effects'

// We'll use this function to redirect to different routes based on cases
import { push } from 'react-router-redux';

// Helper for api errors
import { handleApiErrors } from '../lib/api-errors'

// Our recovery constants
import {
  RECOVERY_REQUESTING,
  RECOVERY_SUCCESS,
  RECOVERY_ERROR,
} from './constants'

// So that we can modify our Client piece of state
import {
  setClient,
  unsetClient,
} from '../client/actions'

import {
  CLIENT_UNSET,
} from '../client/constants'

const recoveryUrl = `${process.env.REACT_APP_API_URL}/nonapi/reset-password`

function recoveryApi (email) {
  return fetch(recoveryUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
    },
    body: JSON.stringify({ email }),
  })
    .then(handleApiErrors)
    .then(response => response.json())
    .then(json => json)
    .catch((error) => { throw error })
}



function* recoveryFlow (action) {
  let token
  try {
    // try to call to our recoveryApi() function.  Redux Saga
    // will pause here until we either are successful or
    // receive an error
    const {email} = action;
   
    const response = yield call(recoveryApi, email)
    console.log(response);

    yield put({ type: RECOVERY_SUCCESS })

   
  } catch (error) {
    // error? send it to redux
    console.log(error);
    
    yield put({ type: RECOVERY_ERROR, error })
  } finally {
    // No matter what, if our `forked` `task` was cancelled
    // we will then just redirect them to recovery
    if (yield cancelled()) {
      //yield put(push('/login'));
    }
  }

  // return the token for health and wealth
  return token
}

// Our watcher (saga).  It will watch for many things.
function* recoveryWatcher () {

  yield takeEvery(
    RECOVERY_REQUESTING, recoveryFlow
  )
   

}

export default recoveryWatcher
