import { call, put, takeLatest, delay } from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { handleApiErrors } from '../lib/api-errors'
import {
  UPDATEACCOUNTDETAILS_REQUESTING,
  UPDATEACCOUNTDETAILS_SUCCESS,
  UPDATEACCOUNTDETAILS_ERROR
} from './constants'

// The url derived from our .env file
const actualizarDatosCuentaURL = `${process.env.REACT_APP_API_URL}/api/users/`




function actualizarDatosApi (datos, client) {
  let datosCuenta={
    "empresa": datos.nombre,
    "cifEmpresa": datos.nif,
    "direccion": datos.direccion,
    "cargo": datos.cargo,
    "telefono": datos.telefono,
    "numeroEmpleados": datos.numero_empleados,
    //"sector": "string",
    "privacyPolicy": datos.acepto,
    "nif": datos.nif,
    //"razonSocial": "string",
    "ciudad": datos.ciudad,
    "provincia":datos.provincia,
    "pais": datos.pais

  }
  /*
  acepto: true
apellidos: "apellidos"
cargo: "cargo"
cif: "cif"
cuidad: "ciudad"
direccion: "direccion"
nif: "nif"
nombre: "nombre"
numero_empleados: "1"
pais: "pais"
provincia: "provincia"
razon_social: "razon social"
telefono: "tlf"
  */

  return fetch(actualizarDatosCuentaURL+client.decode.id, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(datosCuenta),
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
       return res;
    })
    .catch((error) => { 
      console.log(error);
      throw error })
}



// This will be run when the UPDATEACCOUNTDETAILS_REQUESTING
// Action is found by the watcher
function* actualizarDatosFlow (action) {
  try {
    const { client, datos } = action
    const response = yield call(actualizarDatosApi, datos,client)
    yield put({ type: UPDATEACCOUNTDETAILS_SUCCESS, response })
    if (client.decode.is_verified===false){
      yield delay(1); // Add delay
      yield put(push('/verificacion'));
      document.location='/verificacion';
    }else{
      yield put(push('/dashboard'));
    }

  } catch (error) {
    // if the api call fails, it will "put" the UPDATEACCOUNTDETAILS_ERROR
    // into the dispatch along with the error.
    console.log(error);
    yield put({ type: UPDATEACCOUNTDETAILS_ERROR, error })
  }
}




// Watches for the UPDATEACCOUNTDETAILS_REQUESTING action type
// When it gets it, it will call verificarFlow()
// WITH the action we dispatched
function* actualizarDatosCuentaWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(UPDATEACCOUNTDETAILS_REQUESTING, actualizarDatosFlow)
 
}

export default actualizarDatosCuentaWatcher
