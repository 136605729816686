import React, { Component , Fragment,useEffect,useState} from 'react';
import styled from 'styled-components';
import {getParameterByName} from "../lib/helpers";

const PaginadorDiv = styled.div`
     text-align: center;

`;

const PaginaInactiva = styled.button`
     min-width: 32px;
     height: 32px;
     margin: 9px 5px 0 10px;
     padding: 4px;
     border-radius: 4px;
     border: solid 1px #dfe3e8;
     background-color: #fff;
     font-family: Roboto;
     font-size: 15px;
     font-weight: 600;
     font-stretch: normal;
     font-style: normal;
     line-height: normal;
     letter-spacing: normal;
     text-align: center;
     color: rgba(0, 0, 0, 0.2);

     &:hover{
          border: solid 1px #3a6eb0;
          color: #3a6eb0;
     }
     &.active{
          border: solid 1px #3a6eb0;
          color: #3a6eb0;
     }

`;


const PaginadorRow = ({paginacion, callback_click}) => {
    return (
            <PaginadorDiv>
                  {
                        paginacion.hasOwnProperty('hydra:first') ? 
                     
                     <PaginaInactiva
                        onClick={(e)=>{e.preventDefault();callback_click(
                             paginacion['hydra:first']
                        );}}> Primera</PaginaInactiva>
                        : null

                  }
                {
                  paginacion.hasOwnProperty('hydra:previous') ? 
                     <PaginaInactiva
                        onClick={(e)=>{e.preventDefault();callback_click(
                             paginacion['hydra:previous']
                        );}}> &lt;</PaginaInactiva>
                        : null
                  }

                  { 
                       paginacion.hasOwnProperty('@id') ? 
                     <PaginaInactiva className='active'
                        onClick={(e)=>{e.preventDefault();callback_click(
                             paginacion['@id']
                        );}}> {getParameterByName('page',paginacion['@id'])}</PaginaInactiva>
                        : null

                  }
                
                {
                        paginacion.hasOwnProperty('hydra:next') ? 
                     <PaginaInactiva
                        onClick={(e)=>{e.preventDefault();callback_click(
                             paginacion['hydra:next']
                        );}}> &gt;</PaginaInactiva>
                        : null

                  }
                  
                  {
                     paginacion.hasOwnProperty('hydra:last') ? 
                     <PaginaInactiva
                        onClick={(e)=>{e.preventDefault();callback_click(
                             paginacion['hydra:last']
                        );}}> Última</PaginaInactiva>
                        : null

                  }
            </PaginadorDiv>


      );
}
 
export default PaginadorRow;