import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import dateFormat from "dateformat";
import { handleApiErrors } from "../lib/api-errors";
import { useNavigate } from "react-router-dom";
import imageTestCamp from "../test/images/testCampImg.jpeg";
import iconEdit from "../test/icons/Group 158.png";
import iconTrash from "../test/icons/trash-2.png";

const ImgUser = styled.div`
  width: 44px;
  height: 44px;
  flex-grow: 0;
  margin: 0 15px 0 0;
  background-color: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 50%;
  display: inline-block;
`;
const NombreUserPost = styled.span`
  flex-grow: 0;
  margin: 2px 0 1px 15px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  display: inline-block;
  vertical-align: top;
`;
const BloqueColPostResultRow = styled.div`
  display: inline-block;
  vertical-align: top;
  flex-grow: 0;
  margin: 0 14px 0 0;
  padding: 8.2px 13px 8.6px 18px;
  border-radius: 10px;
  background-color: #fff;
  font-size: 13px;
`;

const FechaUserPost = styled.div`
  flex-grow: 0;
  margin: 1px 0px 6px 15px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: left;
  color: rgba(0, 0, 0, 0.2);
`;
const PostResultRowLikes = styled.span`
  flex-grow: 0;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2a4365;
  strong {
    width: 100%;
    clear: both;
    display: block;
  }
`;
const PostResultRow = styled.div`
  width: calc(100% - 50px);
  padding: 5px 27px;
`;

const DivPostRow = styled.div`
  width: 98%;
  height: auto;
  left: 22px;
  top: 640px;

  background: #e8f1f8;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  margin-top: 20px;
  display: block;
`;

/* ******************* ICONS ******************* */
const Icon_RRSS = styled.img`
  width: 30px;
  padding-top: 5px;
`;

const IconRightMenu = styled.img`
  width: 20px;
  padding-top: 5px;
  cursor: pointer;
`;

const Icon_Likes = styled.span`
  padding-top: 5px;
  background-image: url(./assets/images/logos_group.png);
  width: 20px;
  height: 16px;
  background-position: 0 0;
  display: inline-block;
`;

const Icon_Comentarios = styled.span`
  padding-top: 5px;
  background-image: url(./assets/images/logos_group.png);
  width: 20px;
  height: 16px;
  background-position: -60px 0;
  display: inline-block;
`;

const Icon_Retweet = styled.span`
  padding-top: 5px;
  background-image: url(./assets/images/logos_group.png);
  width: 20px;
  height: 16px;
  background-position: -116px 0;
  display: inline-block;
`;

const Icon_Interacciones = styled.span`
  padding-top: 5px;
  background-image: url(./assets/images/logos_group.png);
  width: 20px;
  height: 16px;
  background-position: -177px 0;
  display: inline-block;
`;

const Icon_Engagement = styled.span`
  padding-top: 5px;
  background-image: url(./assets/images/logos_group.png);
  width: 20px;
  height: 16px;
  background-position: 73px 0;
  display: inline-block;
`;

const Icon_EMV = styled.span`
  padding-top: 5px;
  background-image: url(./assets/images/logos_group.png);
  width: 13px;
  height: 16px;
  background-position: 13px 0;
  display: inline-block;
`;
/* ******************* END ICONS ******************* */

const Div12 = styled.div`
  width: 100%;
  display: block;
`;

const Div12_Center = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Div1 = styled.div`
  float: left;
  padding-left: 30px;
`;

const Div3 = styled.div`
  width: 20%;
  float: left;
  padding-top: 5px;
`;

const Div4 = styled.div`
  width: 30%;
  float: left;
  padding-top: 5px;
`;

const Div7 = styled.div`
  width: 70%;
  float: left;
  padding-top: 5px;
  padding-bottom: 10px;
`;

const Div9 = styled.div`
  width: 70%;
  float: left;
`;

const Div10 = styled.div`
  width: 80%;
  float: left;
`;

const Span_stats = styled.span`
  font-size: 12px;
`;

/* ****** Elementos styled ****** */
const RowCamp = styled.div`
  margin-bottom: 10px;
  display: flex;
`;

const Col_12 = styled.div`
  width: 100%;
  padding-left: 100px;
`;

const CardDiv = styled.div`
  // width: 25%;
  display: block;
  float: left;
  // justify-content: center;
  // margin: 10px;

  width: 320px;
  height: auto;
  margin: 20px 30px 50px;
  padding: 0 0 22px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.25);
  background-color: #fffcfe;

  // background-color: white;
  color: #272727;
  // padding: 20px;
  border-radius: 10px;
  border: 3px solid transparent;
  border-style: solid;
  transition: 200ms;
`;

const Div6 = styled.div`
  width: 49%;
`;

const Div6_Center = styled.div`
  width: 49%;
  float: left;
  display: flex;
  justify-content: center;
`;

const HorizontalLine = styled.div`
  width: 282.4px;
  height: 1px;
  flex-grow: 0;
  margin: 23px 16.9px 21px 20.7px;
  background-color: #d4d4d4;
`;

const ModalEdit = styled.div`
  width: 615px;
  height: 962px;
  padding: 35px 0 150px 39px;
  border-radius: 11px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
`;

const Span_Nombre = styled.span`
  width: 200.9px;
  height: 19px;
  flex-grow: 0;
  margin: 0 369.1px 3px 4px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
`;
const SinCamp = styled.p`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2d3748;
`;
/* ****** END Elementos styled ****** */

const StoryRow = ({ item, client, dispatch, deleteMentionedRequesting }) => {
  const navigate = useNavigate();

  /* ***************** STATES ***************** */
  const [postId, setPostId] = useState(item.id);
  const [currentCamp, setCurrentCamp] = useState({
    id: "",
    nombre: "",
    fecha: "",
    likes: "",
    imagen: "",
    posts: "",
    comments: "",
  });
  /* ***************** END STATES ***************** */

  /* ***************** CUSTOM FUNCTIONS ***************** */
  const updatePost = () => {
    // let item = {eliminado: true};

    // fetch(`${process.env.REACT_APP_API_URL}/api/mentioned_media/${postId}`, {
    //     method: 'PUT',
    //     headers: {
    //         'Content-Type': 'application/ld+json',
    //         Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    //       },
    //     body: JSON.stringify(item)
    // })
    // .then((result) => {
    //     result.json().then((resp) => {

    //     })
    // })

    dispatch(deleteMentionedRequesting(client, postId));
  };

  // Ir a la Story de la campaña
  const changeRoute = (idStory) => {
    navigate("/story/" + idStory);
  };

  const getCurrentCamp = (item) => {
    setCurrentCamp(item);
    // setShowEditModal(true);
  };
  /* ***************** END CUSTOM FUNCTIONS ***************** */


  return (
    <Div4 className="Div4">
      <CardDiv className="card CardDiv" key={`story_${item.id}`}>
        <div
          className="containerImgStory"
          style={ item.tagEncontrado === true ? { backgroundColor: "green" } : { backgroundColor: "yellow" } }
          onClick={(e) => {
            e.preventDefault();
            changeRoute(item.id);
          }}
        >
          <img className="card-image" src={imageTestCamp} alt="Logo" />
          <div className="bottom-left">
            <span className="titleCard">@{item.usuario}</span>
            <br />
          </div>
        </div>
        <Div12_Center className="card-items-bar">
          <Div4>
            <div className="item-text">
              <b>Fecha</b>
              <br />
              <Div12>
                {dateFormat(item.fecha_inicio, "dd/mm/yyyy")}
              </Div12>
            </div>
          </Div4>
          <Div4>
            <div className="item-text">
              <b>EMV</b>
              <br />
              <Div12>
                <span className="numberData">{item.emv}€</span>
              </Div12>
            </div>
          </Div4>
          <Div4>
            <div className="item-text">
              <b>Followers</b>
              <br />
              <Div12>
                <span className="numberData">{item.followers}</span>
              </Div12>
            </div>
          </Div4>
        </Div12_Center>
      </CardDiv>
    </Div4>
  );
};

export default StoryRow;
