import React from "react";
import { Form, Field, option } from 'react-final-form';
import {useDispatch, useSelector, useState} from 'react';
import styled from '@emotion/styled';

import SelectImgForm from "./formularios/selectImgForm";

const DivPopUpCrearCamp = styled.div`
  width: 40%;
  height: 350px;

  padding: 35px 15px 150px 39px;
  margin: 50px 600px 50px 500px;
  border-radius: 11px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff;
  position: absolute;
  right: 0px;
  z-index: 1;

  @media (max-width:500px){
    width: 90%;
    margin: 500px 0px 0px 0px;
    height: 470px;
    }  
`;

const PopSelectImg = ({mostrarCrear,setMostrarCrear,img, cargarImg, imagenesPerfil, setImagenesPerfil}) => {
    const Titulo =styled.h1`
        width: 100%;
        height: 26px;
        padding: 30px 00px 0px 0px;
        font-family: Roboto;
        font-size: 23px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
        font-weight: bold;
        `;
    const Close = styled.div`
        width: 20px;
        height: 20px;
        display: inline-block;
        float: right;
        
    `;

    

    if (mostrarCrear){
    return (  
        <DivPopUpCrearCamp>
            <Close onClick={(e)=>{e.preventDefault();setMostrarCrear(!mostrarCrear)}}><img src="/assets/images/close-icon.svg"/></Close>
            <Titulo>Elige una imagen de perfil</Titulo>
            <hr></hr>
            <SelectImgForm 
                img={img}
                cargarImg={cargarImg}
                mostrarCrear={mostrarCrear}
                setMostrarCrear={setMostrarCrear}
                imagenesPerfil={imagenesPerfil}
                setImagenesPerfil={setImagenesPerfil}
            />
        
        </DivPopUpCrearCamp>
            

            );
    }else{
        return (
            null
        );
    }
}
 
export default PopSelectImg;