import { VERIFY_REQUESTING,  RESEND_VERIFY_REQUESTING } from './constants'

const verifyRequest = function verifyRequest (codigo, client) {
  return {
    type: VERIFY_REQUESTING,
    codigo,
    client
  }
}
export const resendVerifyRequest = function resendVerifyRequest (client) {
  return {
    type: RESEND_VERIFY_REQUESTING,
    client
  }
}

export default verifyRequest
