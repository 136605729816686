import { Link } from "react-router-dom";
const MenuSuperior = ({showDerecha,marginBottom}) => {
    // console.log(showDerecha);
    return ( 
        <div className="menusuperior" style={{'marginBottom': (marginBottom === 'No' ? '0px': '25px')}}>
             <div className="pull-left">
                  <img src="/assets/images/logo.png" className="mt-20" />
                 
             </div>
             {(showDerecha !== "false") ?
             
            <div className="pull-right">
                <Link to="/account_connect" ><img src="/assets/images/avatar.png" className="avatar mt-10" /></Link>
            </div>
            : null }
        </div>
      );
}
 
export default MenuSuperior;