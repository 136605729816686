import React, { Component, Fragment, PropTypes, useEffect } from 'react'
import { Form, Field } from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import { Link, Navigate } from 'react-router-dom'

import {unsetClient} from '../client/actions';

// If you were testing, you'd want to export this component
// so that you can test your custom made component and not
// test whether or not Redux and Redux Form are doing their jobs
const Logout = () => {

  

  const login = useSelector(state => state.login);
  
  const dispatch = useDispatch();
  
  useEffect(()=>{
    dispatch(unsetClient());
    localStorage.removeItem('token');
  },[]);
  

 
    return (
      <Navigate to={{ pathname: '/', state: ''}} />
    )
          }

export default Logout