import React from 'react';
import styled from '@emotion/styled';
import dateFormat, { masks } from "dateformat";
import imageTestCamp from '../../test/images/testCampImg.jpeg';
import iconEdit from '../../test/icons/Group 158.png';
import iconTrash from '../../test/icons/trash-2.png';
import { Link } from 'react-router-dom';
const CardImg = styled.img`
        width: 130px;
        height: 100px;
        padding: 5px 5px 5px 5px;
        float: left;
        border-radius: 10px;
      
    `

const NombreCamp = styled.p`
        flex-grow: 0;
        margin: 15px 17.1px 14px 27px;
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
    `;
    const FechaCamp = styled.p`
        flex-grow: 0;
        margin: 0px 0 26px 27px;
        transform: rotate(-360deg);
        font-family: Roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
`;
    const Div1 = styled.div`
        padding: 10px 0px 0px 130px;
    `;

    const CardDiv = styled.div`
        // width: 20%;
        display: block;
        float: left;
        // justify-content: center;
        // margin: 10px;

        width: 98%;
        height: 89px;
        
        padding: 0 0 22px;
        box-shadow: 0 2px 2px 0
            rgba(0, 0, 0, 0.25);
        background-color: #fffcfe;

        // background-color: white;
        color: #272727;
        // padding: 20px;
        border-radius: 10px;
        border: 3px solid transparent;
        border-style: solid;
        transition: 200ms;
    `;
const Div12 = styled.div`
width: 100%;
float: left;
`;

const Div12_Center = styled.div` 
float: left;
margin: -100px 0px 0px 400px ;

`;

const Div13 = styled.div`
width: 100%;
float: left;
margin: 0px px 0px 0px ;

`;

const Div6 = styled.div`
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 3px;
        margin: 15px 17.1px 14px 27px;
        padding: 0;
    `;

    const Div6_Center = styled.div`
        width: 49%;
        float: left;
        display: flex;
        justify-content: center;
    `;

    const NombreItem = styled.b`
        width: 90px;
        height: 10px;
        flex-grow: 0;
        
        font-family: Roboto;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
    `

    const HorizontalLine = styled.div`
        width: 1px;
        height: 50px;
        flex-grow: 0;
        margin: 19px 27px 10px 0;  
        background-color: #d4d4d4;
    `;

    const ModalEdit = styled.div`
        width: 615px;
        height: 962px;
        padding: 35px 0 150px 39px;
        border-radius: 11px;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
        background-color: #fff;
    `;

    const Span_Nombre = styled.span`
        width: 200.9px;
        height: 19px;
        flex-grow: 0;
        margin: 0 369.1px 3px 4px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #2d3748;
    `

        const Div_Finished = styled.div`
        flex-grow: 0;
        padding: 0px 0px 0px 100px;
        margin: 15px 50px 14px 60px;
        font-family: Roboto;
        font-size: 14px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.2);
        font-weight: bold;
        `


const ListCard = ({index,item,getCurrentCamp,handleOpen,changeRoute}) => {
    return (  
    <CardDiv className="card" 
    key={`camp_list_${index}`} >
        <div 
            className="containerImg"
            style={{cursor: 'pointer'}}
            onClick={(e) => {
                e.preventDefault();
                //getSingleCamp(item.id);
                changeRoute(item.id);
            }}


            
        >
           <Link to="{{`/campaign/${item.id}`}}"> <CardImg className="card-image" src={imageTestCamp} alt="Logo" /></Link>
           
        </div>
        
         <Div1><Link to="{{`/campaign/${item.id}`}}">
                <NombreCamp>{item.nombre}</NombreCamp>
                <FechaCamp>
                    {dateFormat(item.fechaInicio, "dd/mm/yyyy")} -  {""}
                    {dateFormat(item.fechaFin, "dd/mm/yyyy")}
              </FechaCamp>    </Link>
        </Div1>
         
        <Div12_Center className="card-items-bar">
        
        <HorizontalLine/>
        <Link to="{{`/campaign/${item.id}`}}">
            <Div6>
                <div className="item-text">
                    <b>Total Visibility</b><br/>
                    <Div12>
                        <span className="numberData">{item.posts}</span>
                    </Div12>
                </div>
            </Div6>
            <Div6>
                <div className="item-text">
                    <b>Total Interactions</b><br/>
                    <Div12>
                        <span className="numberData">{item.likes + item.comments}</span>
                    </Div12>
                </div>
            </Div6>
      
            <Div6>
                <div className="item-text">
                    <br/><b>EMV</b><br/>
                    <Div12>
                        <span className="numberData">{item.posts}</span>
                    </Div12>
                </div>
            </Div6>
            <Div6>
                <div className="item-text">
                    <b>Engagement Ratio</b><br/>
                    <Div12>
                        <span className="numberData">{item.posts > 0 ? ((item.likes + item.comments)/item.posts).toFixed(2) : 0}%</span>
                    </Div12>
                </div>
            </Div6>
      
            <Div6>
                <div className="item-text">
                    <b>Total Contributors</b><br/>
                    <Div12>
                        <span className="numberData">{item.comments}</span>
                    </Div12>
                </div>
            </Div6>
            <Div6>
                <div className="item-text">
                    <b>Total Posts</b><br/>
                    <Div12>
                        <span className="numberData">{item.posts}</span>
                    </Div12>
                </div>
            </Div6>
            </Link>
        
        <Div13 className="card-items-bar">
            <Div_Finished className="item-text ml-20 ">
                { new Date(item.fechaFin).getTime() > new Date().getTime()?
                    <span>Activa</span>
                    :
                    <span>Finished</span>
                }
                
            </Div_Finished>
            <div className="item-text">
                <a
                    href="#"
                    className="pr-20 btnCard"
                    onClick={(e) => {
                        e.preventDefault();
                        getCurrentCamp(item);
                        handleOpen();
                    }}
                >
                    <img src={iconEdit} />
                </a>
                <a href="#" className="btnCard"><img src={iconTrash}/></a>
            </div>

        </Div13>
        </Div12_Center>
    </CardDiv> );
}
 
export default ListCard;