import React, { Fragment } from 'react'
import styled from '@emotion/styled'

import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
const Divdown = styled.div`
    width: 70%;
    margin: 20px 0px 10px 15px;
    padding: 30px 0px 30px 60px;
    border-radius: 10px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.07);
    background-color: #fff;
    float: left;
    postion:absolute;

    @media (max-width:500px){
     margin: 1200px 0px 10px 15px;
     width: 80%;
      }  
    

    & > span {
      width: 70%;
      height: 26px;
      font-family: Roboto;
      font-size: 23px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #2d3748;
      font-weight: bold;

      @media (max-width:500px){
        padding: 0px 0px 0px 60px;
         }  
     
    } 

`
const Buttonplan = styled.button`
    width: 97px;
    height: 32px;
    padding: 6px 12px 7px 13px;
    border-radius: 5px;
    background-color: #e42c64;
    
    border:none;

    & > span {
      width: 72px;
      height: 18px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fffcfe;
    }
`

const Divbutton = styled.div`
    float: right;
    margin: 0px 50px 0px 0px;
    padding: 0px 0px 0px 0px;

    @media (max-width:500px){
      padding: 20px 52px 0px 0px;
       }  
     
`

const ChangePlan = styled.span`
    padding: 0px 30px 10px 20px;
`

const Barrainferior = () => {
  const client = useSelector(state=> state.client);
  return (
    <Divdown>
    <span>Tipo de subscripción</span>
    <Divbutton>
    <Buttonplan>
      <span>{
          client.decode.plan === "BASIC INFLUENCER" ? 
          <Fragment>Plan Basic</Fragment>
          :
          <Fragment>Plan FREE</Fragment>
        }</span>
    </Buttonplan>
      <Link to="/planes"><ChangePlan>Cambiar plan</ChangePlan></Link>
    </Divbutton>
  </Divdown>
  )
}

export default Barrainferior