import React, { Fragment,useState } from 'react'
import { reduxForm, Field } from 'redux-form'
import { Link, Navigate } from 'react-router-dom'
import { connect } from 'react-redux'
import {useDispatch, useSelector} from 'react-redux';
import Messages from '../notifications/Messages'
import Errors from '../notifications/Errors'
import RegistroForm from '../componentes/formularios/registroForm';
import MenuSuperior from "../componentes/menuSuperior";
import DatosCuentaForm from '../componentes/formularios/datosCuentaForm';

const DatosCuenta = () => {
  const client = useSelector((state) => state.client);
 
    return (
      <Fragment>
        {
                //successful ? (<Navigate to={{ pathname: '/widgets', state: ''}} />) : null
        }
        <MenuSuperior
          showDerecha= "false"
          />

      <div className="col-md-12">
          <div className="fondo-h1">
              <div className="pull-left">
                  <h1>{client.decode.roles.includes('ROLE_INFLUENCER') ? 'Registro de Influencer' : 'Registro de Empresa'}</h1>
              </div>
          </div>
          <DatosCuentaForm />
      </div>
      </Fragment>
    )
  }

export default DatosCuenta
