import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import ReactModal from 'react-modal';
import { Form, Field, option } from 'react-final-form';
import { useParams } from 'react-router-dom';
import {addInfluencer, deleteInfluencer} from '../../campaign/actions';

const ModalTitle = styled.h2`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  padding-bottom:10px;
  border-bottom: 3px solid  #aeaeae;
  
  `


const ModalInfluencers = ({isOpen, setIsOpen}) => {
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const { idCamp} = useParams();
    const listadoInfluencers = useSelector((state) => state.campaign.campaign.influencers);
  

    const onSubmit = (values) =>{
        dispatch(addInfluencer(values.username,idCamp, client));
      };
      const validate = () =>{
  
      };
    return (
        <ReactModal

        isOpen={isOpen}
        
        >
            <div>
          <div className="rowModal" style={{textAlign:'right'}}><img src="/assets/images/close-icon.png" onClick={(e)=>{e.preventDefault();setIsOpen(false);}}/></div>
          <ModalTitle>Gestión de Influencers</ModalTitle>
        <div className="contenidoModal">
            <div className="rowModal">
            <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="form-datos-cuenta">
                    
                    <Field
                            name="username"
                            type="text"
                            id="username"
                            className="input_text_1 w100 inputInfluencerModal"
                            component="input"
                            placeholder="@username"
                        />

          
                        <button action="submit" ><img src="/assets/images/add_influencer.png"/></button>
          
                    </form>
        
        )}
      />
            </div>
            <div className="rowModal">
                <table>

                
                {listadoInfluencers !== undefined ?
                    listadoInfluencers.map((influencer,i)=>(
                        <tr key={`tr_${i}`}>
                            <td><input type="checkbox"/></td>
                            <td><span key={`infList_${i}`}>{influencer.name}</span></td>
                            <td>IG</td>
                            <td>FB</td>
                            <td>Tw</td>
                            <td>Yt</td>
                            <td>
                                <button onClick={(e)=>{
                                    e.preventDefault();
                                    dispatch(deleteInfluencer(influencer.id, idCamp, client));
                            }}>Eliminar</button></td>

                        </tr>
                    ))
                    : null
                }
                </table>
            </div>
        </div>

          </div>
        </ReactModal>
      );
}
 
export default ModalInfluencers;