import styled from "styled-components";
export const ModalTitle = styled.h2`
flex-grow: 0;
margin: 0 461px 38px 0;
font-family: Roboto;
font-size: 23px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2d3748;
@media (max-width:500px){
  margin: 0px 0px 10px 100px;
  }  

`

export const DivResponisve = styled.div`
width:100%;

@media (max-width:500px){
display:flex;
flex-direction: column;
position:absolute;
}   

`
export const PlanCol1= styled.div`
width: 60%;
display:inline-block ;
vertical-align: top;
@media (max-width:500px){
  position: flex;
  }  
 
`
export const PlanCol2= styled.div`
width: 40%;
display:inline-block;
vertical-align: top;
position: relative;
@media (max-width:500px){
  margin: 0px 0px 5px -10px;
  }  
`
export const Text1=styled.h2`
flex-grow: 0;
margin: 0px 0 6px 30.3px;
font-family: Roboto;
font-size: 34px;
font-weight: 900;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2c5282;
`
export const Text2=styled.p`
flex-grow: 0;
margin: 6px 60px 5.5px 30.3px;
font-family: Roboto;
font-size: 26px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: 1.38;
letter-spacing: normal;
text-align: left;
color: #464646;
`
export const ImgPlan=styled.img`
@media (max-width:500px){
  display: none;
  }  
`
export const Text3=styled.p`
width: 85%;
flex-grow: 0;
margin: 32.5px 72px 114px 49px;
font-family: Roboto;
font-size: 21px;
font-weight: 600;
font-stretch: normal;
font-style: normal;
line-height: 1.52;
letter-spacing: normal;
text-align: left;
color: #6a6a6a;
font-weight:normal;
  @media (max-width:500px){
    margin: 10px 0px 10px 10px;
    width: 150%;
  }  
`
export const Card1=styled.div`
width: 326px;
height: 562px;
flex-grow: 0;
margin: 0px 110px 0 0;
padding: 20px 5px 24px 25px;
border-radius: 26px;
box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
background-color: #3a6eb0;
position: relative;
z-index:2;
& li{
      margin: 0 0 3px 11.2px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
  }
  & ul {
      list-style: none;
      padding-left: 0px;
  }

  & ul li img {
      vertical-align: middle;
  }
  & ul li{
      padding-bottom: 10px;
  }
 
`
export const DivRecomendado = styled.div`
margin: 0 147px 26px 0;
padding: 7px 16px 6px 15px;
border-radius: 13.5px;
background-color: #2c5282;
text-align: center;
& span{
  font-family: Avenir;
font-size: 13px;
font-weight: 900;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.83px;
text-align: center;
color: #c7d8ed;
text-transform: uppercase;
}
`
export const Text4=styled.p`
flex-grow: 0;
margin: 26px 193px 10px 5px;
font-family: Roboto;
font-size: 28px;
font-weight: 800;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #fff;
`
export const Text5=styled.p`
margin: 0 10px 0 0;
font-family: Roboto;
font-size: 36px;
font-weight: 800;
font-stretch: normal;
font-style: normal;
line-height: 1.28;
letter-spacing: normal;
text-align: left;
color: #fff;
& span{
  margin: 19px 0 7px 10px;
  font-family: Roboto;
  font-size: 17px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
}
`
export const Text6=styled.p`
margin: 2px 0 0;
font-family: Roboto;
font-size: 15px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #fff;
`
export const BotonContratar=styled.button`
margin: 28px 14px 15px 22px;
padding: 13px 46px 14px;
border-radius: 24px;
border: solid 0 #9ae6b4;
background-color: #fff;
font-family: Roboto;
font-size: 15px;
font-weight: 900;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #2c5282;
`
export const VerTodosPlanes=styled.a`
flex-grow: 0;
margin: 15px 59px 0 66px;
font-family: Avenir;
font-size: 15px;
font-weight: 900;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: center;
color: #fff;
text-decoration:none;
`
export const PlanCol1_1=styled.div`
width: 30%;
display: inline-block;
vertical-align: top;
`
export const PlanCol1_2=styled.div`
width: 70%;
display: inline-block;
vertical-align: top;
  @media (max-width:500px){
      margin: 10px 0px 0px -20px;
      width: 180%;
    }  
`
export const TipoPlan=styled.span`
flex-grow: 0;
transform: rotate(-270deg);
font-family: Avenir;
font-size: 10px;
font-weight: 900;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: 0.83px;
text-align: center;
color: #c7d8ed;
text-transform: uppercase ;
display: inline-block ;
position: absolute;
right:0px;
top:40px;
`

export const Card2 = styled.div`
width: 326px;
height: 562px;
flex-grow: 0;
margin: 0px 110px 0 0;
padding: 20px 5px 24px 25px;
border-radius: 26px;
box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
background-color: #2c5282;
top: 0px;
  left: 40px;
position: absolute;
z-index:1;
& li{
      margin: 0 0 3px 11.2px;
      font-family: Roboto;
      font-size: 15px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: #fff;
  }
  & ul {
      list-style: none;
      padding-left: 0px;
  }

  & ul li img {
      vertical-align: middle;
  }
  & ul li{
      padding-bottom: 10px;
  }

`