import React, { useState, useEffect, Fragment } from 'react';
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import {useDispatch, useSelector} from 'react-redux';
/* *********** STYLED COMPONENTS *********** */
const Card1=styled.div`
width: 326px;
  height: 562px;
  flex-grow: 0;
  margin: 0px 5px;
  padding: 20px 5px 24px 25px;
  border-radius: 26px;
  box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
  background-color: #AEAEAE;
  position: relative;
  z-index:2;
  display: inline-block;
  & li{
        margin: 0 0 3px 11.2px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & ul {
        list-style: none;
        padding-left: 0px;
    }

    & ul li img {
        vertical-align: middle;
    }
    & ul li{
        padding-bottom: 10px;
    }
      @media (max-width: 500px) {
        margin-bottom: 20px;
      }
`

const DivRecomendado = styled.div`
  margin: 0 147px 26px 0;
  padding: 7px 16px 6px 15px;
  border-radius: 13.5px;
  background-color: #2c5282;
  text-align: center;
  & span{
    font-family: Avenir;
  font-size: 13px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: #c7d8ed;
  text-transform: uppercase;
  }
`

const TipoPlan=styled.span`
  flex-grow: 0;
  transform: rotate(-270deg);
  font-family: Avenir;
  font-size: 10px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.83px;
  text-align: center;
  color: #c7d8ed;
  text-transform: uppercase ;
  display: inline-block ;
  position: absolute;
  right:0px;
  top:40px;
`

const Text4=styled.p`
  flex-grow: 0;
  margin: 26px 193px 10px 5px;
  font-family: Roboto;
  font-size: 28px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const Text5=styled.p`
  margin: 0 10px 0 0;
  font-family: Roboto;
  font-size: 36px;
  font-weight: 800;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
  & span{
    margin: 19px 0 7px 10px;
    font-family: Roboto;
    font-size: 17px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
`
const Text6=styled.p`
  margin: 2px 0 0;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #fff;
`
const BotonContratar=styled.button`
  margin: 28px 14px 15px 22px;
  padding: 13px 46px 14px;
  border-radius: 24px;
  border: solid 0 #9ae6b4;
  background-color: #fff;
  font-family: Roboto;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #2c5282;
  cursor: pointer;
`
const VerTodosPlanes=styled.a`
  flex-grow: 0;
  margin: 15px 59px 0 66px;
  font-family: Avenir;
  font-size: 15px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  text-decoration:none;
  cursor: pointer;
`
/* *********** END STYLED COMPONENTS *********** */

const FreeInfluencerPlan = ({showBtnPlanes}) => {

  const client = useSelector(state=> state.client);
  const navigate = useNavigate();
    
  // Ir a la Comprar Plan Basic
  const changeRoute = (plan, periodicidad) => {
    navigate("/planes/" + plan+"/"+periodicidad);
  }

    return (
        <Fragment>
            <Card1 className="Card1">
                {/* <DivRecomendado className="DivRecomendado"><span>recomendado</span></DivRecomendado> */}
                <TipoPlan className="TipoPlan">Mensual</TipoPlan>
                <Text4 className="Text4">Free</Text4>
                <Text5 className="Text5">0€ <span>/mes</span></Text5>
                <Text6 className="Text6">Perfecto para comenzar en la gestión de tus publicaciones.</Text6>
                <ul>
                    <li><img src="/assets/images/check-circle-1.png"/> Limitado a <strong>5</strong> posts</li>
                    <li><img src="/assets/images/check-circle-1.png"/> Informe EMV limitado</li>
                </ul>
                <BotonContratar 
                  className="BotonContratar"
                  onClick={(e) => {
                    let plan = "free_influencer";
                    changeRoute(plan,"mensual");
                  }}
                >
               { (client.decode.plan.includes("FREE")) ?
                      <Fragment>Mantener Plan Free</Fragment>
                :
                <Fragment>Cambiar a Plan Free</Fragment>
                }
                  </BotonContratar>
                  {showBtnPlanes === true ? 
                    <VerTodosPlanes 
                      className="VerTodosPlanes"
                      onClick={(e) => {
                        navigate("/planes");
                      }}
                    >
                      <img className="verticalAlign arrowLeftIcon" src="/assets/images/arrow_left_icon.png"></img>
                      <span className="verticalAlign">Ver todos los planes</span>
                    </VerTodosPlanes>
                    :null}
            </Card1>
        </Fragment>
    );
}

export default FreeInfluencerPlan;