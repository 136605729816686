import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import ReactModal from 'react-modal';
import { Form, Field, option } from 'react-final-form';
import { useParams } from 'react-router-dom';
import ContenedorGoogle from "../conectar_cuentas/contenedorGoogle";

const ModalTitle = styled.h2`
  font-family: Roboto;
  font-size: 23px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #2d3748;
  
  `
const SubmodalTitle = styled.h4`
font-family: Roboto;
font-size: 14px;
font-weight: 500;
font-stretch: normal;
font-style: normal;
line-height: normal;
letter-spacing: normal;
text-align: left;
color: #2d3748;
padding-bottom:10px;
border-bottom: 3px solid  #aeaeae;

`

const ModalCuentas = ({isOpen, setIsOpen}) => {

  /* Esto está pendiente porque tengo que ver cómo se estructuraban las cuentas */
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const { idCamp} = useParams();
    const listadoCuentas = useSelector((state) => state.client.decode.cuentas_user);
    const listadoCuentasCampaing = useSelector((state) => state.campaign.campaign.cuentas);
    const [cuentasActivas, setCuentasActivas] = useState({});

    
    useEffect(()=>{
      let listadoCuentasAux=listadoCuentas;
      if (typeof listadoCuentas ==="string"){
        listadoCuentasAux= JSON.parse(listadoCuentas);
      }
      let auxCuentas= {};
      try{
        if (listadoCuentasAux!== undefined) {
          if (listadoCuentasAux.hasOwnProperty("google")){
            listadoCuentasAux.google.map(cuenta =>{
              //Miro si en la campaña está activa o no para decidir  
              if (listadoCuentasCampaing !== undefined){
                var found = listadoCuentasCampaing.filter(function(item) { 
                  return item['@id'] === `/api/cuentas/${cuenta.id}`; 
                });
              }
              auxCuentas[cuenta.id]= 1;
            })
          }
        
          if (listadoCuentasAux.hasOwnProperty("fb")){
            listadoCuentasAux.fb.map(cuenta =>{
              //Miro si en la campaña está activa o no para decidir  
              if (listadoCuentasCampaing !== undefined){
                var found = listadoCuentasCampaing.filter(function(item) { 
                  return item['@id'] === `/api/cuentas/${cuenta.id}`; 
                });
              }
              auxCuentas[cuenta.id]= 1;
            })
          }
          if (listadoCuentasAux.hasOwnProperty("instagram")){
            listadoCuentasAux.instagram.map(cuenta =>{
              //Miro si en la campaña está activa o no para decidir  
              if (listadoCuentasCampaing !== undefined){
                var found = listadoCuentasCampaing.filter(function(item) { 
                  return item['@id'] === `/api/cuentas/${cuenta.id}`; 
                });
              }
              auxCuentas[cuenta.id]= 1;
            })
          }


          setCuentasActivas(auxCuentas);
        }
      }catch(e){
      console.log(e);
    }
    },[,listadoCuentas])

    const onSubmit = (values) =>{
        
      };
      const validate = () =>{
  
      };
    return (
        <ReactModal

        isOpen={isOpen}
        
        >
            <div>
          <div className="rowModal" style={{textAlign:'right'}}><img src="/assets/images/close-icon.png" onClick={(e)=>{e.preventDefault();setIsOpen(false);}}/></div>
          <ModalTitle>Añadir cuenta de RRSS vinculada</ModalTitle>
          <SubmodalTitle>Las cuentas se utilizan para poder conectar y obtener la información de la misma.</SubmodalTitle>
        <div className="contenidoModal">
            <div className="rowModal">
            <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} id="form-datos-cuenta">
                    
                    {
                    listadoCuentas !== undefined ?
                    JSON.parse(listadoCuentas).map((cuenta,i)=>(

                      
                       <div  key={`cuentaList_${i}`}>
                         <Field
                            name={cuenta.nombre}
                            type="checkbox"
                            id={cuenta.nombre}
                            className=""
                            component="input"
                            />
                          <span>{cuenta.nombre}</span></div>
                    ))
                    : null
                }
                   <button>Submit</button>
             </form>
        
        )}
      />
            </div>
            
        </div>

          </div>
        </ReactModal>
      );
}
 
export default ModalCuentas;