import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";

const MyDropzone = ({ input, ...res }) => {

    const [files, setFiles] = useState([]);
    const onDrop = useCallback(acceptedFiles => {
        input.onChange(acceptedFiles);
        setFiles(acceptedFiles.map(file => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    }, []);

  const removeFile = (name) => {
        // find the index of the item
         // remove the item from array
        const validFileIndex = files.findIndex(e => e.name === name);
        files.splice(validFileIndex, 1);
        // update validFiles array
        setFiles([...files]);
        input.onChange({});
        const acceptedFilesIndex = acceptedFiles.findIndex(e => e.name === name);
        acceptedFiles.splice( acceptedFilesIndex, 1)
    }

   const thumb={}
   const thumbInner={}
   const img={}
   const thumbsContainer={}

  const thumbs = files.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
      <div className="file-remove" onClick={() => removeFile(file.name)}>X</div>
        <img
          src={file.preview}
          style={img}
        />
      </div>
    </div>
  ));

  
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone({ onDrop, accept: 'image/*' });
  const {currentFile} = res;
  
  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  }, [files]);

  
  
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      { acceptedFiles.length === 0 ? 
                isDragActive ? (
                    <img src="/assets/images/upload_img.png" />
                ) : (
                    <img src="/assets/images/upload_img.png" />
                )
                
                :
      <aside style={thumbsContainer}>
        {thumbs}
      </aside>
                }
    </div>
  );
};

export default MyDropzone;
