import React, {Fragment, useEffect, useState} from "react";
import MenuSuperior from "../componentes/menuSuperior";
import {useDispatch, useSelector} from 'react-redux';
import dateFormat, { masks } from "dateformat";
import styled from 'styled-components';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import { useNavigate } from "react-router-dom";
import {getParameterByName} from "../lib/helpers";
import './checkbox.css';
import { style } from "@mui/system";

import PagoStripe from '../componentes/stripe/pagoStripe';

import BasicPlan from "../componentes/planes_cards/BasicPlan";
import FreePlan from "../componentes/planes_cards/FreePlan";
import PlusPlan from "../componentes/planes_cards/PlusPlan";
import AgencyPlan from "../componentes/planes_cards/AgencyPlan";

import FreeInfluencerPlan from "../componentes/planes_cards/FreeInfluencerPlan";
import BasicInfluencerPlan from "../componentes/planes_cards/BasicInfluencerPlan";


const Col_12 = styled.div`
    width: 100%;
    padding-left: 100px;
    margin-bottom: 20px;
        @media (max-width:500px){
            margin: 0px 0px 0px -30px;
        }
`;

const Col_12_Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
`;

const DivPlan = styled.div`
  width: 326px;
  height: 561px;
  margin: 66px 10px 0 12px;
  padding: 45px 0 58.9px 30px;
  border-radius: 26px;
  box-shadow: 0 42px 34px 0 rgba(44, 82, 130, 0.3);
  background-color: #aeaeae;
  display: inline-block;

  & :fist-child{
      margin-left:0px;
  }

  & > h4{
    margin: 0 44px 2.3px 1px;
    font-family: Roboto;
    font-size: 28px;
    font-weight: 800;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #fff;
  }
  & > h5{
        flex-grow: 0;
        font-family: Roboto;
        font-size: 36px;
        font-weight: 800;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.28;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
& > h5 > span{
        flex-grow: 0;
        font-family: Roboto;
        font-size: 17px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & p{
        margin: 4.3px 0 0;
            font-family: Roboto;
            font-size: 15px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #fff;
    }
    & li{
        margin: 0 0 3px 11.2px;
        font-family: Roboto;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;
    }
    & ul {
        list-style: none;
        padding-left: 0px;
    }

    & ul li img {
        vertical-align: middle;
    }
    & ul li{
        padding-bottom: 10px;
    }
`;

const ButtonPlan= styled.button`
    margin: 32.6px 47px 0 17px;
    padding: 12px 50px 14.9px;
    border-radius: 24px;
    border: solid 0 #fff;
    background-color: #fff;
    font-family: Roboto;
    font-size: 15px;
    font-weight: 900;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #2c5282;
`;

const Planes = () => {
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const navigate = useNavigate();
    const showBtnPlanes = false;

    /* ****** STATES ****** */
    const [isCheckedAnual, setIsCheckedAnual] = useState(false);
    /* ****** END STATES ****** */


    /* ******* MODAL EDIT ****** */
    /* ******* END MODAL EDIT ****** */



    /* ****** USE-EFFECTS ****** */
    /* ****** END USE-EFFECTS ****** */



    /* ****** FUNCTIONS ****** */
    /* ****** END FUNCTIONS ****** */



    /* ****** Elementos styled ****** */
    /* ****** END Elementos styled ****** */


    return (
        <Fragment>
            <MenuSuperior/>
            <div className="fondo-h1">
                <div className="pull-left">
                    <h1>Gestión de planes</h1>
                </div>
            </div>
            

            <div className="container">
                    <Col_12_Center className="col_12">
                        <div>
                            <span className="verticalAlign margin5"><strong>Plan mensual</strong></span>
                            <label className="switch verticalAlign margin5">
                                <input type="checkbox" value={isCheckedAnual} onChange={(e)=>{setIsCheckedAnual(e.target.checked)}} />
                                <div className="slider"></div>
                            </label>
                            <span className="verticalAlign margin5"><strong>Plan Anual</strong> (Ahorra hasta 2 meses)</span></div>
                    </Col_12_Center>
                    <Col_12 className="col_12">
                        {client.decode.roles.includes('ROLE_INFLUENCER') ?
                                    <Fragment>
                                        <FreeInfluencerPlan />
                                        <BasicInfluencerPlan 
                                        showRenderCupon="false"
                                        />
                                    </Fragment>  
                            :
                        <Fragment>
                        <FreePlan/>
                        <BasicPlan
                            showBtnPlanes={showBtnPlanes}
                        />
                        <PlusPlan/>
                        <AgencyPlan/>
                        </Fragment>
                    }
                    </Col_12>
            </div>
        </Fragment>
    );
}

export default Planes;