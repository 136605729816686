import { CLIENT_SET, CLIENT_UNSET, CLIENT_SUPERADO_PLAN, CLIENT_SUPERADO_PLAN_CANCEL,CLIENT_UPDATE_CUENTAS } from './constants'
import jwt from 'jwt-decode';
const initialSate = {
  id: null,
  token: null,
  gestion_plan:{
    superado: false,
    error: null
  }
}

const reducer = function clientReducer (state = initialSate, action) {
  switch (action.type) {
    case CLIENT_UPDATE_CUENTAS:
      let decodeAux=state.decode;
      decodeAux.cuentas_user=action.cuentas;
      return{
        ...state,
        decode: decodeAux
      }
    case CLIENT_SUPERADO_PLAN:
      return{
        ...state,
        gestion_plan:{
          superado: true,
          error: action.payload.mensaje
        }
      }
    case CLIENT_SUPERADO_PLAN_CANCEL:
      return{
        ...state,
        gestion_plan:{
          superado: false,
          error: null
        }
      }
    case CLIENT_SET:
      return {
        id: action.token.userId,
        token: action.token,
        decode: jwt(action.token.token),
        gestion_plan:{
          superado: false,
          error: null
        }
      }

    case CLIENT_UNSET:
      return {
        id: null,
        token: null,
        gestion_plan:{
          superado: false,
          error: null
        }
      }

    default:
      return state
  }
}

export default reducer
