import React, { Fragment, useEffect, useState } from "react";
import styled from 'styled-components';
import {useDispatch, useSelector} from 'react-redux';
import { Form, Field, option, useField } from 'react-final-form';
import { FamilyRestroom } from "@mui/icons-material";
import {aplicarFiltros} from '../campaign/actions';
import { useParams } from 'react-router-dom';

 /* ****** Elementos styled ****** */
 const Div12 = styled.div`
 width: 100%;
 display: block;
`;

const Div12_Center = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;
`;

const Div1 = styled.div`
 width: 10%;
 float: left;
`;

const Div10 = styled.div`
 width: 80%;
 float: left;

`;

const Div_menu_aside = styled.div`
 position: absolute;
 width: 305px;
 height: 779px;
 left: 0px;
 top: 107px;
 overflow-y: scroll;
 display: block;
 float: left;

 @media (max-width:500px){
  z-index: 3;
  position: fixed;
}
`;

const Bar_menu_aside = styled.div`
 position: absolute;
 width: 295px;
 height: 1116px;
 left: 0px;
 top: 0px;
`;

const Bar_2_menu_aside = styled.div`
 position: absolute;
 width: 304px;
 height: 1150px;
 height: 100%;
 left: -9px;
 top: 0px;
 background: #E8F1F8;
 padding-bottom:200px;
`;

const Titulo_Seccion_Menu = styled.div`
 width: 244px;
 height: 35px;
 left: 27px;
 top: 29px;
 display: flex;
 justify-content: center;
 background: #2D3748;
 border-radius: 10px;
`;

const Text_Titulo_Seccion = styled.span`
 
 font-style: normal;
 font-weight: bold;
 font-size: 16px;
 line-height: 19px;
 padding-top: 7px;
 color: #FFFFFF;
`;

const Text_Titulo_Content = styled.span`
 left: 15.59%;
 right: 53.22%;
 top: 7.97%;
 bottom: 90.14%;
 font-style: normal;
 font-weight: bold;
 font-size: 18px;
 line-height: 21px;
 color: #2D3748;
 padding-left: 10px;
`;

const Add_button = styled.a`
 height: 15px;
 left: 139px;
 top: 158px;
 font-style: normal;
 font-weight: bold;
 font-size: 13px;
 line-height: 15px;
 color: #2D3748;
 float: right;
 padding-right: 10px;
 text-decoration: none;
 padding-bottom: 10px;
 padding-top: 10px;
`;

const Checkbox = styled.input`

`;


/* ***************** LISTADO INFLUENCERS ***************** */
const Content_Listado_Influencers = styled.div`
 width: 242px;
 left: 29px;
 top: 77px;
 background: #FFFFFF;
 border-radius: 10px;
`;

const Users_listado_influencers = styled.p`
 width: 249px;
 left: 43px;
 top: 115px;
 font-style: normal;
 font-weight: normal;
 font-size: 14px;
 line-height: 16px;
`;
/* ***************** END LISTADO INFLUENCERS ***************** */


/* ***************** FILTROS BÁSICOS ***************** */
const Content_RRSS_Filtros_Basicos = styled.div`
 width: 36px;
 left: 12.88%;
 right: 73.22%;
 top: 22.58%;
 bottom: 73.75%;
 background: #FFFFFF;
 border-radius: 3px;
 margin: 5px;
`;

const Logo_RRSS = styled.img`
 width: 15px;
 padding-top: 5px;
`;


const Content_Filtros_Basicos = styled.div`
 width: 242px;
 left: 29px;
 top: 77px;
 background: #FFFFFF;
 border-radius: 10px;
`;
/* ***************** END FILTROS BÁSICOS ***************** */


/* ***************** FILTROS AVANZADOS ***************** */
const ButtonMenu = styled.button`
 width: 98px;
 height: 32px;
 left: 41px;
 top: 719px;
 background: #FFFFFF;
 border: 1px solid #2C5282;
 box-sizing: border-box;
 border-radius: 25px;
 margin: 5px;
 font-style: normal;
 font-weight: bold;
 font-size: 13px;
 line-height: 18px;
`;

const InputFiltroMin = styled.input`
 width: 98px;
 height: 32px;
 left: 41px;
 top: 719px;
 background: #FFFFFF;
 border: 1px solid #2C5282;
 box-sizing: border-box;
 border-radius: 25px;
 margin: 5px;
 font-style: normal;
 font-weight: bold;
 font-size: 13px;
 line-height: 18px;
`;

const InputDate = styled.input`
 width: 190px;
 height: 32px;
 left: 41px;
 top: 719px;
 border: 1px solid #2C5282;
 box-sizing: border-box;
 border-radius: 25px;
 margin: 5px;
 font-style: normal;
 font-weight: bold;
 font-size: 13px;
 line-height: 18px;
 padding: 0 20px;
`;

const Label = styled.label`
 font-style: normal;
 font-weight: bold;
 font-size: 13px;
 line-height: 18px;
`;
/* ***************** END FILTROS AVANZADOS ***************** */

/* ****** END Elementos styled ****** */


const FiltrosMenuAside = ({setIsOpenModalInfluencers,setIsOpenModalCuentas,setIsOpenModalHashtag}) => {
  const [isCheckedMenciones,setIsCheckedMenciones] = useState({});
  const [isCheckedHashtags,setIsCheckedHashtags] = useState({});
  const [filtroRedesKey, setFiltroRedesKey]= useState(0);
  const datosSingleCampaign = useSelector((state) => state.campaign.campaign);
  const [isCheckedRed,setIsCheckedRed] = useState({instagram: true, facebook: true, youtube: true, twitter: true});
  const filtro = useSelector((state) => state.campaign.filtro);
  const client = useSelector((state) => state.client);
  const dispatch = useDispatch();
  const { idCamp} = useParams();
  
  useEffect(()=>{
      if (filtro.hasOwnProperty("redes")){
        setIsCheckedRed(filtro.redes);
      }
      if (filtro.hasOwnProperty("menciones")){
        setIsCheckedMenciones(filtro.menciones);
      }else{
        if (datosSingleCampaign.hasOwnProperty('cuentas')){
          datosSingleCampaign.cuentas.map(cuentas => {
            let aux1= isCheckedMenciones;
            aux1[cuentas.nombre]=true;
            setIsCheckedMenciones(aux1);     
            setFiltroRedesKey(Math.random());   
          });
        }
      }
      if (filtro.hasOwnProperty("hashtags")){
        setIsCheckedHashtags(filtro.hashtags);
      }

      setTimeout(function(){
        setFiltroRedesKey(Math.random());
      },100);

  },[])


  
  useEffect(()=>{
    if (Object.keys(filtro).length ===0 ){
        if (datosSingleCampaign.hasOwnProperty('cuentas')){
          datosSingleCampaign.cuentas.map(cuenta => {
            let aux1= isCheckedMenciones;
            aux1[cuenta.nombre]=true;
            setIsCheckedMenciones(aux1);     
            setFiltroRedesKey(Math.random());   
          });
        }
        if (datosSingleCampaign.hasOwnProperty('hashtagsCampaign')){
          datosSingleCampaign.hashtagsCampaign.map(hashtag => {
          let aux1= isCheckedHashtags;
          aux1[hashtag.hashtag]=true;
          setIsCheckedHashtags(aux1);      
          setFiltroRedesKey(Math.random());  
        });
      }
  }    
    
  },[datosSingleCampaign])

  const onSubmit = (values) =>{
/*
redes[instagram]: on
redes[facebook]: on
redes[twitter]: on
redes[youtube]: on
menciones[Porsche Ibérica]: on
hashtags[ExperienciaFormulaE]: on
minFollowers: 
maxFollowers: 
minInteracciones: 
maxInteracciones: 
desdeFechaPublicacion: 
hastaFechaPublicacion: 
filtrar: filtrar

  */
    //values= {...values, 'redes[instagram]':isCheckedRed.instagram, 'redes[facebook]':isCheckedRed.facebook, 'redes[twitter]':isCheckedRed.twitter, 'redes[youtube]': isCheckedRed.youtube}
    values['filtrar']='filtrar';

    values['redes']={
      'instagram': isCheckedRed.instagram, 
      'facebook':isCheckedRed.facebook,
      'twitter':isCheckedRed.twitter, 'youtube': isCheckedRed.youtube
    };
    values['menciones']={};

    Object.keys(isCheckedMenciones).map (cuenta =>{
      values['menciones'][`${cuenta}`]= isCheckedMenciones[cuenta];
    });

    values['hashtags']={};
    Object.keys(isCheckedHashtags).map (hashtag =>{
      values['hashtags'][`${hashtag}`] = isCheckedHashtags[hashtag];
    });

    console.log(values);

    dispatch(aplicarFiltros(client, idCamp, values));



  };
  const validate = () =>{

  };

  return (
<Form
    onSubmit={onSubmit}
    validate={validate}
    render={({ handleSubmit }) => (
      <form onSubmit={handleSubmit} >
    <Div_menu_aside>
      <Bar_menu_aside>
        <Bar_2_menu_aside>
          {/* ************ LISTADO INFLUENCERS ************ */}
          <Div12 style={{ paddingTop: "20px", display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }}>
            <Div12_Center>
              <Titulo_Seccion_Menu>
                <Text_Titulo_Seccion>LISTADO INFLUENCERS</Text_Titulo_Seccion>
              </Titulo_Seccion_Menu>
            </Div12_Center>
            <Div12_Center style={{ paddingBottom: "15px", paddingTop: "15px" }}>
              <Content_Listado_Influencers>
                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Text_Titulo_Content>Influencers</Text_Titulo_Content>
                </Div12>
                <Div12 style={{ paddingLeft: "10px" }}>
                  <Users_listado_influencers>
                  {datosSingleCampaign.hasOwnProperty('influencers') ?
                
                datosSingleCampaign.influencers.map((influencer,i) => (
                    <span  key={`influencer_${influencer.id}`}>@{influencer.name}{(i>0 && i<(datosSingleCampaign.influencers.length -1))? ', ': null}</span>

                )):null
                }
                  </Users_listado_influencers>
                </Div12>
                <Div12 style={{ paddingTop: "10px" }}>
                  <Add_button href="#" onClick={(e)=>{e.preventDefault();setIsOpenModalInfluencers(true);}}>+ Añadir Influencers</Add_button>
                </Div12>
              </Content_Listado_Influencers>
            </Div12_Center>
          </Div12>
          {/* ************ END LISTADO INFLUENCERS ************ */}

          {/* ************ FILTROS BÁSICOS ************ */}
          <Div12>
            <Div12_Center>
              <Titulo_Seccion_Menu>
                <Text_Titulo_Seccion>FILTROS BÁSICOS</Text_Titulo_Seccion>
              </Titulo_Seccion_Menu>
            </Div12_Center>
            <Div12_Center style={{ paddingTop: "10px" }}>
            
            <Field
                                    nombre="redes[instagram]"
                                    name="redes[instagram]"
                                    type="checkbox"
                                    id="red_instagram"
                                    value={isCheckedRed.instagram}
                                    valor={isCheckedRed.instagram}
                                    onChange={(e)=>{e.preventDefault();setIsCheckedRed({...isCheckedRed, instagram: e.target.checked});setFiltroRedesKey(Math.random())}}
                                    component={RenderCheckboxRed}
                                    red="instagram"
                                    key={`filtro_red_instagram_${filtroRedesKey}`}
                                  />
              
              
              <Field
                                    nombre="redes[facebook]"
                                    name="redes[facebook]"
                                    type="checkbox"
                                    id="red_facebook"
                                    value={isCheckedRed.facebook}
                                    valor={isCheckedRed.facebook}
                                    onChange={(e)=>{e.preventDefault();setIsCheckedRed({...isCheckedRed, facebook: e.target.checked});setFiltroRedesKey(Math.random())}}
                                    component={RenderCheckboxRed}
                                    red="facebook"
                                    key={`filtro_red_facebook_${filtroRedesKey}`}
                                  />
              <Field
                                    nombre="redes[twitter]"
                                    name="redes[twitter]"
                                    type="checkbox"
                                    id="red_twitter"
                                    value={isCheckedRed.twitter}
                                    valor={isCheckedRed.twitter}
                                    onChange={(e)=>{e.preventDefault();setIsCheckedRed({...isCheckedRed, twitter: e.target.checked});setFiltroRedesKey(Math.random())}}
                                    component={RenderCheckboxRed}
                                    red="twitter"
                                    key={`filtro_red_twitter_${filtroRedesKey}`}
                                  />
              <Field
                                    nombre="redes[youtube]"
                                    name="redes[youtube]"
                                    type="checkbox"
                                    id="red_youtube"
                                    value={isCheckedRed.youtube}
                                    valor={isCheckedRed.youtube}
                                    onChange={(e)=>{e.preventDefault();setIsCheckedRed({...isCheckedRed, youtube: e.target.checked});setFiltroRedesKey(Math.random())}}
                                    component={RenderCheckboxRed}
                                    red="youtube"
                                    key={`filtro_red_youtube_${filtroRedesKey}`}
                                  />
       
            </Div12_Center>
            <Div12_Center style={{ paddingTop: "10px" }}>
              <Content_Filtros_Basicos>
                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px",  display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }}>
                  <Text_Titulo_Content>Cuentas vinculadas</Text_Titulo_Content>
                </Div12>
                {datosSingleCampaign.hasOwnProperty('cuentas') ?
                
                datosSingleCampaign.cuentas.map(cuenta => (

                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px",  display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }} key={`cuenta_${cuenta.id}`}>
                  <Div1>

                  <Field
                                    nombre={cuenta.nombre.toString()}
                                    name={cuenta.nombre.toString()}
                                    type="checkbox"
                                    id={`menciones[${cuenta.nombre}]`}
                                    value={isCheckedMenciones[cuenta.nombre]}
                                    valor={isCheckedMenciones[cuenta.nombre]}
                                    onChange={(e)=>{e.preventDefault();
                                      let aux=isCheckedMenciones;
                                      aux[e.target.name]=e.target.checked;
                                      setIsCheckedMenciones(aux);
                                      setFiltroRedesKey(Math.random())
                                    }}
                                    component={RenderCheckboxMenciones}
                                    key={`filtro_menciones_${cuenta.nombre}_${filtroRedesKey}`}
                                  />
                  </Div1>
                  <Div10>
                    <span>{cuenta.nombre}</span>
                  </Div10>
                  <Div1>
                    <a
                      href="#"
                      style={{ textDecoration: "none", float: "end" }}
                    >
                      x
                    </a>
                  </Div1>
                </Div12>
                )) : null }
                <Div12 style={{ paddingTop: "20px",  display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }}>
                  <Add_button href="#"  onClick={(e)=>{e.preventDefault();setIsOpenModalCuentas(true)}}>+ Añadir Cuenta Vinculada</Add_button>
                </Div12>
              </Content_Filtros_Basicos>
            </Div12_Center>

            <Div12_Center style={{ paddingTop: "10px" }}>
              <Content_Filtros_Basicos>
                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px",  display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }}>
                  <Text_Titulo_Content>
                    Hashtags y Menciones
                  </Text_Titulo_Content>
                </Div12>
                
                {datosSingleCampaign.hasOwnProperty('hashtagsCampaign') ?
                
                datosSingleCampaign.hashtagsCampaign.map((hash,index) => (

                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px", display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }} key={`hash_${index}`}>
                  <Div1>
                  <Field
                                    nombre={hash.hashtag}
                                    name={hash.hashtag}
                                    type="checkbox"
                                    id={`hashtag[${hash.hashtag}]`}
                                    value={isCheckedHashtags[hash.hashtag]}
                                    valor={isCheckedHashtags[hash.hashtag]}
                                    onChange={(e)=>{e.preventDefault();
                                      let aux=isCheckedHashtags;
                                      aux[e.target.name]=e.target.checked;
                                      setIsCheckedHashtags(aux);
                                      setFiltroRedesKey(Math.random())
                                    }}
                                    component={RenderCheckboxMenciones}
                                    key={`filtro_menciones_${hash.hashtag}_${filtroRedesKey}`}
                                  />
                  </Div1>
                  <Div10>
                    <span>#{hash.hashtag}</span>
                  </Div10>
                  <Div1>
                    <a
                      href="#"
                      style={{ textDecoration: "none", float: "end" }}
                    >
                      x
                    </a>
                  </Div1>
                </Div12>
                ))
                  : null
                  }


                <Div12 style={{ paddingTop: "20px",  display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block')  }}>
                  <Add_button href="#"  onClick={(e)=>{e.preventDefault();setIsOpenModalHashtag(true)}}>+ Añadir Hashtag</Add_button>
                </Div12>
              </Content_Filtros_Basicos>
            </Div12_Center>
          </Div12>
          {/* ************ END FILTROS BÁSICOS ************ */}

          {/* ************ FILTROS AVANZADOS ************ */}
          <Div12 style={{ paddingTop: "20px" }}>
            <Div12_Center>
              <Titulo_Seccion_Menu>
                <Text_Titulo_Seccion>FILTROS AVANZADOS</Text_Titulo_Seccion>
              </Titulo_Seccion_Menu>
            </Div12_Center>

            <Div12_Center style={{ paddingTop: "10px" }}>
              <Content_Filtros_Basicos>
                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px", display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block') }}>
                  <Text_Titulo_Content style={{ display: ( client.decode.roles.includes('ROLE_INFLUENCER') ? 'none': 'block') }}>Total Seguidores</Text_Titulo_Content>
                </Div12>
                <Div12_Center
                  style={{ paddingBottom: "10px", paddingTop: "10px" }}
                >
                   <Field
                      name="minFollowers"
                      type="number"
                      id="minFollowers"
                      className="input_filtro"
                      component="input"
                      placeholder="Min"
                    />
                  
                  <Field
                      name="maxFollowers"
                      type="number"
                      id="maxFollowers"
                      className="input_filtro"
                      component="input"
                      placeholder="Max"
                    />
                </Div12_Center>
                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Text_Titulo_Content>Total Interacciones</Text_Titulo_Content>
                </Div12>
                <Div12_Center
                  style={{ paddingBottom: "10px", paddingTop: "10px" }}
                >
                   <Field
                      name="minInteracciones"
                      type="number"
                      id="minInteracciones"
                      className="input_filtro"
                      component="input"
                      placeholder="Min"
                    />
                  <Field
                      name="maxInteracciones"
                      type="number"
                      id="maxInteracciones"
                      className="input_filtro"
                      component="input"
                      placeholder="Max"
                    />
                </Div12_Center>
              </Content_Filtros_Basicos>
            </Div12_Center>

            <Div12_Center style={{ paddingTop: "10px" }}>
              <Content_Filtros_Basicos>
                <Div12 style={{ paddingBottom: "10px", paddingTop: "10px" }}>
                  <Text_Titulo_Content>Fecha Publicación</Text_Titulo_Content>
                </Div12>
                <Div12_Center
                  style={{ paddingBottom: "5px", paddingTop: "10px" }}
                >
                  <Label>Desde</Label>
                </Div12_Center>
                <Div12_Center style={{ paddingBottom: "10px" }}>
                <Field
                      name="desdeFechaPublicacion"
                      type="date"
                      id="desdeFechaPublicacion"
                      className="input_filtro_date"
                      component="input"
                      placeholder="dd/mm/YYYY"
                    />
                </Div12_Center>
                <Div12_Center
                  style={{ paddingBottom: "5px", paddingTop: "10px" }}
                >
                  <Label>Hasta</Label>
                </Div12_Center>
                <Div12_Center style={{ paddingBottom: "10px" }}>
                <Field
                      name="hastaFechaPublicacion"
                      type="date"
                      id="hastaFechaPublicacion"
                      className="input_filtro_date"
                      component="input"
                      placeholder="dd/mm/YYYY"
                    />
                </Div12_Center>
              </Content_Filtros_Basicos>
            </Div12_Center>
          </Div12>
          {/* ************ END FILTROS AVANZADOS ************ */}

          {/* ************ BOTONES FIN FILTROS ************ */}
          <Div12>
            <Div12_Center style={{ paddingBottom: "10px", paddingTop: "20px" }}>
              <ButtonMenu style={{ background: "#2C5282", color: "#ffffff" }}>
                Limpiar
              </ButtonMenu>
              <ButtonMenu
                style={{ background: "#6CDB92", color: "#ffffff", border: 0 }}
              >
                Aplicar
              </ButtonMenu>
            </Div12_Center>
          </Div12>
          {/* ************ END BOTONES FIN FILTROS ************ */}
        </Bar_2_menu_aside>
      </Bar_menu_aside>
    </Div_menu_aside>
    </form>
    )}
    />
    
  );
};

const RenderCheckboxRed = ({nombre, name, input, onChange, value, red, valor}) =>{
    return (
        <Content_RRSS_Filtros_Basicos>
          <Div12_Center>
          {red === 'instagram'?  <Logo_RRSS src="/assets/images/instagram-logo.png"></Logo_RRSS> : null}
          {red === 'facebook'?  <Logo_RRSS src="/assets/images/fb-logo.png"></Logo_RRSS> : null}
          {red === 'youtube'?  <Logo_RRSS src="/assets/images/youtube-logo.png"></Logo_RRSS> : null}
          {red === 'twitter'?  <Logo_RRSS src="/assets/images/twitter-logo.png"></Logo_RRSS> : null}
               
          </Div12_Center>
          <Div12_Center>
            <input type="checkbox" value={valor} onChange={onChange} name={nombre} checked={ valor === true ? 'checked': false }/>
          </Div12_Center>
      </Content_RRSS_Filtros_Basicos>
    );
}


const RenderCheckboxMenciones = ({nombre, name, input, onChange, value, red, valor}) =>{

  return (
      <input type="checkbox" value={valor} onChange={onChange} name={nombre} checked={ valor === true ? 'checked': false }/>
  );
}


export default FiltrosMenuAside;
