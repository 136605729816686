import { call, put, takeLatest, takeEvery } from 'redux-saga/effects'
import { handleApiErrors } from '../lib/api-errors'
import {listadoCamps,mensajeCrearCamp} from './actions'
import { Dispatch } from 'react'
import {
  LISTADO_CAMP_REQUESTING,
  LISTADO_CAMP_SUCCESS,
  LISTADO_CAMP_ERROR,
  CREAR_CAMP_ERROR,
  CREAR_CAMPAÑA,
  SET_PAGINACION
} from './constants'

// The url derived from our .env file
const listadoCampsURL = `${process.env.REACT_APP_API_URL}/api/campaigns`
const nuevacampURL = `${process.env.REACT_APP_API_URL}/api/campaigns`
const addTagTocampURL = `${process.env.REACT_APP_API_URL}/api/hashtags`
const addInfluencerTocampURL = `${process.env.REACT_APP_API_URL}/api/influencers`
const uploadImageURL = `${process.env.REACT_APP_API_URL}/api/media_objects`
const addImageTocampURL=`${process.env.REACT_APP_API_URL}/api/campaigns/`



function obtenerCampsApi (client, page=null) {
  // call to the "fetch".  this is a "native" function for browsers
  // that's conveniently polyfilled in create-react-app if not available
  // console.log(client.token.token);
  return fetch( (page !== null ? `${process.env.REACT_APP_API_URL}${page}` : listadoCampsURL), {
    method: 'GET',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    //body: JSON.stringify({})
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
      return res;
    })
    .catch((error) => { 
      throw error })
}


// This will be run when the LISTADO_CAMP_REQUESTING
// Action is found by the watcher
function* listadoCampsFlow (action) {
  try {
    const {client, page } = action.payload;
    // console.log(client);
    // pulls "calls" to our signupApi with our email and password
    // from our dispatched signup action, and will PAUSE
    // here until the API async function, is complete!
    const response = yield call(obtenerCampsApi,client,page)
    // when the above api call has completed it will "put",
    // or dispatch, an action of type SIGNUP_SUCCESS with
    // the successful response.
    //Controlo si tiene paginación 
    if (response.hasOwnProperty('hydra:view')){
     let pagination = response['hydra:view'];
      yield put({ type: SET_PAGINACION, pagination})
    }
    
    yield put({ type: LISTADO_CAMP_SUCCESS, response })
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    console.log(error);
    yield put({ type: LISTADO_CAMP_ERROR, error })
  }
}


//Acciones para nuevas campañas
/*
Los pasos son:
1) Enviar un post /api/campaigns con los datos básicos de la campaña:
{
  "nombre": "string",
  "fechaInicio": "2022-01-14T13:02:58.708Z",
  "fechaFin": "2022-01-14T13:02:58.708Z",
  "continua": true
}
Si la campaña es continua no se envía el campo fechaFin
2. Recibir la ID del tipo
/api/campaigns/80
3. Enviar la imagen llamando a /api/media_objects y guardar su ID para hacer luego un put a la campaña con la id de la imagen
4. Crear uno por uno los hasthags, llamando a /api/hashtags pasando el nombre del hashtag y la id en formato url de la campaña
{
  "hashtag": "string",
  "campaign": "string"
}
5. Igual para los influencers
6. Si todo está bien, notificarlo al usuario. 
*/
function* nuevaCampFlow (action) {
  try {
    const {client, datosBasicos} = action.payload;
    let mensaje={ tipo:"alerta",mensaje:"Creando la campaña"};
    yield put({ 
      type: 'MENSAJE_CREAR_CAMPAÑA', 
      mensaje
    });
    const response = yield call(crearCampApi, client, datosBasicos);

     //chequeo si tiene tags.
     if (response.hasOwnProperty('@id')){
        let idCampNew= response['@id'];
        let normalIdCampNew= response.id;
        let respuestasTags=[];
       if (datosBasicos.tags.length >0){
          datosBasicos.tags.forEach((tag,indice) =>{
            // console.log("llamando a addTagToCampApi");
            respuestasTags[indice] = addToCampApi(client,addTagTocampURL,{"hashtag": tag,"campaign": idCampNew});
          })
        }
        let respuestasInfluencers=[];
       if (datosBasicos.influencers.length >0){
          datosBasicos.influencers.forEach((influencer,indice) =>{
            respuestasInfluencers[indice] = addToCampApi(client,addInfluencerTocampURL,{"name": influencer,"campaign": [idCampNew]});
          })
        }
        if (datosBasicos.hasOwnProperty('image') && datosBasicos.imagen.length >0){
          try{
            mensaje={ tipo:"alerta",mensaje:"Añadiendo la imagen"};
            yield put({ 
              type: 'MENSAJE_CREAR_CAMPAÑA', 
              mensaje
            });
            let respuestaImagen=uploadImagenToCampApi(client,datosBasicos.imagen,normalIdCampNew);
            // console.log(respuestaImagen);
            mensaje={ tipo:"alerta",mensaje:"Campaña creada correctamente"};
                yield put({ 
                  type: 'MENSAJE_CREAR_CAMPAÑA', 
                  mensaje
                });
            
          }catch(e){
            console.log(e);
          }
        }else{
          mensaje={ tipo:"alerta",mensaje:"Campaña creada correctamente"};
          yield put({ 
            type: 'MENSAJE_CREAR_CAMPAÑA', 
            mensaje
          });
        }
    }
  } catch (error) {
    // if the api call fails, it will "put" the SIGNUP_ERROR
    // into the dispatch along with the error.
    //Aqui tengo que controlar los problemas cuando se alcanza el máximo del PLAN
    console.log(error);
    if (error.hasOwnProperty('type') && error.type==='Error plan'){
      // console.log("Gestionar cambio de plan");
    }
    if (error.hasOwnProperty('type') && error.type==='Error JWT'){
      // console.log("Gestionar error login");
    }
    yield put({ type: CREAR_CAMP_ERROR, error })
    let mensaje={ tipo:"alerta",mensaje:"Error al crear la campaña"};
          yield put({ 
            type: 'MENSAJE_CREAR_CAMPAÑA', 
            mensaje
          });
  }
}

function crearCampApi (client, datosBasicos) {

  var dateParts = datosBasicos.fechaInicio.split("-");
  // month is 0-based, that's why we need dataParts[1] - 1
  var dateInicio = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]); 
  let datos ={
    nombre: datosBasicos.nombre,
    fechaInicio: dateInicio,
    continua: datosBasicos.continua
  }
  return fetch(nuevacampURL , {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(datos)
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}


const uploadImagenToCampApi = async (client,data,normalIdCampNew) => {
  try{
  const formData  = new FormData();
  /*
  for(const name in data) {
    formData.append(name, data[name]);
  }*/
  formData.append('file',data[0]);
  return fetch(uploadImageURL , {
    method: 'POST',
    headers: {
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: formData
  })
  .then(response => response.json())
  .then(respuestaImagen =>{
    if (respuestaImagen.hasOwnProperty('@context') && respuestaImagen['@context']==='/api/contexts/MediaObject'){
        addToCampApi(client,addImageTocampURL+normalIdCampNew,{"image": respuestaImagen['@id']},'PUT').then(response =>{
      })
    }
    return respuestaImagen;
  })
  .catch((error) => { 
    console.log(error);
    throw error })
  }catch(e){
    console.log(e);
  }
}

function addToCampApi (client,url, datos, method="POST") {

  return fetch(url , {
    method: method,
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify(datos)
  })
  .then(handleApiErrors) // we'll make this in a second
  .then(response => response.json())
  .then(json => json)
  .then(res =>{
    return res;
  })
  .catch((error) => { 
    throw error })
}

// Watches for the SIGNUP_REQUESTING action type
// When it gets it, it will call signupFlow()
// WITH the action we dispatched
function* listadoCampsWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeEvery(
    LISTADO_CAMP_REQUESTING, listadoCampsFlow
  )
 
  yield takeEvery(
    CREAR_CAMPAÑA, nuevaCampFlow
  )


}

export default listadoCampsWatcher
