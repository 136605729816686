import React, { Fragment, useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import { Link } from "react-router-dom";
import styled from "styled-components";
import {ModalTitle,Card1,
DivRecomendado,
TipoPlan,
Text4,
Text5,
Text6,
VerTodosPlanes,
Card2, BotonContratar} from './planes_style';

const BasicInfluencer = ({setModoActivo,modoActivo}) => {

    return(
        <Fragment>
    <Card1
                                onClick={(e)=>{
                                  e.preventDefault();
                                  setModoActivo('mensual');
                                }}
                                style={{zIndex: (modoActivo!=='mensual' ? 1 : 2), left: (modoActivo!=='mensual' ? '40px' : '0px') }}

                >
                    <DivRecomendado><span>recomendado</span></DivRecomendado><TipoPlan>Mensual</TipoPlan>
                    <Text4>Basic</Text4>
                    
                    <Text5>9€ <span>/mes</span></Text5>
                    <Text6>La mejor opción para gestinar tus publicaciones al mejor precio</Text6>
                    <ul>
                                    <li><img src="/assets/images/check-circle-1.png"/> Ideal para todos los influencers</li>
                                    <li><img src="/assets/images/check-circle-1.png"/> Sin límites de posts</li>
                                    <li><img src="/assets/images/check-circle-1.png"/> Informe de EMV completo</li>
                                    <li><img src="/assets/images/check-circle-1.png"/>Conecta tus principales redes sociales</li>
                                </ul>
                    <Link to={`/planes/basic_influencer/${modoActivo}`}><BotonContratar>Contratar Plan Basic {modoActivo}</BotonContratar></Link>
                    <Link to="/planes"><VerTodosPlanes href="#">Ver todos los planes</VerTodosPlanes></Link>
                </Card1>
                <Card2
                onClick={(e)=>{
                  e.preventDefault();
                  setModoActivo('anual');
                }}

                style={{zIndex: (modoActivo==='mensual' ? 1 : 2), left: (modoActivo==='mensual' ? '40px' : '0px') }}
                >
                <DivRecomendado><span>recomendado</span></DivRecomendado><TipoPlan>Anual</TipoPlan>
                    <Text4>Basic</Text4>
                    <Text5>90€ <span>/año</span></Text5>
                    <Text6>La mejor opción para gestinar tus publicaciones al mejor precio</Text6>
                    <ul>
                    <li><img src="/assets/images/check-circle-1.png"/> Ideal para todos los influencers</li>
                                    <li><img src="/assets/images/check-circle-1.png"/> Sin límites de posts</li>
                                    <li><img src="/assets/images/check-circle-1.png"/> Informe de EMV completo</li>
                                    <li><img src="/assets/images/check-circle-1.png"/>Conecta tus principales redes sociales</li>

                                </ul>
                                <Link to={`/planes/basic_influencer/${modoActivo}`}><BotonContratar>Contratar Plan Basic</BotonContratar></Link>
                                <Link to={`/planes`}><VerTodosPlanes href="#">Ver todos los planes</VerTodosPlanes></Link>
                                

                </Card2>
    </Fragment>
    )
}

export default BasicInfluencer;