import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import styled from "styled-components";
import UserRow from "./userRow";
import PostRow from "./postRow";
import StoryRow from "./storyRow";
import PaginadorRow from "./PaginadorRow";
import listadoCamps from '../dashboard/actions';
import { useParams } from 'react-router-dom';
import TotalVisibility from "./totalVisibility";
import {
  singleCampaign,
  singleCampaignMentionedMedia,
  singleCampaignUsernames,
  deleteMentionedRequesting,
  singleCampaignStories,
  getDataGraficaSpline,
  obtenerMencionesDelUsuario
} from '../campaign/actions';
import { ChartsWidget3 } from "./charts/ChartsWidget3";
import { MixedWidget7 } from "./charts/MixedWidget7";
import { MixedWidget7_2 } from "./charts/MixedWidget7_2";

 /* ****** Elementos styled ****** */
 const Div12 = styled.div`
 width: 100%;
 display: block;
`;

const Div12_Center = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;
 
`;

const Div13_Center = styled.div`
width: 100%;
display: flex;
display: rows;


  @media(max-width:500px){
    display:flex;
    flex-direction:column;
  }
`;

const Div12_Center_Tab = styled.div`
 width: 100%;
 display: flex;
 justify-content: center;

 border-radius: 10px;
 cursor: pointer;
 &:hover {
     background: #2D3748;
     color: #ffffff;
 }
`;

const Div1 = styled.div`
 width: 10%;
 float: left;
`;

const Div6 = styled.div`
 width: 35%;
 float: left;
`;

const Div10 = styled.div`
 width: 80%;
 float: left;
`;

const Text_Titulo_Content = styled.span`
 left: 15.59%;
 right: 53.22%;
 top: 7.97%;
 bottom: 90.14%;

 font-style: normal;
 font-weight: bold;
 font-size: 18px;
 line-height: 21px;

 color: #2d3748;

 padding-left: 10px;
`;

/* ***************** DETALLES CAMPAÑA ***************** */
const DivMainCamp = styled.div`
margin: 0px 0px 0px 320px;
width: 80%;
height: 767px;
left: 317px;
top: 105px;

filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

display: block;
float: left;

@media (max-width:500px){
 margin: 0px 0px 0px 20px;
 width: 100%;
 flex-direction:column;
 z-index: 0;
`;

const DivGroupCamp = styled.div`
/*height: 784px;*/
left: 0px;
top: 0px;
width: calc(100% - 50px);

@media (max-width:500px){
 display:flex;
 flex-direction: column;

`;

const DataBox = styled.div`
width: 100%;
background: #ffffff;
box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.07);
border-radius: 10px;
margin-top: 20px;
padding-bottom: 20px;
  @media (max-width:500px){
    margin-top: 0px;
  }

`;

const StatsBox = styled.div`
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  /* White 100 */

  background: #ffffff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.21);
  border-radius: 10px;
  width: 18%;
  margin: 0px 0px 0px 20px;
    @media (max-width:500px){
      margin: 5px 9px;
      width: 95%;
    }
`;

const Text_StatsBox = styled.span`
 left: 15.59%;
 right: 53.22%;
 top: 7.97%;
 bottom: 90.14%;

 font-style: normal;
 font-weight: bold;
 font-size: 15px;
 line-height: 21px;

 color: #2d3748;

 padding-left: 10px;
`;

const Number_StatsBox = styled.span`
 left: 6.7%;
 right: 29.81%;
 top: 40.38%;
 bottom: 13.46%;

 font-style: normal;
 font-weight: 300;
 font-size: 14px;
 line-height: 16px;
 display: flex;
 align-items: flex-end;

 padding-left: 10px;
`;

const Icon_StatsBox = styled.span`
 left: 6.7%;
 right: 29.81%;
 top: 40.38%;
 bottom: 13.46%;

 font-style: normal;
 font-weight: 300;
 font-size: 14px;
 line-height: 16px;
 display: flex;
 align-items: flex-end;

 display: block;
 padding-right: 10px;
`;

const GraficBox = styled.div`
width: 50%;
height: auto;
left: 0px;
top: 0px;
background: #ffffff;
box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.07);
border-radius: 10px;
margin: 20px 5px;
display: block;
position: relative;

@media(max-width:500px){
 width: 98%;
`;

const Div_Link = styled.div`
    width: 100%;
    postion:absoulte;
    display: flex;
    justify-content: center;
  
    margin: 40px 0px 0px 0px;
    `;

const Button = styled.button`
 width: 98px;
 height: 32px;
 left: 41px;
 top: 719px;

 background: #ffffff;

 border: 1px solid #2c5282;
 box-sizing: border-box;
 border-radius: 25px;

 margin: 20px 5px;
 font-style: normal;
 font-weight: bold;
 font-size: 13px;
 line-height: 18px;
`;

const Link = styled.button`
 width: 80px;
 height: 30px;
 left: 41px;
 background: #ffffff;
 border: 0px solid #019EF7;
 box-sizing: border-box;
 border-radius: 25px;
 margin: 20px 5px;
 font-style: normal;
 font-size: 12px;
 line-height: 18px;
 color: #466892;
 cursor: pointer;
`;

const DivTabBtn = styled.div`
 
`;

const IconGrafica = styled.img`
  margin-bottom: 5px;
`;

const SpanLabelGrafica = styled.span`
  font-size: 12px;
`;

/* ***************** END DETALLES CAMPAÑA ***************** */
/* ****** END Elementos styled ****** */


const DetalleCampaign = ({datosSingleCampaign, isOpenModalInfluencers, setIsOpenModalInfluencers}) => {

    /* ***************** REDUX ***************** */
    const dispatch = useDispatch();
    const client = useSelector(state=> state.client);
    const paginacion = useSelector((state) => state.dashboard.pagination);
    const mentionedMediaStorage = useSelector((state) => state.campaign.mentionedMedia);
    const usernamesStorage = useSelector((state) => state.campaign.usernames);
    const storiesStorage = useSelector((state) => state.campaign.stories);
    const datosGraficasSpline = useSelector((state) => state.campaign.dataGraficaSpline);
    const datosGraficasDonut = useSelector((state) => state.campaign.dataGraficaDonut);
    const postPorUsername = useSelector((state) => state.campaign.mencionesDelUsername);
    const postPorUsernameUpdates = useSelector((state) => state.campaign.mencionesDelUsername.update);
    const filtro = useSelector((state) => state.campaign.filtro);
    const datosBasicos = useSelector((state) => state.campaign.datos);
    
    const { idCamp} = useParams()

    /* ***************** END REDUX ***************** */
   
    
    /* ***************** STATES ***************** */
    const [tabCheck, setTabCheck] = useState({
        allContributors: true,
    });
    const [datosMentionedMedia, setDatosMentionedMedia] = useState([]);
    const [datosUsernames, setDatosUsernames] = useState([]);
    const [datosStories, setDatosStories] = useState([]);
    const [likesPercent, setLikesPercent] = useState(0);
    const [commentsPercent, setCommentsPercent] = useState(0);
    const [dataSpline, setDataSpline] = useState([]);
    const [categoriesSpline, setCategoriesSpline] = useState([]);
    const [filterSpline, setFilterSpline] = useState('todo');
    const [filterDonut, setFilterDonut] = useState('todo');
    const [rerender, setRerender] = useState(Math.random);
    const [enlaces, setEnlaces] = useState(false);
    /* ***************** END STATES ***************** */


    /* ***************** USE-EFFECTS ***************** */
    useEffect(() => {
      if (datosGraficasDonut.length > 0){
        Object.keys(datosGraficasDonut).forEach((key) => {
          if (datosGraficasDonut[key].tipo === 'instagram'){
            let totalLikesComments = datosGraficasDonut.likes + datosGraficasDonut.comments;
            let likes = datosGraficasDonut.likes;
            let comments = datosGraficasDonut.comments;
            let likes_percentage = (likes * 100) / totalLikesComments;
            let comments_percentage = (comments * 100) / totalLikesComments;
            // setLikesPercent(Math.round(likes_percentage * 10) / 10);
            // setCommentsPercent(Math.round(comments_percentage * 10) / 10);
          }
        });
      }
        
    }, [datosGraficasDonut, filterDonut]);

   
    useEffect(() => {
      if (datosGraficasSpline.length > 0){
        let arrayData = [];
        let arrayCategories = [];
        let arrayDataInstagram = [];
        let arrayCategoriesInstagram = [];
        let arrayDataTwitter = [];
        let arrayCategoriesTwitter = [];
        let arrayDataFacebook = [];
        let arrayCategoriesFacebook = [];
        let arrayDataYoutube = [];
        let arrayCategoriesYoutube = [];
        Object.keys(datosGraficasSpline).forEach((key) => {
          arrayData.push(Number(datosGraficasSpline[key].likes));
          arrayCategories.push(datosGraficasSpline[key].fecha);

          if (datosGraficasSpline[key].tipo === 'instagram'){
            arrayDataInstagram.push(Number(datosGraficasSpline[key].likes));
            arrayCategoriesInstagram.push(datosGraficasSpline[key].fecha);
          }

          if (datosGraficasSpline[key].tipo === 'twitter'){
            arrayDataTwitter.push(Number(datosGraficasSpline[key].likes));
            arrayCategoriesTwitter.push(datosGraficasSpline[key].fecha);
          }

          if (datosGraficasSpline[key].tipo === 'facebook'){
            arrayDataFacebook.push(Number(datosGraficasSpline[key].likes));
            arrayCategoriesFacebook.push(datosGraficasSpline[key].fecha);
          }

          if (datosGraficasSpline[key].tipo === 'youtube'){
            arrayDataYoutube.push(Number(datosGraficasSpline[key].likes));
            arrayCategoriesYoutube.push(datosGraficasSpline[key].fecha);
          }
        });

        if (filterSpline === 'todo'){
          setDataSpline(arrayData);
          setCategoriesSpline(arrayCategories);
        }

        if (filterSpline === 'instagram'){
          setDataSpline(arrayDataInstagram);
          setCategoriesSpline(arrayCategoriesInstagram);
        }

        if (filterSpline === 'twitter'){
          setDataSpline(arrayDataTwitter);
          setCategoriesSpline(arrayCategoriesTwitter);
        }

        if (filterSpline === 'facebook'){
          setDataSpline(arrayDataFacebook);
          setCategoriesSpline(arrayCategoriesFacebook);
        }

        if (filterSpline === 'youtube'){
          setDataSpline(arrayDataYoutube);
          setCategoriesSpline(arrayCategoriesYoutube);
        }
        
      }
    }, [datosGraficasSpline, filterSpline]);

 
    useEffect(()=>{
      if (mentionedMediaStorage!== undefined){
        if ( mentionedMediaStorage.hasOwnProperty('hydra:member') && Array.isArray(mentionedMediaStorage['hydra:member'])){
          setDatosMentionedMedia(mentionedMediaStorage['hydra:member']);
        }
      }      
    },[mentionedMediaStorage]);

    useEffect(()=>{
      if (usernamesStorage!== undefined){
        if ( usernamesStorage.hasOwnProperty('hydra:member') && Array.isArray(usernamesStorage['hydra:member'])){
          setDatosUsernames(usernamesStorage['hydra:member']);
        }
      }
    },[usernamesStorage]);

    useEffect(()=>{
      if (storiesStorage!== undefined){
        if ( storiesStorage.hasOwnProperty('hydra:member') && Array.isArray(storiesStorage['hydra:member'])){
          setDatosStories(storiesStorage['hydra:member']);
        }
      }
    },[storiesStorage]);

    useEffect(() => {
      if (datosSingleCampaign.hasOwnProperty('likes') && datosSingleCampaign.hasOwnProperty('comments')){
        let totalLikesComments = datosSingleCampaign.likes + datosSingleCampaign.comments;
        let likes = datosSingleCampaign.likes;
        let comments = datosSingleCampaign.comments;
        let likes_percentage = (likes * 100) / totalLikesComments;
        let comments_percentage = (comments * 100) / totalLikesComments;
        setLikesPercent(Math.round(likes_percentage * 10) / 10);
        setCommentsPercent(Math.round(comments_percentage * 10) / 10);
      }

    }, [datosSingleCampaign])
    /* ***************** END USE-EFFECTS ***************** */



    /* ***************** FUNCTIONS ***************** */
    const clickPaginacion = (paginacion) =>{
        dispatch(listadoCamps(client,paginacion));
    }

    const clickPaginacionMentionedMedia = (paginacion) =>{
      dispatch(singleCampaignMentionedMedia(client,datosSingleCampaign.id,paginacion));
    }

    const clickPaginacionUsernames = (paginacion) =>{
      dispatch(singleCampaignUsernames(client,datosSingleCampaign.id,paginacion));
    }

    const clickPaginacionObtenerPostUsername = (paginacion) =>{
      const params = new URLSearchParams(paginacion);
      dispatch(obtenerMencionesDelUsuario(client, idCamp, paginacion, filtro, params.get('username')))
    }

    const clickPaginacionStories = (paginacion) =>{
      dispatch(singleCampaignStories(client,datosSingleCampaign.id,paginacion));
    }

    const updateTabCheck = (field) => {
        setTabCheck({
            [field]: true
        });
    }

    const obtenerPostsUser = (username) =>{
      dispatch(obtenerMencionesDelUsuario(client, idCamp, null, filtro, username))
    }
    const mostratDetalle = (e) =>{
      setEnlaces(!enlaces);
      setRerender(Math.random);
  }
    /* ***************** END FUNCTIONS ***************** */


  return (
    <DivMainCamp>
      <DivGroupCamp>
        <Div12_Center className="div-cuerpo-camp">
          <DataBox>
            <Div12 style={{ paddingBottom: "20px", paddingTop: "15px" }} >
              <Text_Titulo_Content>{datosSingleCampaign.nombre}</Text_Titulo_Content>
            </Div12>
            <Div13_Center   key={`datos_generales_${rerender}`}>
            <StatsBox>
                <Div12 style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                  <Text_StatsBox>Total Visibility</Text_StatsBox>
                </Div12>
                <Div12 style={{ paddingBottom: "20px" }}>
                  <Div10>
                    <Number_StatsBox>{datosBasicos && datosBasicos.hasOwnProperty("instagram") && datosBasicos.hasOwnProperty("facebook") && datosBasicos.hasOwnProperty("twitter") && datosBasicos.hasOwnProperty("youtube") ? (Number(datosBasicos.instagram.impressions) + Number(datosBasicos.facebook.impressions) + Number(datosBasicos.twitter.impressions) + Number(datosBasicos.youtube.impressions) ) : 0}</Number_StatsBox>
                  </Div10>
                  
                  <Div1>
                    <Icon_StatsBox  onClick={(e) =>{mostratDetalle(e);}}>i</Icon_StatsBox>
                  </Div1>
                </Div12>
                    <TotalVisibility key={`total_${rerender}`} style={{display: enlaces===true? 'block':'none'}}
                    className={enlaces===true? 'claseblock':'clasenone'}
                      instagram= {datosBasicos && datosBasicos.hasOwnProperty("instagram") ? datosBasicos.instagram.impressions : 0}
                      facebook={datosBasicos && datosBasicos.hasOwnProperty("facebook") ? datosBasicos.facebook.impressions : 0 }
                      twitter={datosBasicos && datosBasicos.hasOwnProperty("twitter") ? datosBasicos.twitter.impressions : 0 }
                      youtube={datosBasicos && datosBasicos.hasOwnProperty("youtube") ? datosBasicos.youtube.impressions : 0 }
                      />
                 
              </StatsBox>
              <StatsBox>
                <Div12 style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                  <Text_StatsBox>Posts</Text_StatsBox>
                </Div12>
                <Div12 style={{ paddingBottom: "20px" }}>
                  <Div10>
                    <Number_StatsBox>{
                            mentionedMediaStorage.hasOwnProperty('hydra:totalItems') ? mentionedMediaStorage['hydra:totalItems'] : null

                    }</Number_StatsBox>
                    
                  </Div10>
                  <Div1>
                    <Icon_StatsBox onClick={(e) =>{mostratDetalle(e);}}>i</Icon_StatsBox>
                  </Div1>
                </Div12>
                   <TotalVisibility key={`posts_${rerender}`} style={{display: enlaces===true? 'block':'none'}}
                      instagram= {datosBasicos && datosBasicos.hasOwnProperty("instagram") ? datosBasicos.instagram.posts : 0}
                      facebook={datosBasicos && datosBasicos.hasOwnProperty("facebook") ? datosBasicos.facebook.posts : 0 }
                      twitter={datosBasicos && datosBasicos.hasOwnProperty("twitter") ? datosBasicos.twitter.posts : 0 }
                      youtube={datosBasicos && datosBasicos.hasOwnProperty("youtube") ? datosBasicos.youtube.posts : 0 }
                      />
                 
              </StatsBox>
              <StatsBox>
                <Div12 style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                  <Text_StatsBox>Interacciones</Text_StatsBox>
                </Div12>
                <Div12 style={{ paddingBottom: "20px" }}>
                  <Div10>
                    <Number_StatsBox>{datosBasicos && datosBasicos.hasOwnProperty("instagram") ? ( (Number(datosBasicos.instagram.likes) + Number(datosBasicos.instagram.comentarios)) + (Number(datosBasicos.facebook.likes) + Number(datosBasicos.facebook.comentarios)) + (Number(datosBasicos.twitter.likes) + Number(datosBasicos.twitter.comentarios)) + (Number(datosBasicos.youtube.likes) + Number(datosBasicos.youtube.comentarios))  ): 0}</Number_StatsBox>
                  </Div10>
                  
                  <Div1>
                    <Icon_StatsBox onClick={(e) =>{mostratDetalle(e);}}>i</Icon_StatsBox>
                  </Div1>
                </Div12>
                     <TotalVisibility key={`interacciones_${rerender}`} style={{display: enlaces===true? 'block':'none'}}
                      instagram= {datosBasicos && datosBasicos.hasOwnProperty("instagram") ? datosBasicos.instagram.likes + datosBasicos.instagram.comentarios : 0}
                      facebook={datosBasicos && datosBasicos.hasOwnProperty("facebook") ? datosBasicos.facebook.likes + datosBasicos.facebook.comentarios : 0 }
                      twitter={datosBasicos && datosBasicos.hasOwnProperty("twitter") ? datosBasicos.twitter.likes + datosBasicos.twitter.comentarios : 0 }
                      youtube={datosBasicos && datosBasicos.hasOwnProperty("youtube") ? datosBasicos.youtube.likes +  datosBasicos.youtube.comentarios : 0 }
                      />
                  
              </StatsBox>
              <StatsBox>
                <Div12 style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                  <Text_StatsBox>EMV</Text_StatsBox>
                </Div12>
                <Div12 style={{ paddingBottom: "20px" }}>
                  <Div10>
                    <Number_StatsBox>{datosBasicos && datosBasicos.hasOwnProperty("instagram") ? (Number(datosBasicos.instagram.emv) +Number(datosBasicos.facebook.emv) +Number(datosBasicos.youtube.emv) +Number(datosBasicos.twitter.emv)    ) : 0 }€</Number_StatsBox>
                  </Div10>
                  <Div1>
                    <Icon_StatsBox onClick={(e) =>{mostratDetalle(e);}}>i</Icon_StatsBox>
                  </Div1>
                </Div12>
                    <TotalVisibility key={`emv_${rerender}`} style={{display: enlaces===true? 'block':'none'}}
                      instagram= {datosBasicos && datosBasicos.hasOwnProperty("instagram") ? datosBasicos.instagram.emv : 0 }
                      facebook={datosBasicos && datosBasicos.hasOwnProperty("facebook") ? datosBasicos.facebook.emv : 0 }
                      twitter={datosBasicos && datosBasicos.hasOwnProperty("twitter") ? datosBasicos.twitter.emv : 0 }
                      youtube={datosBasicos && datosBasicos.hasOwnProperty("youtube") ? datosBasicos.youtube.emv : 0 }
                      />
                 
              </StatsBox>              
              <StatsBox>
                <Div12 style={{ paddingBottom: "5px", paddingTop: "10px" }}>
                  <Text_StatsBox>Engagement ratio</Text_StatsBox>
                </Div12>
                <Div12 style={{ paddingBottom: "20px" }}>
                  <Div10>
                    <Number_StatsBox>0.09%</Number_StatsBox>
                  </Div10>
                  <Div1>
                    <Icon_StatsBox>info</Icon_StatsBox>
                  </Div1>
                </Div12>
              </StatsBox>
            </Div13_Center>
          </DataBox>
        </Div12_Center>

        {/* **************** GRÁFICAS **************** */}
        <Div13_Center className="Div12_Center">
          {/* **************** DONUTS **************** */}
          <GraficBox className="GraficBox">
            <Div12_Center className="Div12_Center mt-10">
              <Button style={{ background: "#2D3748", color: "#FFFFFF" }}>
                Interacciones
              </Button>
              <Button>Posts</Button>
              <Button>Influencers</Button>
            </Div12_Center>
            <Div12_Center className="Div12_Center">
              <Div6 className="Div6">
                <Div12_Center className="Div12_Center">
                  <MixedWidget7
                      className='MixedWidget7 card-xxl-stretch mb-xl-3'
                      chartColor='success'
                      chartHeight= '220px'
                      likesPercent={likesPercent}
                    />
                </Div12_Center>
                <Div12_Center className="Div12_Center">
                  <IconGrafica className="IconGrafica" src="/assets/images/grafica_Like_icon.png"></IconGrafica>
                </Div12_Center>
                <Div12_Center className="Div12_Center">
                  <SpanLabelGrafica className="SpanLabelGrafica">LIKES</SpanLabelGrafica>
                </Div12_Center>
              </Div6>
              <Div6 className="Div6">
                <Div12_Center className="Div12_Center">
                  <MixedWidget7_2
                      className='MixedWidget7_2 card-xxl-stretch mb-xl-3'
                      chartColor='success'
                      chartHeight='220px'
                      commentsPercent={commentsPercent}
                    />
                </Div12_Center>
                <Div12_Center className="Div12_Center">
                  <IconGrafica className="IconGrafica" src="/assets/images/grafica_Comment_icon.png"></IconGrafica>
                </Div12_Center>
                <Div12_Center className="Div12_Center">
                  <SpanLabelGrafica className="SpanLabelGrafica">COMMENT</SpanLabelGrafica>
                </Div12_Center>
              </Div6>
            </Div12_Center>
            <Div12_Center>
            <Div_Link>
              <Link style={{ background: "#466892", color: "#FFFFFF" }}>
                Todo
              </Link>
              <Link>Facebook</Link>
              <Link>Instagram</Link>
              <Link>Twitter</Link>
              <Link>Youtube</Link>
            </Div_Link>
            </Div12_Center>
          </GraficBox>
          {/* **************** END DONUTS **************** */}


          {/* **************** SPLINE **************** */}
          <GraficBox className='GraficBox'>
            <Div12_Center className='Div12_Center'>
              <Div10 className='Div10'>
                <ChartsWidget3 
                  className = 'ChartsWidget3 card-xl-stretch mb-xl-8'
                  dataSpline = {dataSpline}
                  categoriesSpline = {categoriesSpline}
                />
              </Div10>
            </Div12_Center>
            <Div12_Center className='Div12_Center'>
              <Link 
                style={filterSpline === 'todo' ? { background: "#466892", color: "#FFFFFF" } : null}
                onClick={(e) => {
                  setFilterSpline('todo');
                }}
              >
                Todo
              </Link>
              <Link
                style={filterSpline === 'facebook' ? { background: "#466892", color: "#FFFFFF" } : null}
                onClick={(e) => {
                  setFilterSpline('facebook');
                }}
              >
                Facebook
              </Link>
              <Link
                style={filterSpline === 'instagram' ? { background: "#466892", color: "#FFFFFF" } : null}
                onClick={(e) => {
                  setFilterSpline('instagram');
                }}
              >
                Instagram
              </Link>
              <Link
                style={filterSpline === 'twitter' ? { background: "#466892", color: "#FFFFFF" } : null}
                onClick={(e) => {
                  setFilterSpline('twitter');
                }}
              >
                Twitter
              </Link>
              <Link
                style={filterSpline === 'youtube' ? { background: "#466892", color: "#FFFFFF" } : null}
                onClick={(e) => {
                  setFilterSpline('youtube');
                }}
              >
                Youtube
              </Link>
            </Div12_Center>
          </GraficBox>
          {/* **************** END SPLINE **************** */}
        </Div13_Center>
        {/* **************** END GRÁFICAS **************** */}
  


        {/* **************** TABS PANEL **************** */}
        <Div12_Center>
          <DataBox>
            {/* **************** TABS **************** */}
            <Div12_Center>
              <StatsBox>
                <Div12_Center_Tab
                    className={tabCheck.allContributors === true ? "tab_checked" : null}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    onClick={(e) => {
                        updateTabCheck('allContributors');
                    }}
                >
                    <DivTabBtn>
                        <span>All contributors ({datosSingleCampaign.allContributors})</span>
                    </DivTabBtn>
                </Div12_Center_Tab>
              </StatsBox>
              <StatsBox>
                <Div12_Center_Tab
                    className={tabCheck.posts === true ? "tab_checked" : null}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    onClick={(e) => {
                        updateTabCheck('posts');
                    }}
                >
                    <DivTabBtn>
                        <span>Posts ({datosSingleCampaign.posts})</span>
                    </DivTabBtn>
                </Div12_Center_Tab>
              </StatsBox>
              <StatsBox>
                <Div12_Center_Tab
                    className={tabCheck.campaign === true ? "tab_checked" : null}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    onClick={(e) => {
                        updateTabCheck('campaign');
                    }}
                >
                    <DivTabBtn>
                        <span>Campaign (0)</span>
                    </DivTabBtn>
                </Div12_Center_Tab>
              </StatsBox>
              <StatsBox>
                <Div12_Center_Tab
                    className={tabCheck.stories === true ? "tab_checked" : null}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    onClick={(e) => {
                        updateTabCheck('stories');
                    }}
                >
                    <DivTabBtn>
                        <span>Stories ({datosSingleCampaign.storiesTotal})</span>
                    </DivTabBtn>
                </Div12_Center_Tab>
              </StatsBox>
              <StatsBox>
                <Div12_Center_Tab
                    className={tabCheck.comentarios === true ? "tab_checked" : null}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    onClick={(e) => {
                        updateTabCheck('comentarios');
                    }}
                >
                    <DivTabBtn>
                        <span>Comentarios ({datosSingleCampaign.comments})</span>
                    </DivTabBtn>
                </Div12_Center_Tab>
              </StatsBox>
              <StatsBox>
                <Div12_Center_Tab
                    className={tabCheck.menciones === true ? "tab_checked" : null}
                    style={{ paddingBottom: "5px", paddingTop: "10px" }}
                    onClick={(e) => {
                        updateTabCheck('menciones');
                    }}
                >
                    <DivTabBtn>
                        <span>Menciones (0)</span>
                    </DivTabBtn>
                </Div12_Center_Tab>
              </StatsBox>
            </Div12_Center>
            {/* **************** END TABS **************** */}


            {/* **************** ALL CONTRIBUTORS **************** */}
            {tabCheck.allContributors === true && datosUsernames!==undefined  && datosUsernames.length >0
              ?
                (
                  <div key={`all_contributor_div${postPorUsernameUpdates}`}>
                    {datosUsernames.map((item, i) => (
                      <Div12_Center key={i}>
                        <UserRow 
                        item={item} 
                        obtenerPostsUser={obtenerPostsUser}
                        postPorUsername={postPorUsername}
                        client={client}
                        dispatch={dispatch}
                        deleteMentionedRequesting={deleteMentionedRequesting}
                        clickPaginacionUsernames={clickPaginacionObtenerPostUsername}
                        />
                            
                      </Div12_Center>
                    )) }
                  </div>
                )
              : null}

            {tabCheck.allContributors === true && datosUsernames!==undefined  && datosUsernames.length >0 
              ? 
              usernamesStorage.hasOwnProperty('hydra:view') && usernamesStorage['hydra:view'].hasOwnProperty('@id')? 
                      <PaginadorRow 
                          paginacion={usernamesStorage['hydra:view']}
                          callback_click={clickPaginacionUsernames}
                      />
                  : null
              : null}
            {/* **************** END ALL CONTRIBUTORS **************** */}


            {/* **************** POSTS **************** */}
            {tabCheck.posts === true && datosMentionedMedia!==undefined  && datosMentionedMedia.length >0 
              ? 
              (
              datosMentionedMedia.map((item, i) => (
                  <Div12_Center key={i}>
                    <PostRow 
                      item={item}
                      client={client}
                      dispatch={dispatch}
                      deleteMentionedRequesting={deleteMentionedRequesting}
                    />
                  </Div12_Center>
                ))
                 //Añado la paginacion
                 
              )
              : null}

            {tabCheck.posts === true && datosMentionedMedia!==undefined  && datosMentionedMedia.length >0 
              ? 
              mentionedMediaStorage.hasOwnProperty('hydra:view') && mentionedMediaStorage['hydra:view'].hasOwnProperty('@id')? 
                      <PaginadorRow 
                          paginacion={mentionedMediaStorage['hydra:view']}
                          callback_click={clickPaginacionMentionedMedia}
                      />
                  : null
              : null}
            {/* **************** END POSTS **************** */}


            {/* **************** STORIES **************** */}
            {tabCheck.stories === true && datosStories!==undefined  && datosStories.length >0 
              ? 
              (
                datosStories.map((item, i) => (
                  
                    <StoryRow 
                      item={item}
                      client={client}
                      dispatch={dispatch}
                      deleteMentionedRequesting={deleteMentionedRequesting}
                    />
                  
                ))
                 //Añado la paginacion
                 
              )
              : null}

            {tabCheck.stories === true && datosStories!==undefined  && datosStories.length >0 
              ? 
              storiesStorage.hasOwnProperty('hydra:view') && storiesStorage['hydra:view'].hasOwnProperty('@id')? 
                <Div12_Center>
                      <PaginadorRow 
                          paginacion={storiesStorage['hydra:view']}
                          callback_click={clickPaginacionStories}
                      />
                </Div12_Center>
                  : null
              : null}
            {/* **************** END STORIES **************** */}
          </DataBox>
        </Div12_Center>
        {/* **************** END TABS PANEL **************** */}
      </DivGroupCamp>
    </DivMainCamp>
  );
};

export default DetalleCampaign;
