import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from 'react-redux';
import {Elements, CardElement, useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import {solicitandoPago,recibidoPlanOK,recibidoPlanKO,procesarPago} from '../../planes_comprar/actions';

const BotonPagar = styled.button`
    /* Button / Big / Aplicar

    btn_verde_disabled
    */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 15px;

    position: absolute;
    left: 69.21%;
    right: 15.86%;
    
    bottom: 18.46%;

    /* Green */

    background: #9AE6B4;
    border: 1px solid #9AE6B4;
    box-sizing: border-box;
    border-radius: 33px;

    cursor: pointer;
`;

const SpanPagar = styled.span`
    /* Aplicar */

    position: static;
    
    left: calc(50% - 185px/2);
    top: 26.32%;
    bottom: 26.32%;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 18px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
`;

const DivInput = styled.div`
    /* _input */

    /* Auto layout */

    
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 10px 12px;

    position: static;
    height: 43px;

    /* Mono/White */

    background: #FFFFFF;
    /* Grey/Border */

    border: 2px solid #E0E0E0;
    box-sizing: border-box;
    /* Inputs */

    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.07);
    border-radius: 6px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 4px 0px;
`;

const Div9 = styled.div`
  width: 80%;
  float: left;
  padding-top: 5px;
`;

const Div6 = styled.div`
  width: 40%;
  float: left;
  padding-top: 5px;
`;

const Col_12_Center = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC}`);

const CheckoutForm = () =>{
    const client = useSelector(state=> state.client);
    const estado_request_pago = useSelector(state=> state.plan);
       
    const stripe = useStripe();
    const elements= useElements();
    const {plan, periodicidad} = useParams();
    const dispatch = useDispatch();
    
    const enviarFormularioCompra = async (e) =>{
        e.preventDefault();
        dispatch(solicitandoPago());

        const latestInvoicePaymentIntentStatus = localStorage.getItem(
            'latestInvoicePaymentIntentStatus'
        );

        const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement)
        });
        if (!error){
            let invoiceId="";
            if (latestInvoicePaymentIntentStatus === 'requires_payment_method') {
                let invoiceId = localStorage.getItem('latestInvoiceId');
                  
            }else{
                
            }
            // console.log(paymentMethod);
            //hay que enviar el paymentMethod.id al servidor
            const {id } = paymentMethod;
            
            //Enviamos en función de si tiene o no cupón validado
            if (estado_request_pago.cupon.id!==null){
                dispatch(procesarPago(JSON.stringify({'paymentMethodId': id, 'invoiceId':invoiceId, 'plan': plan, 'periodicidad': periodicidad, 'cupon': estado_request_pago.cupon.id}), client));
            }else{
                dispatch(procesarPago(JSON.stringify({'paymentMethodId': id, 'invoiceId':invoiceId, 'plan': plan, 'periodicidad': periodicidad}), client));

            }
            

        }else{
            console.log(error);
        }
    }


    return (
        <form onSubmit={enviarFormularioCompra} style={{width: '100%'}}>
            {estado_request_pago.error === true ?
             <Col_12_Center className="Col_12_Center error">{estado_request_pago.messages}</Col_12_Center>
        : null }
            <Col_12_Center className="Col_12_Center" style={{paddingTop: '35px'}}>
                <Div9 className="Div9">
                    <label>
                        Card number
                    </label>
                </Div9>
            </Col_12_Center>
            <Col_12_Center>
                <Div9 className="Div9">
                    <DivInput className="DivInput">
                        <CardNumberElement/>
                    </DivInput>
                </Div9>
            </Col_12_Center>

            <Col_12_Center className="Col_12_Center" style={{paddingTop: '15px'}}>
                <Div6 className="Div6">
                    <label>
                        Expiry
                    </label>
                </Div6>
                <Div6 className="Div6">
                    <label>
                        CVC
                    </label>
                </Div6>
            </Col_12_Center>
            <Col_12_Center>
                <Div6 className="Div6" style={{paddingRight: '30px', width: '35%'}}>
                    <DivInput className="DivInput">
                        <CardExpiryElement/>
                    </DivInput>
                </Div6>
                <Div6 className="Div6">
                    <DivInput className="DivInput">
                        <CardCvcElement/>
                    </DivInput>
                </Div6>
            </Col_12_Center>
            
                        
            <BotonPagar type="submit" disabled={estado_request_pago.requestingPlan === true? 'disabled' : ''}>
            {estado_request_pago.requestingPlan !== true?
                <SpanPagar >Procesar Pago</SpanPagar>
                : 
                <SpanPagar >Procesando</SpanPagar>
                }

            </BotonPagar> 
        </form>
    );

}

const PagoStripe = () => {

    
    const client = useSelector(state=> state.client);
        
     
    return (  
        <Elements stripe={stripePromise}>
            {/* <h1>Pago Stripe</h1> */}
            <CheckoutForm/>
        </Elements>
    );
}
 
export default PagoStripe;