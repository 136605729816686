import React, { Component, Fragment, PropTypes } from 'react'
import { Form, Field } from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import { Link, Navigate } from 'react-router-dom'
import styled from '@emotion/styled';

import Messages from '../notifications/Messages'
import Errors from '../notifications/Errors'

import loginRequest from './actions'

import LoginForm from '../componentes/formularios/loginForm';
import MenuSuperior from "../componentes/menuSuperior";



const LoginRight=styled.div`
  background: #f5f5f5 url('/assets/images/welcome.png') center center no-repeat;
  vertical-align: top;
  display: table-cell;
      @media (max-width:500px) {
        display: none%;
      }
  `
  const DivResponsive = styled.div`
  @media (max-width:500px) {
    margin: 0px 0px 0px -20px;
  }
   
`
const NoTengoCuenta=styled.p`
  margin: 88px 2px 20px 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #224957;
  & .text-style-2 {
    font-weight: bold;
    color: #2d3748;
  }
`
const ContinuarLoginSocial=styled.div`
  position: relative;
  margin-top:40px;
    & .linea{
    width: 100%;
      height: 1px;
      flex-grow: 0;
      margin: 32.5px 0 31.5px 5px;
      padding: 0.5px 0;
      transform: rotate(-360deg);
      background-color: #2c5282;
      text-align:center;  
      display: block;
      position: absolute;
      top: 0px;
      margin: 10px;
      z-index: 1;
    }
    & .texto-continuar{
      align-self: stretch;
      flex-grow: 1;
      font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #444b59;
      position: relative;
      z-index:2;
      background: #fff;
      margin: 0px auto;
      display: block;
      width: 150px;
    }
    padding-bottom: 50px;
    clear:both;
  `
  const LoginSocialDiv=styled.div`
    margin: 0px auto;
    margin-bottom:20px;
    text-align:center;
  `

const Login = () => {

  const login = useSelector(state => state.login);
  const client = useSelector(state=> state.client);


  const dispatch = useDispatch();
  // Remember, Redux Form passes the form values to our handler
  // In this case it will be an object with `email` and `password`
  const submit = (values) => {
    dispatch(loginRequest(values));
  }

  let {requesting, successful, messages, errors } = login;

  
    return (
      <Fragment>
        {
                successful ? (
                
                  client.decode.roles.includes('ROLE_INFLUENCER') ? 
                  <Navigate to={{ pathname: '/dashboard_influencer', state: ''}} />
                  :
                <Navigate to={{ pathname: '/dashboard', state: ''}} />
                
                ) : null
        }
        <MenuSuperior
          showDerecha= "false"
          marginBottom="No"
          />

      <DivResponsive className="col-md-12 div-table">
        <DivResponsive className="col-md-6 inline-block col-table">
          <p className="welcome">Welcome Back!</p>
          <div className="col-md-6 mauto">
          {!requesting && !successful && (
          <NoTengoCuenta>
            No tengo cuenta.&nbsp;
           <Link to="/register"><span className="text-style-2">Registrar</span></Link>
          </NoTengoCuenta>
          )}
          </div>
          <LoginForm/>
          <div className="col-md-6 mauto">
          <ContinuarLoginSocial style={{display: "none"}}>
            <span className="linea"></span><span className="texto-continuar">o continuar con</span>
          </ContinuarLoginSocial>

          <LoginSocialDiv  style={{display: "none"}}><img src="/assets/images/SignGoogle.png"/></LoginSocialDiv>
          <LoginSocialDiv  style={{display: "none"}}><img src="/assets/images/SignFacebook.png"/></LoginSocialDiv>
          </div>
          
        </DivResponsive>
        <LoginRight className="col-md-6 hideMovil">

        </LoginRight>
      </DivResponsive>
      </Fragment>
    )
          }
/*
// Grab only the piece of state we need
const mapStateToProps = state => ({
  login: state.login,
})

// make Redux state piece of `login` and our action `loginRequest`
// available in this.props within our component
const connected = connect(mapStateToProps, { loginRequest })(Login)

// in our Redux's state, this form will be available in 'form.login'
const formed = reduxForm({
  form: 'login',
})(connected)

// Export our well formed login component
export default formed
*/
export default Login