import { Form, Field } from 'react-final-form';
import signupRequest from '../../registro/actions';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import { Link, Navigate } from 'react-router-dom'
import {Fragment} from 'react';


const NoTengoCuenta=styled.p`
  margin: 15px 2px 30px 9px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #224957;
  & .text-style-2 {
    font-weight: bold;
    color: #2d3748;
  }
`

const ErrorCampo = styled.span`
    font-family: Roboto;
      font-size: 16px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #c91a4f;
      margin-left:20px;
`
const RegistroForm = ({tipo}) => {
    const dispatch = useDispatch();

    const signup = useSelector(state => state.signup);
    let {requesting,
      successful,
      messages,
      errors} = signup;
  
    const onSubmit = (values) =>{
      values = {...values, tipo: tipo};
      dispatch(signupRequest(values));
    };

    const required = value => (value ? undefined : 'Required')
    const mustBeNumber = value => (isNaN(value) ? 'Must be a number' : undefined)
    const minValue = min => value =>
      isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`
    const composeValidators = (...validators) => value =>
      validators.reduce((error, validator) => error || validator(value), undefined)
    

    const validate = (values) =>{
        const errors = {}
        if (!values.email) {
          errors.email = 'Required'
        }
        if (!values.password) {
          errors.password = 'Required'
        }
        if (!values.repassword) {
          errors.repassword = 'Required'
        } else if (values.repassword !== values.password) {
          errors.repassword = 'Must match'
        }
        return errors
    };

    return ( 
      <div className="col-md-6 mauto">
        <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
              <Field
                name="email"
                type="text"
                validate={required}
              >
                {({ input, meta }) => (
                  <Fragment>
                      <input {...input} className={meta.error && meta.touched ? 'email input_text_1 w100 error_field' : 'email input_text_1 w100' }  placeholder="Email"  id="email"
                  />
                      {meta.error && meta.touched && <ErrorCampo>{meta.error}</ErrorCampo>}
                      </Fragment>
                  )}
              </Field>
                
          <Field
            name="password"
            type="password"
            validate={required}
            >
              {({ input, meta }) => (
                <Fragment>
                    <input {...input} className={meta.error && meta.touched ? 'password input_text_1 w100 error_field' : 'password input_text_1 w100' }   id="password"
                        placeholder="Password"
                />
                    {meta.error && meta.touched && <ErrorCampo>{meta.error}</ErrorCampo>}
                    </Fragment>
                )}
            </Field>
              
          <Field
            name="repassword"
            type="password"
            validate={required}
            >
              {({ input, meta }) => (
                <Fragment>
                    <input {...input} className={meta.error && meta.touched ? 'password input_text_1 w100 error_field' : 'password input_text_1 w100' }   id="repassword"
                        placeholder="Reenter Password"
                />
                    {meta.error && meta.touched && <ErrorCampo>{meta.error}</ErrorCampo>}
                    </Fragment>
                )}
            </Field>
      
        {!requesting && !successful && (
          <NoTengoCuenta>
            Ya tengo cuenta.&nbsp;
           <Link to="/"><span className="text-style-2">Login</span></Link>
          </NoTengoCuenta>
          )}
           {!requesting && successful && (
            <div>
              Signup Successful! <Link to="/login">Click here to Login »</Link>
            </div>
          )}

        {!requesting ?
              <button action="submit" className="submit_verder w100">Acceder</button>
          :         <button action="submit" className="submit_verder w100" disabled>Registrando ...</button>
        }  
          </form>
        
        )}
      />
      </div>
    )
}

export default RegistroForm;