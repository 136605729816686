import {
  VERIFY_REQUESTING,
  VERIFY_SUCCESS,
  VERIFY_ERROR,  
  RESEND_VERIFY_REQUESTING,
  RESEND_VERIFY_SUCCESS,
  RESEND_VERIFY_ERROR,
} from './constants'

const initialState = {
  requesting: false,
  successful: false,
  messages: [],
  errors: [],
  requesting_reenvio: false,
  successful_reenvio: false,
  errors_reenvio: [],
}

const reducer = function doblefactorReducer (state = initialState, action) {
  switch (action.type) {
    case VERIFY_REQUESTING:
      return {
        requesting: true,
        successful: false,
        messages: [{ body: 'Verificando...', time: new Date() }],
        errors: [],
      }

    case VERIFY_SUCCESS:
      return {
        errors: [],
        messages: [{
          body: `Verificación correcta`,
          time: new Date(),
        }],
        requesting: false,
        successful: true,
      }

    case VERIFY_ERROR:
      return {
        errors: state.errors.concat([{
          body: action.error.toString(),
          time: new Date(),
        }]),
        messages: [],
        requesting: false,
        successful: false,
      }
    case RESEND_VERIFY_REQUESTING:
        return {
          requesting_reenvio: true,
          successful_reenvio: false,
          errors_reenvio: [],
        }
  
      case RESEND_VERIFY_SUCCESS:
        return {
          errors_reenvio: [],
          requesting_reenvio: false,
          successful_reenvio: true,
        }
  
      case RESEND_VERIFY_ERROR:
        return {
          errors_reenvio: state.errors.concat([{
            body: action.error.toString(),
            time: new Date(),
          }]),
          requesting_reenvio: false,
          successful_reenvio: false,
        }
    default:
      return state
  }
}

export default reducer
