import React, { Fragment, useEffect, useState } from "react";
import styled from 'styled-components';
import dateFormat from "dateformat";
import { handleApiErrors } from '../lib/api-errors'

const ImgUser= styled.div`
        width: 44px;
        height: 44px;
        flex-grow: 0;
        margin: 0 15px 0 0;
        background-color: #c4c4c4;
        border:1px solid #c4c4c4;
        border-radius: 50%;
        display: inline-block;
    `;
    const NombreUserPost= styled.span`
        flex-grow: 0;
        margin: 2px 0 1px 15px;
        font-family: Roboto;
        font-size: 16px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        display: inline-block;
        vertical-align: top;

        `
    const BloqueColPostResultRow=styled.div`
        display: inline-block;
        vertical-align:top;
        flex-grow: 0;
        margin: 0 14px 0 0;
        padding: 8.2px 13px 8.6px 18px;
        border-radius: 10px;
        background-color: #fff;
        font-size: 13px;

    `;

    const FechaUserPost=styled.div`
        flex-grow: 0;
        margin: 1px 0px 6px 15px;
        font-family: Roboto;
        font-size: 12px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        text-align: left;
        color: rgba(0, 0, 0, 0.2);
  `;
  const PostResultRowLikes=styled.span`
        flex-grow: 0;
        font-family: Roboto;
        font-size: 16px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: #2a4365;
        strong{
            width:100%;
            clear:both;
            display: block;
        }
  `
  const PostResultRow = styled.div`
        width: calc(100% - 50px);
        padding: 5px 27px;
    `;

  const DivPostRow = styled.div`
        width: 98%;
        height: auto;
        left: 22px;
        top: 640px;
        
        background: #E8F1F8;
        box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.25);
        border-radius: 10px;

        margin-top: 20px;
        display: block;
    `;

    /* ******************* ICONS ******************* */
    const Icon_RRSS = styled.img`
        width: 30px;
        padding-top: 5px;
    `;

    const IconRightMenu = styled.img`
        width: 20px;
        padding-top: 5px;
        cursor: pointer;
    `;

    const Icon_Likes = styled.span`
        padding-top: 5px;
        background-image: url(./assets/images/logos_group.png);
        width: 20px;
        height: 16px;
        background-position: 0 0;
        display: inline-block;
    `;

    const Icon_Comentarios = styled.span`
        padding-top: 5px;
        background-image: url(./assets/images/logos_group.png);
        width: 20px;
        height: 16px;
        background-position: -60px 0;
        display: inline-block;
    `;

    const Icon_Retweet = styled.span`
        padding-top: 5px;
        background-image: url(./assets/images/logos_group.png);
        width: 20px;
        height: 16px;
        background-position: -116px 0;
        display: inline-block;
    `;

    const Icon_Interacciones = styled.span`
        padding-top: 5px;
        background-image: url(./assets/images/logos_group.png);
        width: 20px;
        height: 16px;
        background-position: -177px 0;
        display: inline-block;
    `;

    const Icon_Engagement = styled.span`
        padding-top: 5px;
        background-image: url(./assets/images/logos_group.png);
        width: 20px;
        height: 16px;
        background-position: 73px 0;
        display: inline-block;
    `;

    const Icon_EMV = styled.span`
        padding-top: 5px;
        background-image: url(./assets/images/logos_group.png);
        width: 13px;
        height: 16px;
        background-position: 13px 0;
        display: inline-block;
    `;
    /* ******************* END ICONS ******************* */

    const Div12 = styled.div`
        width: 100%;
        display: block;
    `;

    const Div12_Center = styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
    `;

    const Div1 = styled.div`
        float: left;
        padding-left: 30px;
    `;

    const Div3 = styled.div`
        width: 20%;
        float: left;
        padding-top: 5px;
    `;

    const Div7 = styled.div`
        width: 70%;
        float: left;
        padding-top: 5px;
        padding-bottom: 10px;
    `;

    const Div9 = styled.div`
        width: 70%;
        float: left;

    `;

    const Div10 = styled.div`
        width: 80%;
        float: left;

    `;

    const Span_stats = styled.span`
        font-size: 12px;
    `;


const PostRow = ({
    item,
    client,
    dispatch,
    deleteMentionedRequesting,
}) => {

    /* ***************** STATES ***************** */
    const [postId, setPostId] = useState(item.id);
    /* ***************** END STATES ***************** */


    /* ***************** CUSTOM FUNCTIONS ***************** */
    const updatePost = () => {
        // let item = {eliminado: true};
        
        // fetch(`${process.env.REACT_APP_API_URL}/api/mentioned_media/${postId}`, {
        //     method: 'PUT',
        //     headers: {
        //         'Content-Type': 'application/ld+json',
        //         Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
        //       },
        //     body: JSON.stringify(item)
        // })
        // .then((result) => {
        //     result.json().then((resp) => {
                
        //     })
        // })

        dispatch(deleteMentionedRequesting(client, postId));
    }
    /* ***************** END CUSTOM FUNCTIONS ***************** */

    return (
        <DivPostRow className="DivPostRow">
            <PostResultRow className="PostResultRow">
                <Div12 className="Div12">
                    <Div3 className="Div3">
                        <Div12 className="Div12">
                            <NombreUserPost className="autologiaonline NombreUserPost">
                                {item.username.username}
                            </NombreUserPost>
                            <FechaUserPost className="FechaUserPost">{dateFormat(item.timestamp, "dd/mm/yyyy")}</FechaUserPost>
                        </Div12>
                        <Div12_Center className="Div12_Center">
                            {item.tipo === 'twitter' ?
                                <Icon_RRSS className="Icon_RRSS" src="/assets/images/twitter-logo.png"></Icon_RRSS>
                            :
                                null
                            }

                            {item.tipo === 'instagram' ?
                                <Icon_RRSS className="Icon_RRSS" src="/assets/images/instagram-logo.png"></Icon_RRSS>
                            :
                                null
                            }

                            {item.tipo === 'facebook' ?
                                <Icon_RRSS className="Icon_RRSS" src="/assets/images/fb-logo.png"></Icon_RRSS>
                            :
                                null
                            }

                            {item.tipo === 'youtube' ?
                                <Icon_RRSS className="Icon_RRSS" src="/assets/images/youtube-logo.png"></Icon_RRSS>
                            :
                                null
                            }
                        </Div12_Center>
                    </Div3>
                    <Div7 className="Div7">
                        <Div12 className="Div12">
                            <BloqueColPostResultRow className="BloqueColPostResultRow">
                                {item.caption}
                            </BloqueColPostResultRow>
                        </Div12>
                        <Div12 className="Div12">
                            <Div1 className="Div1">
                                <Div12 className="Div12">
                                    <Icon_Likes id="likes" className="Icon_Likes"></Icon_Likes>
                                </Div12>
                                <Div12 className="Div12">
                                    <Span_stats className="Span_stats"><b>{item.like_count}</b> Likes</Span_stats>
                                </Div12>
                            </Div1>
                            <Div1 className="Div1">
                                <Div12 className="Div12">
                                    <Icon_Comentarios className="Icon_Comentarios"></Icon_Comentarios>
                                </Div12>
                                <Div12 className="Div12">
                                    <Span_stats><b>{item.comments_count}</b> Comentarios</Span_stats>
                                </Div12>
                            </Div1>
                            <Div1 className="Div1">
                                <Div12 className="Div12">
                                    <Icon_Retweet className="Icon_Retweet"></Icon_Retweet>
                                </Div12>
                                <Div12 className="Div12">
                                    <Span_stats className="Span_stats"><b>{item.twitter_retweet}</b> Retweet</Span_stats>
                                </Div12>
                            </Div1>
                            <Div1 className="Div1">
                                <Div12 className="Div12">
                                    <Icon_Interacciones className="Icon_Interacciones"></Icon_Interacciones>
                                </Div12>
                                <Div12 className="Div12">
                                    <Span_stats className="Span_stats"><b>{item.like_count}</b> Interacciones</Span_stats>
                                </Div12>
                            </Div1>
                            <Div1 className="Div1">
                                <Div12 className="Div12">
                                    <Icon_Engagement className="Icon_Engagement"></Icon_Engagement>
                                </Div12>
                                <Div12 className="Div12">
                                    <Span_stats className="Span_stats"><b>{item.like_count}%</b> Engagement</Span_stats>
                                </Div12>
                            </Div1>
                            <Div1 className="Div1">
                                <Div12 className="Div12">
                                    <Icon_EMV className="Icon_EMV"></Icon_EMV>
                                </Div12>
                                <Div12 className="Div12">
                                    <Span_stats className="Span_stats"><b>{item.emv}€</b> EMV</Span_stats>
                                </Div12>
                            </Div1>
                        </Div12>
                    </Div7>
                    <Div1 className="Div1">
                        <Div12_Center className="Div12_Center" style={{paddingTop: '5px'}}>
                            <Div1 className="Div1">
                                <a href={item.permalink} target="blank">
                                    <IconRightMenu className="IconRightMenu" src="/assets/images/eye_icon.png"></IconRightMenu>
                                </a>
                            </Div1>
                        </Div12_Center>
                        <Div12_Center className="Div12_Center" style={{paddingTop: '10px', display:"none"}}>
                            <Div1 className="Div1">
                                <IconRightMenu className="IconRightMenu" src="/assets/images/menu_icon.png"></IconRightMenu>
                            </Div1>
                        </Div12_Center>
                        <Div12_Center className="Div12_Center" style={{paddingTop: '10px'}}>
                            <Div1 className="Div1">
                                <a 
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        updatePost();
                                    }}
                                >
                                    <IconRightMenu className="IconRightMenu" src="/assets/images/trash_icon.png"></IconRightMenu>
                                </a>
                            </Div1>
                        </Div12_Center>
                    </Div1>
                </Div12>
            </PostResultRow>
        </DivPostRow>
    );
};

export default PostRow;
