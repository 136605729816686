import { Form, Field } from 'react-final-form';
import verifyRequest from '../../doblefactor/actions';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';

//Estilos responsive
const DIV = styled.div`
border:solid;
width: 50%;

  @media (max-width:500px) {
    padding: 0px 0px 5px 3px;
    width: 100%;
  }
`

const Button = styled.button`
  @media (max-width:500px) {
    margin: 0px 0px 0px -10px;
  }
`
const FieldResponsive = styled.div`
  padding: 0px 0px 0px 50px;
    @media (max-width:500px) {
      padding: 0px 0px 0px 0px;
    }
`

const DivResponsive= styled.div`
   float: right;
   @media (max-width:500px) {
    margin: 10px 0px 10px 0px;
  }
  
`
const DobleFactorForm = ({tipo}) => {
    const client = useSelector(state=> state.client);
    const error = useSelector(state=> state.verificacion.errors);
    console.log(error);
    const dispatch = useDispatch();
    const onSubmit = (codigo) =>{
      dispatch(verifyRequest({"codigo": `${codigo.codigo1}${codigo.codigo2}${codigo.codigo3}${codigo.codigo4}${codigo.codigo5}${codigo.codigo6}`    }, client));
    };
    const validate = () =>{

    };

    return ( 
      <div className="col-md-6 mauto">
       
        <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
           <FieldResponsive>
          <Field
            name="codigo1"
            type="number"
            id="codigo1"
            className="codigo input_text_1 w100"
            component="input"
            onKeyUp={(e) => {document.querySelector(`input[name=codigo2]`).focus()}}
          />
           <Field
            name="codigo2"
            type="number"
            id="codigo2"
            className="codigo input_text_1 w100"
            component="input"
            onKeyUp={(e) => {document.querySelector(`input[name=codigo3]`).focus()}}
          />
           <Field
            name="codigo3"
            type="number"
            id="codigo3"
            className="codigo input_text_1 w100"
            component="input"
            onKeyUp={(e) => {document.querySelector(`input[name=codigo4]`).focus()}}
          />
        <DivResponsive>
           <Field
            name="codigo4"
            type="number"
            id="codigo4"
            className="codigo input_text_1 w100"
            component="input"
            onKeyUp={(e) => {document.querySelector(`input[name=codigo5]`).focus()}}
          />
           <Field
            name="codigo5"
            type="number"
            id="codigo5"
            className="codigo input_text_1 w100"
            component="input"
            onKeyUp={(e) => {document.querySelector(`input[name=codigo6]`).focus()}}
          />
           <Field
            name="codigo6"
            type="number"
            id="codigo6"
            className="codigo input_text_1 w100"
            component="input"
          />
          </DivResponsive>
       </FieldResponsive> 
          <Button action="submit" className="submit_verder w100">Enviar</Button>
          </form>
        
        )}
      />
    
      {
        error!==undefined? error.map(err => 
          <span className="error">{err.body}</span>
        ) : null
      }
      </div>
    )
}

export default DobleFactorForm;