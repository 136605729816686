import {
  PLANES_REQUESTING,
  PLANES_SUCCESS,
  PLANES_ERROR,
  CUPON_DESCUENTO_REQUESTING,
  CUPON_DESCUENTO_SUCCESS,
  CUPON_DESCUENTO_ERROR
} from './constants'

const initialState = {
  requestingPlan: false,
  successfulPlan: false,
  messages: null,
  error: false,
  cupon: {
    requesting: false,
    success: false,
    id: null,
    error: null
  }

}

const reducer = function planesReducer (state = initialState, action) {
  switch (action.type) {
    // Set the requesting flag and append a message to be shown
    case CUPON_DESCUENTO_REQUESTING:
      return{
        ...state,
        cupon: {
          requesting: true,
          success: false,
          id: null,
          error: null
        }
      }
    case CUPON_DESCUENTO_ERROR:
      return{
        ...state,
        cupon: {
          requesting: false,
          success: false,
          id: null,
          error: action.error
        }
      }
    case CUPON_DESCUENTO_SUCCESS:
      return{
        ...state,
        cupon: {
          requesting: false,
          success: true,
          id: action.cuponid,
          error: null
        }
      }
    case PLANES_REQUESTING:
      return {
        ...state,
        requestingPlan: true,
        successfulPlan: false,
        messages: null,
        error: false
       
      }

    // Successful?  Reset the login state.
    case PLANES_SUCCESS:
      return {
        ...state,
        messages: null,
        requestingPlan: false,
        successfulPlan: true,
        error: false
      }

    // Append the error returned from our api
    // set the success and requesting flags to false
    case PLANES_ERROR:
      return {
        ...state,
        messages: action.error,
        requestingPlan: false,
        successfulPlan: false,
        error: true
      }

    default:
      return state
  }
}

export default reducer
