import { call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'react-router-redux';
import { handleApiErrors } from '../lib/api-errors'
import {
  VERIFY_REQUESTING,
  VERIFY_SUCCESS,
  VERIFY_ERROR,
  RESEND_VERIFY_REQUESTING,
  RESEND_VERIFY_SUCCESS,
  RESEND_VERIFY_ERROR,
} from './constants'

// The url derived from our .env file
const verificacionURL = `${process.env.REACT_APP_API_URL}/api/verify-user`
const resendVerificacionURL = `${process.env.REACT_APP_API_URL}/api/resend-verify-user`


function resendVerifyApi (client){
  return fetch(resendVerificacionURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    }
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
       return res;
    })
    .catch((error) => { 
      throw error })
}

function verifyApi (codigo, client) {
  return fetch(verificacionURL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/ld+json',
      Authorization: 'Bearer ' +client.token.token || undefined, // will throw an error if no login
    },
    body: JSON.stringify({ 
      'verifycode' : codigo
    
    }),
  })
    .then(handleApiErrors) // we'll make this in a second
    .then(response => response.json())
    .then(json => json)
    .then(res =>{
       return res;
    })
    .catch((error) => { 
      throw error })
}

function* sucessVerificarFlow (action){
  yield put(push('/dashboard'));
  document.location='/dashboard';
  
}

// This will be run when the VERIFY_REQUESTING
// Action is found by the watcher
function* verificarFlow (action) {
  try {
    const { codigo, client } = action
    // console.log(tipo);
    // pulls "calls" to our signupApi with our email and password
    // from our dispatched signup action, and will PAUSE
    // here until the API async function, is complete!
    const response = yield call(verifyApi, codigo, client)
    if (response.verify === true){
      yield put({ type: VERIFY_SUCCESS, response })
    }else{
      if (response.error==='codigo_no_valido'){
        yield put({ type: VERIFY_ERROR, error: 'El código introducido no es válido' })
      }
    }

  } catch (error) {
    // if the api call fails, it will "put" the VERIFY_ERROR
    // into the dispatch along with the error.
    yield put({ type: VERIFY_ERROR, error })
  }
}


function* resendCodigoFlow (action) {
  try {
    const { client } = action
    const response = yield call(resendVerifyApi, client)
   
    if (response.enviado === "success"){
      yield put({ type: RESEND_VERIFY_SUCCESS, response })
    }

  } catch (error) {
    // if the api call fails, it will "put" the VERIFY_ERROR
    // into the dispatch along with the error.
    yield put({ type: RESEND_VERIFY_ERROR, error })
  }
}

// Watches for the VERIFY_REQUESTING action type
// When it gets it, it will call verificarFlow()
// WITH the action we dispatched
function* doblefactorWatcher () {
  // takeLatest() takes the LATEST call of that action and runs it
  // if we we're to use takeEvery, it would take every single
  // one of the actions and kick off a new task to handle it
  // CONCURRENTLY!!!
  yield takeLatest(VERIFY_REQUESTING, verificarFlow)
  yield takeLatest(VERIFY_SUCCESS, sucessVerificarFlow)

  yield takeLatest(RESEND_VERIFY_REQUESTING, resendCodigoFlow)

}

export default doblefactorWatcher
