import { Form, Field } from 'react-final-form';
import loginRequest from '../../login/actions';
import {useDispatch, useSelector} from 'react-redux';
import styled from '@emotion/styled';
import { Link } from "react-router-dom";
import { Fragment } from 'react';
const OlvidastePassword= styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #224957;`;

const ErrorLogin = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #c91a4f;`
const ErrorCampo = styled.span`
font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #c91a4f;
  margin-left:20px;
`
const LoginForm = () => {
    const dispatch = useDispatch();
    const onSubmit = (values) =>{
      dispatch(loginRequest(values));
    };
    const validate = () =>{

    };
    const handleSubmit = (e) =>{
      e.preventDefault();
    }
    const login = useSelector(state => state.login);
    let {requesting, successful, messages, errors } = login;
    const required = value => (value ? undefined : 'Required')
    

    return ( 
      <div className="col-md-6 mauto">
        <Form
        onSubmit={onSubmit}
        validate={validate}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
          <Field
            name="email"
            type="text"
            validate={required}
            >
            {({ input, meta }) => (
              <Fragment>
                  <input {...input} className={meta.error && meta.touched ? 'email input_text_1 w100 error_field' : 'email input_text_1 w100' }  placeholder="Email"  id="email"
              />
                  {meta.error && meta.touched && <ErrorCampo>{meta.error}</ErrorCampo>}
                  </Fragment>
              )}
          </Field>
          

          <Field
            name="password"
            type="password"
            validate={required}
            >
              {({ input, meta }) => (
                <Fragment>
                    <input {...input} className={meta.error && meta.touched ? 'password input_text_1 w100 error_field' : 'password input_text_1 w100' }   id="password"
                        placeholder="Password"
                />
                    {meta.error && meta.touched && <ErrorCampo>{meta.error}</ErrorCampo>}
                    </Fragment>
                )}
            </Field>

            <Link to="/password-reset"><OlvidastePassword>¿Olvidaste la <strong>contraseña</strong>?</OlvidastePassword></Link>
          


          <div className="auth-messages">
            {/* As in the signup, we're just using the message and error helpers */}
            {!requesting && !!errors.length && (
              <ErrorLogin>Los datos de acceso no son correctos.</ErrorLogin>
            )}
             {requesting ? <div>Logging in...</div>: <button action="submit" className="submit_verder w100">Acceder</button>}
            
          </div>

          
          </form>
        
        )}
      />
      </div>
    )
}
 
export default LoginForm;