import React, { Component, Fragment, PropTypes, useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import {useDispatch, useSelector} from 'react-redux';
import { Link, Navigate } from 'react-router-dom'
import styled from '@emotion/styled';
import {updateCuentasClient} from '../client/actions'
import FacebookLogin from 'react-facebook-login';
import MenuSuperior from "../componentes/menuSuperior";
import MenuLateral from '../componentes/menuLateral';
import CuentaSubscripcion from './cuentaSubscripcion';

import Cuentas from './cuentas';

//Estilo boton para el menu responsive
const Button = styled.button`
    display: none;

    @media (max-width:500px){
        display: flex;
        position: fixed;
        z-index: 6;
    }

`
//
const Div = styled.div`
  @media (max-width:500px){
    display:none;
    z-index: 1;
  }
` 

const DivContenedores = styled.div`
    @media (max-width:500px){
      position: absolute;
      
    }
`





const AccountConnect = () => {

  const dispatch = useDispatch();
  const client = useSelector(state=> state.client);

  /* ************ STATES *********** */
  const [paginaActual, setPaginaActual] = useState('cuentas');
  const [responsive, setResponsive] = useState(false)
  /* ************ END STATES *********** */


  /* ************ USE EFFECTS *********** */
  
  /* ************ END USE EFFECTS *********** */


  
    return (
      
      <Fragment>
       

        <MenuSuperior
          showDerecha= "false"
          marginBottom="No"
        />
        <Button 
           onClick={()=>setResponsive(!responsive)}>menu</Button>
        <Div>
        <MenuLateral
          setPaginaActual = {setPaginaActual}
        />
        </Div>
       
          {responsive ?
            <MenuLateral
            setPaginaActual = {setPaginaActual}
          />
          :
          null
          }
        <DivContenedores>
        {paginaActual === 'cuentas' ? 
          <Cuentas/>
        : null}

        {paginaActual === 'administrador'  && 1===2? 
          <Fragment>
            <h3>Página Administrador</h3>
          </Fragment>
        : null}

        {paginaActual === 'cuenta' ? 
          <Fragment>
            <CuentaSubscripcion />
          </Fragment>
        : null}

        {paginaActual === 'facturacion'   && 1===2 ? 
          <Fragment>
            <h3>Página Facturacion</h3>
          </Fragment>
        : null}

        {paginaActual === 'usuarios'   && 1===2 ? 
          <Fragment>
            <h3>Página Usuarios</h3>
          </Fragment>
        : null}

        {paginaActual === 'ayuda'  && 1===2 ? 
          <Fragment>
            <h3>Página Ayuda</h3>
          </Fragment>
        : null}
        
      </DivContenedores>
      {/* <FacebookLogin
          appId="370019901084564"
          autoLoad={false}
          fields="name,email"
          scope="public_profile,instagram_basic,pages_show_list,pages_read_engagement,instagram_manage_comments,instagram_manage_insights,pages_manage_metadata"
          callback={responseFacebook}
        /> */}
      <ul className="cuentas_activas">
        { client.hasOwnProperty("decode") && client.decode.hasOwnProperty("cuentas_user") && typeof client.decode.cuentas_user === 'object' && client.decode.cuentas_user.hasOwnProperty("facebook")?
          client.decode.cuentas_user.map(cuenta =>(
            <li>{cuenta.nombre} ===== {cuenta.activa ? "Si": "No"}</li>
          ))
          :null
        }
      </ul>
      </Fragment>
       
    )
          }

export default AccountConnect